import HttpService from "@/services/HttpService";
const PaquetesService = {
  async obtenerDestinos(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenerDestinos.php", cargaUtil);
  },
  async obtenerPaquetesRegion(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenerPaquetesRegion.php", cargaUtil);
  },

  async AddTipoPaq(NombreServicio) {
    return await HttpService.post("/AgregarServicio.php", {
      NombreServicio: NombreServicio,
    });
  },

  async AddDestino(NomLugar, NomRegion) {
    return await HttpService.post("/AgregarLugar.php", {
      NomLugar: NomLugar,
      NomRegion: NomRegion,
    });
  },
  async obtenerPaquetes() {
    return await HttpService.post("/obtenerPaquetes.php");
  },
  async obtenerTrenes() {
    return await HttpService.post("/obtenerTrenes.php");
  },
  async obtenertypepaq() {
    return await HttpService.post("/obtenertypepaq.php");
  },
  async obtenertype(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenertype.php", cargaUtil);
  },

  async cambiarEstado(nuevoVal, id, opcion) {
    return await HttpService.post("/cambiarPaquete.php", {
      nuevoVal,
      id,
      opcion,
    });
  },
  async cambiarEstadoTrenes(nuevoVal, id, opcion) {
    return await HttpService.post("/cambiarEstadoTrenes.php", {
      nuevoVal,
      id,
      opcion,
    });
  },
  async uploadItinerario(file, id) {
    const formdata = new FormData();
    formdata.append("img", file);
    formdata.append("id", id);
    return await HttpService.formdata("/uploadItinerario.php", formdata);
  },
  async uploadPDF(file, id) {
    const formdata = new FormData();
    formdata.append("img", file);
    formdata.append("id", id);
    return await HttpService.formdata("/uploadPDF.php", formdata);
  },

  async uploadImage(file, id) {
    const formdata = new FormData();
    formdata.append("img", file);
    formdata.append("id", id);
    return await HttpService.formdata("/uploadPromocional.php", formdata);
  },
  async uploadImageTre(file, id) {
    const formdata = new FormData();
    formdata.append("img", file);
    formdata.append("id", id);
    return await HttpService.formdata("/uploadPromocionalTre.php", formdata);
  },
  async ActualizarImage(file, id) {
    const formdata = new FormData();
    formdata.append("img", file);
    formdata.append("id", id);
    return await HttpService.formdata("/ActualizarImg.php", formdata);
  },
  async PortadaTrenes(file, id) {
    const formdata = new FormData();
    formdata.append("img", file);
    formdata.append("id", id);
    return await HttpService.formdata("/PortadaTrenes.php", formdata);
  },

  async NuevoPaq(
    n_tipo,
    titulo,
    Destino,
    fechaE,
    duracion,
    salidas,
    salidasSelect,
    precio,
    NomRegion,
    ImgLimpia,
    ImgPromo,
    itinerario,
    pdfFile,
    hotel,
    avion,
    traslado,
    crucero,
    train,
    alimentos,
    oferta
  ) {
    const formdata = new FormData();

    formdata.append("n_tipo", n_tipo);
    formdata.append("titulo", titulo);
    formdata.append("Destino", Destino);
    formdata.append("fechaE", fechaE);
    formdata.append("duracion", duracion);
    formdata.append("salidas", salidas);
    formdata.append("salidasSelect", salidasSelect);
    formdata.append("precio", precio);
    formdata.append("NomRegion", NomRegion);
    formdata.append("ImgLimpia", ImgLimpia);
    formdata.append("ImgPromo", ImgPromo);
    formdata.append("itinerario", itinerario);
    formdata.append("pdfFile", pdfFile);
    formdata.append("hotel", hotel);
    formdata.append("avion", avion);
    formdata.append("traslado", traslado);
    formdata.append("crucero", crucero);
    formdata.append("train", train);
    formdata.append("alimentos", alimentos);
    formdata.append("oferta", oferta);

    return await HttpService.formdata("/AddPaq.php", formdata);
  },

  async NuevoTren(ImgLimpia, ImgPromo, titulo, descrip, url, NomRegion) {
    const formdata = new FormData();
    formdata.append("titulo", titulo);
    formdata.append("url", url);
    formdata.append("NomRegion", NomRegion);
    formdata.append("ImgLimpia", ImgLimpia);
    formdata.append("ImgPromo", ImgPromo);
    formdata.append("descrip", descrip);

    return await HttpService.formdata("/AddTren.php", formdata);
  },
};
export default PaquetesService;
