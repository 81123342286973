<template>
  <div class="Login">
    <br />
    <div align="center"><p class="Btn"></p></div>

    <form class="form">
      <p id="heading">Iniciar Sesión</p>

      <b-input
        icon="account"
        :loading="cargando"
        required
        ref="fieldUser"
        custom-class="inputfield"
        placeholder="Usuario"
        type="text"
        v-model="user"
        rounded
      >
      </b-input>

      <b-input
        icon="lock"
        :loading="cargando"
        required
        ref="fieldPalabraSecreta"
        custom-class="inputfield"
        type="password"
        placeholder="Contraseña"
        v-model="palabraSecreta"
        value="iwantmytreasure"
        password-reveal
        rounded
      >
      </b-input>
      <b-button
        id="send"
        rounded
        :loading="cargando"
        @click="iniciarSesion()"
        type="is-info"
        >Enviar</b-button
      >
    </form>
  </div>
</template>

<script>
import UsuariosService from "@/services/UsuariosService";
import EventBus from "@/EventBus";
export default {
  name: "Inicio",
  data: () => ({
    user: "",
    palabraSecreta: "",
    cargando: false,
  }),
  methods: {
    async iniciarSesion() {
      const fields = ["fieldUser", "fieldPalabraSecreta"];
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }
      try {
        this.cargando = true;
        const usuarioLogueado = await UsuariosService.login(
          this.user,
          this.palabraSecreta
        );
        if (usuarioLogueado) {
          EventBus.establecerUsuario(usuarioLogueado);
          EventBus.$emit("navegarHacia", "HelloWorld");
          this.message("Bienvenido", "is-success");

          this.user = this.palabraSecreta = "";
        } else {
          this.message("Usuario o contraseña incorrectos", "is-danger");
        }
      } catch (e) {
        this.message("Error iniciando sesión: " + e, "is-danger");
        console.log(e);
      } finally {
        this.cargando = false;
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

<style>
.inputfield {
  color: rgb(0, 255, 200) !important;
  background-color: #171717f1 !important;
}

#send {
  --glow-color: rgb(176, 255, 189);
  --glow-spread-color: rgba(123, 255, 160, 0.781);
  --enhanced-glow-color: rgb(182, 175, 71);
  --btn-color: rgba(13, 241, 21, 0.508);
  border: 0.25em solid var(--glow-color);
  padding: 1em 2em;
  color: var(--glow-color);
  font-size: 14px;
  font-weight: bold;
  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;
  box-shadow: 0 0 1em 0.25em var(--glow-color),
    0 0 4em 1em var(--glow-spread-color),
    inset 0 0 0.05em 0.25em var(--glow-color);
  text-shadow: 0 0 0.5em var(--glow-color);
  position: relative;
  transition: all 0.3s;
}

#send::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: 0.7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
}

#send:hover {
  color: var(--btn-color);
  background-color: var(--glow-color);
  box-shadow: 0 0 1em 0.25em var(--glow-color),
    0 0 4em 2em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
}

#send:active {
  box-shadow: 0 0 0.6em 0.25em var(--glow-color),
    0 0 2.5em 2em var(--glow-spread-color),
    inset 0 0 0.5em 0.25em var(--glow-color);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 0.4em;
  background-color: #171717f1;
  border-radius: 20px;
  width: 300px;
  margin: 50px auto;
}

#heading {
  text-align: center;
  margin: 2em;
  color: rgb(0, 255, 200);
  font-size: 1.2em;
}

.input-icon {
  height: 1.3em;
  width: 1.3em;
  fill: rgb(0, 255, 200);
}

.Login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(163deg, #2c6da7 0%, #ff7b43 100%);
}

.Btn {
  width: 30vw;
  height: 15vh;
  border: none;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    #77530a,
    #ffd277,
    #77530a,
    #77530a,
    #ffd277,
    #77530a
  );
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
  transition-duration: 1s;
}

.Btn::before {
  position: absolute;
  content: "Administrador de website";
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
}

.Btn:hover {
  background-position: right;
  transition-duration: 1s;
}

.Btn:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.Btn:active {
  transform: scale(0.95);
}
</style>