<template>
  <div class="box">
    <h1 class="title">Capacitaciones</h1>
    <section>
      <div class="block">
        <b-button
          label="+ Nuevo"
          type="is-info"
          size="is-medium"
          @click="isOpen = !isOpen"
          :aria-expanded="isOpen"
          aria-controls="contentIdForA11y2"
        />
      </div>

      <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide"
        v-model="isOpen"
      >
        <template #trigger>
          <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2"
            :aria-expanded="isOpen"
          >
            <strong>Datos</strong>
          </div>
        </template>
        <div class="rows p-6">
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Asunto">
                  <b-input
                    rounded
                    v-model="asunto"
                    ref="fieldasunto"
                    placeholder="Escribe el asunto"
                    native-value="asunto"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Duración">
                  <b-input
                    rounded
                    v-model="duracion"
                    ref="fieldduracion"
                    placeholder="duracion del curso"
                    native-value="duracion"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de capacitación">
                  <b-datetimepicker
                    rounded
                    ref="fieldfchin"
                    :show-week-number="true"
                    :locale="locale"
                    placeholder="Click to select..."
                    icon="calendar-multiselect"
                    :icon-right="fchin ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDate"
                    required
                    v-model="fchin"
                  >
                  </b-datetimepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Descripción">
                  <b-input
                    rounded
                    v-model="descrip"
                    ref="fieldDescripcion"
                    placeholder="Descripción del curso"
                    native-value="descrip"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Url Reunión">
                  <b-input
                    rounded
                    v-model="url"
                    ref="fieldnameUrl"
                    placeholder="Ingresa URL"
                    native-value="url"
                    type="textarea"
                    maxlength="100"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="No. Participantes">
                  <b-numberinput
                    rounded
                    :controls="false"
                    v-model="Participantes"
                    ref="fieldParticipantes"
                    placeholder="Ingrese el numero"
                    native-value="Participantes"
                  >
                  </b-numberinput>
                </b-field>
                <b-tooltip
                  label="Guardar"
                  position="is-bottom"
                  type="is-success is-light"
                >
                  <a @click="GuardarCapacitacion()"
                    ><b-icon icon="content-save" type="is-success">
                      <b-tooltip
                        label="Guardar Comunicado"
                        position="is-bottom"
                        type="is-info is-light"
                      >
                      </b-tooltip></b-icon></a
                ></b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </section>
    <div class="columns">
      <div class="column">
        <b-table
          v-if="isOpen == false"
          style="font-size: 18px"
          :data="isEmpty ? [] : Capacitaciones"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          detailed
          detail-key="id"
          :show-detail-icon="showDetailIcon"
        >
          <template>
            <b-table-column
              field="fecha"
              label="Fecha Capacitación"
              sortable
              searchable
              v-slot="props"
            >
              <template v-if="showDetailIcon">
                {{ props.row.fecha }}
              </template>
              <template v-else>
                <a @click="props.toggleDetails(props.row)">
                  {{ props.row.fecha }}
                </a>
              </template>
            </b-table-column>
            <b-table-column
              field="asunto"
              label="Asunto"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.asunto }}
            </b-table-column>
            <b-table-column
              field="duracion"
              label="Duración"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.duracion }}
            </b-table-column>
            <b-table-column
              field="descripcion"
              label="Descripción"
              searchable
              sortable
              v-slot="props"
            >
              {{ props.row.descripcion }}
            </b-table-column>
            <b-table-column
              field="participantes"
              label="Participantes"
              searchable
              sortable
              v-slot="props"
              centered
            >
              {{ props.row.participantes }}
            </b-table-column>

            <b-table-column
              field="url"
              label="URL Archivo"
              v-slot="props"
              centered
            >
              <a class="button is-light" :href="props.row.url" target="_blank">
                <b-icon icon="file-eye" type="is-info"></b-icon>
              </a>
            </b-table-column>

            <b-table-column
              field="Eliminar"
              label="Eliminar"
              sortable
              v-slot="props"
              centered
            >
              <a
                class="button is-light"
                @click="cambiarEstado(props.row.id, 7)"
                target="_blank"
              >
                <b-icon icon="delete-empty" type="is-danger"></b-icon>
              </a>
            </b-table-column>
          </template>
          <template #detail="props">
            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Fecha de capacitación</strong>
                  </div>
                  <div class="row">
                    <b-datetimepicker
                      rounded
                      placeholder="Click to select..."
                      icon="calendar-multiselect"
                      :icon-right="fchin ? 'close-circle' : ''"
                      ref="fieldfchin"
                      :locale="locale"
                      icon-right-clickable
                      @icon-right-click="clearDate"
                      trap-focus
                      required
                      :value="fchin"
                      native-value="fchin"
                      @input="
                        (option) => CapturarFecha(option, props.row.id, 1)
                      "
                    >
                    </b-datetimepicker>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Asunto</strong>
                    <b-input
                      rounded
                      v-model="props.row.asunto"
                      ref="fieldasunto"
                      placeholder="Escribe el asunto"
                      @input="Textoeditable(props.row.asunto, props.row.id, 2)"
                      :value="props.row.asunto"
                      native-value="asunto"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Duración</strong>
                    <b-input
                      rounded
                      v-model="props.row.duracion"
                      ref="fieldnameduracion"
                      placeholder="Escribe la duración del curso"
                      @input="
                        Textoeditable(props.row.duracion, props.row.id, 3)
                      "
                      :value="props.row.duracion"
                      native-value="duracion"
                    >
                    </b-input>
                  </div>
                  <div class="row"></div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Descripción</strong>
                    <b-input
                      rounded
                      v-model="props.row.descripcion"
                      ref="fielddescripcion"
                      placeholder="Escribe la descripcion"
                      @input="
                        Textoeditable(props.row.descripcion, props.row.id, 4)
                      "
                      :value="props.row.descripcion"
                      native-value="descripcion"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Participantes</strong>
                    <b-numberinput
                      rounded
                      v-model="props.row.participantes"
                      :controls="false"
                      ref="fieldnameparticipantes"
                      placeholder="Participantes para el curso"
                      @input="
                        Textoeditable(props.row.participantes, props.row.id, 5)
                      "
                      native-value="participantes"
                    >
                    </b-numberinput>
                  </div>
                  <div class="row"></div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Url Archivo</strong>
                  </div>
                  <div class="row">
                    <b-input
                      rounded
                      v-model="props.row.url"
                      ref="fieldnameUrl"
                      placeholder="Ingresa URL"
                      @input="Textoeditable(props.row.url, props.row.id, 6)"
                      :value="props.row.url"
                      native-value="url"
                      type="textarea"
                      maxlength="100"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #empty>
            <div class="has-text-centered">Sin Capacitaciones aun</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CapacitacionesService from "@/services/CapacitacionesService";

export default {
  name: "tablaCapacitaciones",
  data() {
    return {
      asunto: null,
      isOpen: false,
      fchin: new Date(),
      isEmpty: false,
      Capacitaciones: [],
      cargando: false,
      descrip: null,
      url: "",
      nuevoVal: "",
      duracion: null,
      locale: undefined, // Browser locale
      showDetailIcon: true,
      Participantes: null,
    };
  },
  mounted() {
    this.obtenerCapacitaciones();
  },

  methods: {
    async GuardarCapacitacion() {
      let anio = this.fchin.getFullYear();
      let mes = this.fchin.getMonth() + 1;
      let dia = this.fchin.getDate();
      let hora = this.fchin.getHours();
      let min = this.fchin.getMinutes();
      let fechaP =
        anio + "-" + mes + "-" + dia + " " + hora + ":" + min + ":00";
      try {
        const respuesta = await CapacitacionesService.NuevaCapacitacion(
          fechaP,
          this.asunto,
          this.duracion,
          this.descrip,
          this.Participantes,
          this.url
        );

        if (respuesta) {
          this.message("Se guardo la capacitación con exito", "is-success");
          location.reload();
        } else {
          this.message("Error al generar la capacitación", "is-danger");
        }
      } catch (e) {
        console.log(e);
      }
    },

    clearDate() {
      this.fchin = null;
    },

    Textoeditable(texto, id, opcion) {
      this.nuevoVal = texto;

      this.cambiarEstado(id, opcion);
    },

    CapturarFecha(fecha, id, opcion) {
      let anio = fecha.getFullYear();
      let mes = fecha.getMonth() + 1;
      let dia = fecha.getDate();
      let hora = fecha.getHours();
      let min = fecha.getMinutes();
      this.nuevoVal =
        anio + "-" + mes + "-" + dia + " " + hora + ":" + min + ":00";
      this.cambiarEstado(id, opcion);
    },
    async cambiarEstado(id, opcion) {
      this.cargando = true;
      try {
        const respuesta = await CapacitacionesService.cambiarEstado(
          this.nuevoVal,
          id,
          opcion
        );
        if (respuesta) {
          if (opcion == 7) {
            this.message("El comunicado ha sido eliminado", "is-success");
          }

          this.obtenerCapacitaciones();
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.cargando = false;
      }
    },

    async obtenerCapacitaciones() {
      this.cargando = true;
      this.Capacitaciones = await CapacitacionesService.obtenerCapacitaciones();
      this.cargando = false;
    },

    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>
