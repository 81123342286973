<template>
  <div class="box">
    <h1 class="title">Acceso a paquete dinámico</h1>

    <div class="columns">
      <div class="column">
        <h1>Resultados encontrados: {{ contactos.length }}</h1>
        <b-table
          style="font-size: 18px"
          :data="contactos"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
        >
          <template>
            <b-table-column
              field="id_iris"
              label="ID IRIS"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.id_iris }}
            </b-table-column>
            <b-table-column
              field="Referencia"
              label="REFERENCIA"
              searchable
              sortable
              v-slot="props"
            >
              {{ props.row.Referencia }}
            </b-table-column>
            <b-table-column
              field="Nombre_RazonSo"
              label="Nombre/Razon Social"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.Nombre_RazonSo }}
            </b-table-column>

            <b-table-column
              field="Acceso"
              label="ACCESO"
              sortable
              v-slot="props"
              centered
            >
              <b-checkbox
                :value="props.row.Acceso"
                @input="CambiarValor(props.row.Acceso, props.row.id)"
                native-value="Acceso"
              >
              </b-checkbox>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ClientesService from "@/services/ClientesService";

export default {
  name: "AccesoAPD",
  data() {
    return {
      contactos: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      nuevoVal: null,
    };
  },
  mounted() {
    this.obtenerAgencias();
  },
  methods: {
    async CambiarValor(valor, id) {
      if (valor == "false") {
        this.nuevoVal = "true";
      } else {
        this.nuevoVal = "false";
      }

      this.cargando = true;
      try {
        const respuestsa = await ClientesService.cambiarAccesoAPD(
          this.nuevoVal,
          id
        );
        if (respuestsa) {
          this.message("Estado cambiado", "is-success");
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
        console.log(e);
      } finally {
        this.cargando = false;
      }
    },
    async obtenerAgencias() {
      this.cargando = true;
      this.contactos = await ClientesService.obtenerAgencias();
      this.cargando = false;
    },

    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

