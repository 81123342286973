<template>
  <section style="background-color: white">
    <link
      href="https://fonts.googleapis.com/css2?family=Calibri:wght@400&display=swap"
      rel="stylesheet"
    />
    <div id="ITINERARIO">
      <div
        class="VistaHoja"
        v-for="(hoja, index) in hojas"
        :key="index"
        v-show="index === paginaActual"
      >
        <div class="Margen">
          <div class="PorHoja" id="Contenido">
            <div ref="contenedor" id="Hoja1">
              <img
                src="@/assets/LogoOperadora.png"
                alt="LogoOperadora"
                width="150px"
              />
              <p style="text-align: justify">&nbsp;</p>
              <h1 style="text-align: center; font-size: 15pt">
                <strong style="color: #ff9d02">
                  <p>{{ datosFormulario.titulo }}</p>
                </strong>
              </h1>
              <h2 style="text-align: center; font-size: 15pt">
                <strong style="color: #2f6fa8">
                  <p>{{ datosFormulario.duracion }}</p>
                  <br
                /></strong>
              </h2>
              <h3 style="text-align: left; font-size: 12pt; color: #2f6fa8">
                <b>Desde: </b> ${{ datosFormulario.precio }}&nbsp;{{
                  datosFormulario.currency
                }}
              </h3>
              <h3 style="text-align: left; font-size: 12pt; color: #2f6fa8">
                <b>Salida: </b> <br />
                <p v-html="datosFormulario.salidas"></p>
              </h3>

              <h3 style="text-align: left; font-size: 12pt; color: #2f6fa8">
                <b>Visitando: </b> {{ datosFormulario.visitando }}
              </h3>
              <br />
              <h1 style="text-align: left; font-size: 15pt">
                <strong style="color: #ff9d02">
                  <p>ITINERARIO</p>
                </strong>
              </h1>
              <br />

              <div v-for="(hojaD, index) in hoja" :key="index">
                <template v-if="hojaD">
                  <h3
                    v-html="hojaD.titulo"
                    style="text-align: left; font-size: 10pt; color: #002060"
                  ></h3>
                  <P style="text-align: justify">{{ hojaD.actividades }}</P>

                  <br />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button
      size="is-large"
      rounded
      outlined
      type="is-info"
      icon-right="download"
      @click="makePDF"
    ></b-button>
  </section>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import EventBus from "@/EventBus";
export default {
  name: "Preview",
  props: ["hojas", "paginaActual"],
  data() {
    return {
      datosFormulario: {},
      datosAlmacenados: [],
    };
  },
  created() {
    EventBus.$on("datos-formulario", (datos) => {
      this.datosFormulario = datos;
    });
  },
  methods: {
    moverDatos() {
      console.log("(/-_-)/" + this.datosAlmacenados[0]);
      const indiceCorte = this.datosFormulario.findIndex((dato, index) => {
        return dato.offsetTop + dato.clientHeight > 648;
      });

      if (indiceCorte > -1) {
        this.datosAlmacenados = this.datosFormulario.slice(indiceCorte);
        this.datosFormulario = this.datosFormulario.slice(0, indiceCorte);
      }
    },
    makePDF() {
      console.log("(/-_-)/" + this.datosFormulario, this.datosAlmacenados);
      window.html2canvas = html2canvas;
      let doc = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "Letter",
      });
      doc.html(document.querySelector("#ITINERARIO"), {
        callback: function (pdf) {
          pdf.save("Itinerario.pdf");
        },
      });
    },
  },
};
</script>

<style scoped>
.VistaHoja {
  width: 612px;
  height: 792px;
  border: 1px solid rgb(111, 197, 255);

  /* Ajusta el tamaño según sea necesario */
}
.Margen {
  margin: 72px;
}
.PorHoja {
  width: 468px;
  height: 648px;
  overflow: auto;
  border: 1px solid rgb(111, 197, 255);
}
#Hoja1 {
  width: 100%;
  height: auto;
}
#HeadInicio button {
  bottom: 10px;
  right: 10px;
  position: fixed;
  z-index: 9999;
}
#ITINERARIO {
  font-family: Montserrat, sans-serif;
  font-size: 8pt; /* Ajusta el tamaño de fuente según sea necesario */
  line-height: 1.2; /* Ajusta el espaciado entre líneas según sea necesario */
  color: #2f6fa8;
}
</style>

