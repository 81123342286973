<template>
  <div>
    <b-sidebar
      type="is-light"
      :fullheight="true"
      :overlay="true"
      v-model="show"
    >
      <div class="p-1">
        <div style="background-color: rgb(42 41 41)">
          <img alt="logoGris" src="@/assets/LogoGris.png" />
        </div>
        <b-menu>
          <b-menu-list label="Menu">
            <b-menu-item
              icon="cellphone-link"
              label="Ir al sitio"
              href="https://operadora.contravel.com.mx/"
            ></b-menu-item>
            <b-menu-item icon="account-group" :active="isActive">
              <template #label="props">
                Clientes
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                ></b-icon>
              </template>
              <b-menu-item
                @click="navegarHacia('AllClientes')"
                icon="format-list-bulleted-type"
                label="Todos"
              ></b-menu-item>
              <b-menu-item
                @click="navegarHacia('Agencias')"
                icon="human-queue"
                label="Agencias"
              ></b-menu-item>
              <b-menu-item
                icon="package-variant"
                @click="navegarHacia('AccesoAPD')"
                label="Acceso paquete dinámico"
              ></b-menu-item>
            </b-menu-item>
            <b-menu-item
              icon="bullhorn"
              label="Comunicados"
              @click="navegarHacia('tablaComunicados')"
            ></b-menu-item>
            <b-menu-item
              icon="calendar-month"
              label="Calendario de pagos"
              @click="navegarHacia('Pagos')"
            >
            </b-menu-item>

            <b-menu-item icon="laptop" :active="isActive">
              <template #label="props">
                Capacitaciones
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                ></b-icon>
              </template>
              <b-menu-item
                label="Administrar capacitaciones"
                icon="laptop"
                @click="navegarHacia('Capacitaciones')"
              ></b-menu-item>
              <b-menu-item
                @click="navegarHacia('Tabla_Participantes')"
                icon="account-group"
                label="Participantes"
              ></b-menu-item>
            </b-menu-item>

            <b-menu-item
              icon="book-open-variant"
              label="Manuales"
              @click="navegarHacia('Manuales')"
            >
            </b-menu-item>

            <b-menu-item icon="package-variant-closed" :active="isActive">
              <template #label="props">
                Paquetes
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                ></b-icon>
              </template>
              <b-menu-item
                icon="earth"
                label="Regiones"
                @click="navegarHacia('Paquetes')"
              >
              </b-menu-item>
              <b-menu-item
                @click="navegarHacia('Disney')"
                icon="crown-circle-outline"
                label="Disney"
              ></b-menu-item>
              <b-menu-item
                @click="navegarHacia('Trenes')"
                icon="train"
                label="Trenes"
              ></b-menu-item>
              <!--Pendiente Terminar componente-->
              <b-menu-item
                v-show="false"
                @click="navegarHacia('Itinerario')"
                icon="file-document-edit"
                label="Crear Itinerario"
              ></b-menu-item>
            </b-menu-item>

            <b-menu-item
              icon="table-large"
              @click="navegarHacia('Comisiones')"
              label="Tabla de comisiones"
            >
            </b-menu-item>

            <b-menu-item
              icon="image-move"
              label="Slider"
              @click="navegarHacia('Slider')"
            ></b-menu-item>

            <b-menu-item
              icon="file-document-multiple-outline"
              label="Facturas"
              @click="navegarHacia('Facturas')"
            ></b-menu-item>

            <b-menu-item
              icon="file-upload"
              label="Subir Reportes"
              @click="navegarHacia('Reportes')"
            ></b-menu-item>
            <b-menu-item
              icon="card-account-phone"
              label="Directorio"
              @click="navegarHacia('Directorio')"
            ></b-menu-item>
          </b-menu-list>
          <!-- <b-menu-list label="Administrador">
            <b-menu-item icon="cog" :active="isActive">
              <template #label="props">
                Sistema
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                ></b-icon>
              </template>
              <b-menu-item
                icon="account-group-outline"
                label="Usuarios"
              ></b-menu-item
              ><b-menu-item
                icon="application-cog-outline"
                label="Ajustes del sitio"
              ></b-menu-item
              ><b-menu-item
                icon="account-cog-outline
"
                label="Ajustes de SEO"
              ></b-menu-item
              ><b-menu-item
                icon="semantic-web"
                label="Headers en paquetes"
              ></b-menu-item>
            </b-menu-item>
          </b-menu-list> -->
        </b-menu>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: ["value"],
  name: "SideMenu",
  data() {
    return { isActive: false };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
      this.menuEstaActivo = false;
      this.show = false;
    },
  },
};
</script>

