<template>
  <div class="box">
    <h1 class="title">Disney</h1>

    <b-collapse class="panel" animation="slide" v-model="Collapse">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">Agregar Paquete</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>

      <div class="box">
        <div class="content">
          <br />
          <div class="columns">
            <div class="column">
              <b-field label="Titulo" label-position="on-border">
                <b-input
                  expanded
                  v-model="titulo"
                  required
                  ref="fieldTitulo"
                  placeholder="Escribe el titulo"
                  native-value="titulo"
                />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Servicio" label-position="on-border">
                <b-autocomplete
                  expanded
                  v-model="nameTipo"
                  required
                  ref="fieldServicio"
                  placeholder="Tipo de Servicio"
                  native-value="typs"
                  group-field="typs"
                  group-options="n_tipo"
                  open-on-focus
                  :clearable="true"
                  :data="filtrarObjeto"
                  @select="(option) => (selected = option)"
                  @select-header="AddNewServicio"
                  selectable-header
                >
                  <template #header>
                    <a><span> Agregar Nuevo... </span></a>
                  </template>
                  <template #empty>No results for {{ nameTipo }}</template>
                </b-autocomplete>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Destino" label-position="on-border">
                <b-select
                  expanded
                  ref="fieldDestino"
                  placeholder="Selecciona una opción"
                  v-model="Destino"
                  required
                >
                  <option value="Orlando">Orlando</option>
                  <option value="Los Angeles">Los Angeles</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field
                label="Salidas"
                label-position="on-border"
                v-if="salidasSelect == null || salidasSelect == ''"
              >
                <b-select
                  expanded
                  placeholder="Selecciona una opción"
                  v-model="salidasSelect"
                  required
                >
                  <option value="Diario">Diarias</option>
                  <option value="Rango">Rango de fechas</option>
                  <option value="Multifecha">Multiples fechas</option>
                  <option value="Otro">Otro</option>
                </b-select>
              </b-field>
              <div v-if="salidasSelect == 'Otro'">
                <b-field label="Ingrese la fecha" label-position="on-border">
                  <b-input
                    v-model="dataFecha[0]"
                    expanded
                    required
                    ref="fieldDataFecha"
                  />

                  <b-button
                    @click="LimpiarDataFecha()"
                    icon-left="delete"
                    type="is-danger"
                  />
                </b-field>
              </div>
              <div v-if="salidasSelect == 'Diario'">
                <b-field label="Fecha seleccionada" label-position="on-border">
                  <b-input
                    :value="(dataFecha = 'Diario')"
                    v-model="dataFecha"
                    disabled
                    expanded
                    required
                    ref="fieldDataFecha"
                  />

                  <b-button
                    v-show="dataFecha.length > 0"
                    @click="LimpiarDataFecha()"
                    icon-left="delete"
                    type="is-danger"
                  />
                </b-field>
              </div>
              <div v-if="salidasSelect == 'Rango'">
                <b-field label="Salidas" label-position="on-border">
                  <b-datepicker
                    expanded
                    placeholder="Click para seleccionar..."
                    v-model="dataFecha"
                    range
                    icon-right-clickable
                    icon="calendar-today"
                    trap-focus
                    required
                    ref="fieldDataFecha"
                  >
                  </b-datepicker>
                  <b-button
                    v-show="dataFecha.length > 0"
                    @click="LimpiarDataFecha()"
                    icon-left="delete"
                    type="is-danger"
                  />
                </b-field>
              </div>
              <div v-if="salidasSelect == 'Multifecha'">
                <b-field label="Salidas" label-position="on-border">
                  <b-datepicker
                    expanded
                    placeholder="Click para seleccionar..."
                    v-model="dataFecha"
                    multiple
                    icon-right-clickable
                    icon="calendar-today"
                    trap-focus
                    required
                    ref="fieldDataFecha"
                  >
                  </b-datepicker>
                  <b-button
                    v-show="dataFecha.length > 0"
                    @click="LimpiarDataFecha()"
                    icon-left="delete"
                    type="is-danger"
                  />
                </b-field>
              </div>
            </div>
            <div class="column">
              <b-field label="Fecha de expiración" label-position="on-border">
                <b-datepicker
                  required
                  ref="fieldFechaEx"
                  expanded
                  :show-week-number="true"
                  :locale="locale"
                  placeholder="Click to select..."
                  icon="calendar-multiselect"
                  :icon-right="fchexp ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="clearDate1"
                  trap-focus
                  :value="fchexp"
                  native-value="fchexp"
                  v-model="fchexp"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Duración" label-position="on-border">
                <b-input
                  required
                  expanded
                  v-model="duracion"
                  ref="fieldDuracion"
                  placeholder="Escribe la duración"
                  native-value="duracion"
                />
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Descripción" label-position="on-border">
                <b-input
                  v-model="descrip"
                  placeholder="Escribe la descripción"
                  native-value="descrip"
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Precio" label-position="on-border">
                <b-input
                  v-model="precio"
                  required
                  ref="fieldPrecio"
                  placeholder="Escribe el precio"
                  native-value="precio"
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field
                class="file is-light"
                :class="{ 'has-name': !!itinerario }"
                label-position="on-border"
                label="Itinerario"
              >
                <b-upload
                  v-model="itinerario"
                  @input="changeDoc($event)"
                  required
                  ref="fieldItinerario"
                  class="file-label"
                  accept=".doc , .docx"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">{{
                      itinerario.name || "Click para subir (Unicamente word)"
                    }}</span>
                  </span>
                </b-upload>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field
                class="file is-warning"
                :class="{ 'has-name': !!ImgLimpia }"
                label-position="on-border"
                label="Imagen Limpia"
              >
                <b-upload
                  accept="image/jpeg,image/jpg"
                  required
                  ref="fieldImgLimpia"
                  @input="cambiarImagen($event)"
                  v-model="ImgLimpia"
                  class="file-label"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="ImgLimpia">
                    {{ ImgLimpia.name }}
                  </span>
                </b-upload>
              </b-field>

              <div class="column" v-if="visorImgLimp">
                <b-field>
                  <figure class="figure">
                    <img
                      alt="No se encontro Imagen"
                      :src="visorImgLimp"
                      class="image height"
                      style="width: 28vw"
                    />
                  </figure>
                  <b-button type="is-danger" outlined @click="eliminarImg()"
                    ><b-icon icon="delete-forever"> </b-icon
                  ></b-button>
                </b-field>
              </div>
            </div>
            <div class="column">
              <b-field
                class="file is-info"
                :class="{ 'has-name': !!ImgPromo }"
                label-position="on-border"
                label="Imagen Promocional"
              >
                <b-upload
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  required
                  ref="fieldImgPromocional"
                  @input="changeImage($event)"
                  v-model="ImgPromo"
                  class="file-label"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="ImgPromo">
                    {{ ImgPromo.name }}
                  </span>
                </b-upload>
              </b-field>
              <div class="column" v-if="visorImgPromo">
                <b-field>
                  <figure class="figure">
                    <img
                      alt="No se encontro Imagen"
                      :src="visorImgPromo"
                      class="image height"
                      style="width: 28vw"
                    />
                  </figure>
                  <b-button type="is-danger" outlined @click="eliminarview()"
                    ><b-icon icon="delete-forever"> </b-icon
                  ></b-button>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="column" style="text-align: right">
          <b-button
            rounded
            @click="AgregarPaquete()"
            icon-left="content-save"
            type="is-success"
            outlined
          />
        </div>
      </div>
    </b-collapse>
    <b-collapse class="panel" animation="slide" v-model="Collapse2">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">Ver todos los paquetes</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>

      <div class="box">
        <div class="columns">
          <div class="column">
            <b-table
              :data="isEmpty ? [] : Paquetes"
              :paginated="true"
              :per-page="10"
              :bordered="true"
              :striped="true"
              :narrowed="true"
              :hoverable="true"
              :mobile-cards="true"
              detailed
              detail-key="id"
              :show-detail-icon="showDetailIcon"
            >
              <template>
                <b-table-column
                  field="fecha_expi"
                  label="Fecha de expiración"
                  searchable
                  sortable
                  v-slot="props"
                >
                  <template v-if="showDetailIcon">
                    {{ props.row.fecha_expi }}
                  </template>
                  <template v-else>
                    <a @click="props.toggleDetails(props.row)">
                      {{ props.row.fecha_expi }}
                    </a>
                  </template>
                </b-table-column>

                <b-table-column
                  field="tipo"
                  label="Tipo"
                  sortable
                  searchable
                  v-slot="props"
                >
                  {{ props.row.tipo }}
                </b-table-column>
                <b-table-column
                  field="titulo"
                  label="Titulo"
                  sortable
                  searchable
                  v-slot="props"
                >
                  {{ props.row.titulo }}
                </b-table-column>
                <b-table-column
                  field="destino"
                  label="Destino"
                  searchable
                  sortable
                  v-slot="props"
                >
                  {{ props.row.destino }}
                </b-table-column>

                <b-table-column
                  field="descrip"
                  label="Descripción"
                  searchable
                  sortable
                  v-slot="props"
                >
                  {{ props.row.descrip }}
                </b-table-column>

                <b-table-column
                  field="status"
                  label="Publicado"
                  sortable
                  v-slot="props"
                >
                  <b-checkbox
                    :value="props.row.status"
                    @input="CambiarValor(props.row.status, props.row.id, 5)"
                    native-value="status"
                  >
                  </b-checkbox>
                </b-table-column>
                <b-table-column
                  field="multimedia"
                  label="Multimedia"
                  v-slot="props"
                >
                  <a
                    class="button is-light"
                    :href="location + props.row.multimedia"
                    target="_blank"
                  >
                    <b-icon icon="folder-eye" type="is-info"></b-icon>
                  </a>
                </b-table-column>
                <b-table-column field="portada" label="Portada" v-slot="props">
                  <a
                    class="button is-light"
                    :href="location + props.row.imagen"
                    target="_blank"
                  >
                    <b-icon icon="file-eye" type="is-success"></b-icon>
                  </a>
                </b-table-column>
                <b-table-column
                  field="promocional"
                  label="Promocional"
                  v-slot="props"
                >
                  <a
                    class="button is-light"
                    :href="location + props.row.detalles"
                    target="_blank"
                  >
                    <b-icon icon="file-eye" type="is-warning"></b-icon>
                  </a>
                </b-table-column>

                <b-table-column
                  field="Eliminar"
                  label="Eliminar"
                  sortable
                  v-slot="props"
                >
                  <a
                    class="button is-light"
                    @click="cambiarEstado(props.row.id, 6)"
                    target="_blank"
                  >
                    <b-icon icon="delete-empty" type="is-danger"></b-icon>
                  </a>
                </b-table-column>
              </template>
              <template #detail="props">
                <div class="columns">
                  <div class="column">
                    <b-field label="Titulo" label-position="on-border">
                      <b-input
                        expanded
                        v-model="props.row.titulo"
                        required
                        ref="fieldTitulo"
                        placeholder="Escribe el titulo"
                        native-value="titulo"
                        @input="
                          Textoeditable(props.row.titulo, props.row.id, 2)
                        "
                        :value="props.row.titulo"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Servicio" label-position="on-border">
                      <b-autocomplete
                        expanded
                        v-model="props.row.tipo"
                        required
                        ref="fieldServicio"
                        placeholder="Tipo de Servicio"
                        native-value="typs"
                        group-field="typs"
                        group-options="n_tipo"
                        open-on-focus
                        :clearable="true"
                        :data="filtrarObjeto"
                        :value="props.row.tipo"
                        @input="Textoeditable(props.row.tipo, props.row.id, 1)"
                        @select-header="AddNewServicio"
                        selectable-header
                      >
                        <template #header>
                          <a><span> Agregar Nuevo... </span></a>
                        </template>
                        <template #empty
                          >No results for {{ nameTipo }}</template
                        >
                      </b-autocomplete>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Salidas" label-position="on-border">
                      <b-taginput
                        ref="fieldnamesalidas"
                        placeholder="Escribe la o las salidas"
                        native-value="salidas"
                        v-model="props.row.salidas"
                        :value="props.row.salidas"
                        ellipsis
                        icon="label"
                        aria-close-label="Borrar fecha"
                        @input="
                          Textoeditable(props.row.salidas, props.row.id, 9)
                        "
                      >
                      </b-taginput>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field
                      label="Fecha de expiración"
                      label-position="on-border"
                    >
                      <b-datepicker
                        :show-week-number="true"
                        :locale="locale"
                        placeholder="Click to select..."
                        icon="calendar-multiselect"
                        :icon-right="destino ? 'close-circle' : ''"
                        icon-right-clickable
                        @icon-right-click="clearDate"
                        trap-focus
                        native-value="destino"
                        @input="
                          (option) => CapturarFecha(option, props.row.id, 4)
                        "
                      >
                      </b-datepicker>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Duración" label-position="on-border">
                      <b-input
                        required
                        expanded
                        v-model="props.row.duracion"
                        ref="fieldDuracion"
                        placeholder="Escribe la duración"
                        native-value="duracion"
                        @input="
                          Textoeditable(props.row.duracion, props.row.id, 8)
                        "
                        :value="props.row.duracion"
                      >
                      </b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Descripción" label-position="on-border">
                      <b-input
                        v-model="props.row.descrip"
                        placeholder="Escribe la descripción"
                        native-value="descrip"
                        expanded
                        @input="
                          Textoeditable(props.row.descrip, props.row.id, 7)
                        "
                        :value="props.row.descrip"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Precio" label-position="on-border">
                      <b-input
                        v-model="props.row.precio"
                        required
                        ref="fieldPrecio"
                        placeholder="Escribe el precio"
                        native-value="precio"
                        expanded
                        @input="
                          Textoeditable(props.row.precio, props.row.id, 10)
                        "
                        :value="props.row.precio"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field
                      class="file is-light"
                      :class="{ 'has-name': !!itinerario }"
                      label-position="on-border"
                      label="Itinerario"
                    >
                      <b-upload
                        ref="avatarInput"
                        @input="changeDoc($event, props.row.id)"
                        v-model="itinerario"
                        class="file-label"
                        required
                        accept=".doc , .docx"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">{{
                            itinerario.name ||
                            "Click para subir (Unicamente word)"
                          }}</span>
                        </span>
                      </b-upload>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field
                      class="file is-warning"
                      :class="{ 'has-name': !!ImgLimpia }"
                      label-position="on-border"
                      label="Imagen Limpia"
                    >
                      <b-upload
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        required
                        ref="fieldImgLimpia"
                        @input="cambiarImagen($event, props.row.id)"
                        v-model="ImgLimpia"
                        class="file-label"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label"></span>
                        </span>
                        <span class="file-name" v-if="ImgLimpia">
                          {{ ImgLimpia.name }}
                        </span>
                      </b-upload>
                    </b-field>

                    <div class="column" v-if="!visorImgLimp">
                      <b-field>
                        <figure class="figure">
                          <img
                            alt="No se encontro Imagen"
                            :src="location + props.row.imagen"
                            class="image height"
                            style="width: 28vw"
                          />
                        </figure>
                      </b-field>
                    </div>
                    <div class="column" v-if="visorImgLimp">
                      <b-field>
                        <figure class="figure">
                          <img
                            alt="No se encontro Imagen"
                            :src="visorImgLimp"
                            class="image height"
                            style="width: 28vw"
                          />
                        </figure>
                      </b-field>
                    </div>
                  </div>
                  <div class="column">
                    <b-field
                      class="file is-info"
                      :class="{ 'has-name': !!ImgPromo }"
                      label-position="on-border"
                      label="Imagen Promocional"
                    >
                      <b-upload
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        required
                        ref="fieldImgPromocional"
                        @input="changeImage($event, props.row.id)"
                        v-model="ImgPromo"
                        class="file-label"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label"></span>
                        </span>
                        <span class="file-name" v-if="ImgPromo">
                          {{ ImgPromo.name }}
                        </span>
                      </b-upload>
                    </b-field>

                    <div class="column" v-if="!visorImgPromo">
                      <b-field>
                        <figure class="figure">
                          <img
                            alt="No se encontro Imagen"
                            :src="location + props.row.detalles"
                            class="image height"
                            style="width: 28vw"
                          />
                        </figure>
                      </b-field>
                    </div>

                    <div class="column" v-if="visorImgPromo">
                      <b-field>
                        <figure class="figure">
                          <img
                            alt="No se encontro Imagen"
                            :src="visorImgPromo"
                            class="image height"
                            style="width: 28vw"
                          />
                        </figure>
                      </b-field>
                    </div>
                  </div>
                </div>
              </template>
              <template #empty>
                <div class="has-text-centered">
                  Sin paquetes registrados aun
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import PaquetesService from "@/services/PaquetesService";
export default {
  data() {
    return {
      titulo: null,
      salidasSelect: null,
      locale: undefined,
      fchexp: new Date(),
      duracion: null,
      descrip: null,
      precio: null,
      itinerario: {},
      ImgPromo: null,
      ImgLimpia: null,
      visorImgPromo: "",
      visorImgLimp: "",
      visorDoc: "",
      dataFecha: [],
      Paquetes: [],
      n_tipo: null,
      nameTipo: "",
      selected: null,
      Destino: null,
      Collapse: false,
      Collapse2: true,
      isEmpty: false,
      destino: null,
      location: "https://contravel.com.mx",
      showDetailIcon: true,
      dataTipo: [
        {
          typs: "Servicios",
          n_tipo: [],
        },
      ],
    };
  },
  computed: {
    filtrarObjeto() {
      const newData = [];
      this.dataTipo.forEach((element) => {
        const n_tipo = element.n_tipo.filter(
          (item) => item.toLowerCase().indexOf(this.nameTipo.toLowerCase()) >= 0
        );

        if (n_tipo.length) {
          newData.push({ typs: element.typs, n_tipo });
        }
      });
      return newData;
    },
  },
  mounted() {
    this.obtenerPaquetes();
  },
  methods: {
    LimpiarDataFecha() {
      this.dataFecha = [];
      this.fechaArray = [];
      this.salidasSelect = null;
    },
    clearDate() {
      this.destino = null;
    },
    clearDate1() {
      this.fchexp = null;
    },
    async changeImage(e, id) {
      this.file = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.visorImgPromo = this.result;
      };
      this.file.valueOf().id = id;
      if (id > 0) {
        const upload_image = await PaquetesService.uploadImage(this.file, id);
        if (upload_image) {
          this.eliminarview();

          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    eliminarview() {
      this.ImgPromo = null;
      this.visorImgPromo = null;
    },
    eliminarImg() {
      this.ImgLimpia = null;
      this.visorImgLimp = null;
    },
    async CambiarValor(valor, id, opcion) {
      if (valor == "false") {
        this.nuevoVal = "true";
      } else {
        this.nuevoVal = "false";
      }
      this.cambiarEstado(id, opcion);
    },

    async cambiarImagen(e, id) {
      this.ImgLimpia = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.ImgLimpia);
      reader.onload = function (e) {
        that.visorImgLimp = this.result;
      };
      this.ImgLimpia.valueOf().id = id;
      if (id > 0) {
        const upload_image = await PaquetesService.ActualizarImage(
          this.ImgLimpia,
          id
        );
        if (upload_image) {
          this.eliminarImg();
          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    async changeDoc(e, id) {
      this.doc = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.doc);
      reader.onload = function (e) {
        that.visorDoc = this.result;
      };
      this.doc.valueOf().id = id;

      if (id > 0) {
        const upload_image = await PaquetesService.uploadItinerario(
          this.doc,
          id
        );
        if (upload_image) {
          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },

    async AgregarPaquete() {
      const fields = [
        "fieldTitulo",
        "fieldServicio",
        "fieldDestino",
        "fieldDataFecha",
        "fieldFechaEx",
        "fieldDuracion",
        "fieldPrecio",
        "fieldItinerario",
        "fieldImgLimpia",
        "fieldImgPromocional",
      ];

      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }

      let anioE = this.fchexp.getFullYear();
      let mesE = this.fchexp.getMonth() + 1;
      let diaE = this.fchexp.getDate();
      let fechaE = anioE + "-" + mesE + "-" + diaE;

      if (
        this.salidasSelect === "Rango" ||
        this.salidasSelect === "Multifecha"
      ) {
        for (const fecha of this.dataFecha) {
          const anioE = fecha.getFullYear();
          const mesE = fecha.getMonth() + 1;
          const diaE = fecha.getDate();
          let NameMes = undefined;

          switch (mesE) {
            case 1:
              NameMes = "Ene";
              break;
            case 2:
              NameMes = "Feb";
              break;
            case 3:
              NameMes = "Mar";
              break;
            case 4:
              NameMes = "Abr";
              break;
            case 5:
              NameMes = "May";
              break;
            case 6:
              NameMes = "Jun";
              break;
            case 7:
              NameMes = "Jul";
              break;
            case 8:
              NameMes = "Ago";
              break;
            case 9:
              NameMes = "Sep";
              break;
            case 10:
              NameMes = "Oct";
              break;
            case 11:
              NameMes = "Nov";
              break;
            case 12:
              NameMes = "Dic";
              break;
            default:
          }

          const fechaE = diaE + "-" + NameMes + "-" + anioE;
          this.fechaArray.push(fechaE);
        }
      } else {
        this.fechaArray = this.dataFecha;
      }
      this.NomRegion = "Disney";
      const upload_image = await PaquetesService.NuevoPaq(
        this.itinerario,
        this.ImgLimpia,
        this.ImgPromo,
        this.nameTipo,
        this.titulo,
        this.Destino,
        fechaE,
        this.descrip,
        this.duracion,
        this.fechaArray,
        this.salidasSelect,
        this.precio,
        this.NomRegion
      );
      if (upload_image) {
        this.message("Se guardo el paquete con exito", "is-success");
        location.reload();
      } else {
        this.message("Error al realizar el paquete ", "is-danger");
      }
    },

    CapturarFecha(fecha, id, opcion) {
      let anio = fecha.getFullYear();
      let mes = fecha.getMonth() + 1;
      let dia = fecha.getDate();
      this.nuevoVal = anio + "-" + mes + "-" + dia;
      this.cambiarEstado(id, opcion);
    },
    async cambiarEstado(id, opcion) {
      try {
        const respuesta = await PaquetesService.cambiarEstado(
          this.nuevoVal,
          id,
          opcion
        );

        if (respuesta) {
          if (opcion == 6) {
            this.message("El comunicado ha sido eliminado", "is-success");
          }
          if (opcion == 7) {
            this.message("El comunicado se enviará por correo", "is-success");
          }
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        console.log(e);
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.obtenerPaquetes();
      }
    },

    async obtenerPaquetes() {
      this.Paquetes = await PaquetesService.obtenerPaquetes();

      for (let paquete of this.Paquetes) {
        paquete.salidas = paquete.salidas.split(",");
      }

      this.obtenertypepaq();
    },
    Textoeditable(texto, id, opcion) {
      if (opcion == 9) {
        let arrayDate = [];
        for (let datof of texto) {
          arrayDate.push(datof);
        }
        this.nuevoVal = arrayDate.toString();
      } else {
        this.nuevoVal = texto;
      }
      this.cambiarEstado(id, opcion);
    },
    async obtenertypepaq() {
      try {
        this.tipopaq = await PaquetesService.obtenertypepaq();
        if (this.tipopaq) {
          for (let item of this.tipopaq) {
            this.tipo = item.tipo;
            this.dataTipo[0].n_tipo.push(this.tipo);
          }
        }
      } catch (e) {
        console.log("Tienes un error" + e);
      }
    },

    AddNewServicio() {
      this.$buefy.dialog.prompt({
        message: `Servicio`,
        inputAttrs: {
          placeholder: "Excribe el tipo de servicio",
          maxlength: 200,
          value: this.nameTipo,
        },
        confirmText: "Agregar",
        onConfirm: (value) => {
          this.AddServicio(value);
          this.nameTipo = value;
        },
      });
    },
    async AddServicio(NewTipo) {
      try {
        const respuesta = await PaquetesService.AddTipoPaq(NewTipo);

        if (respuesta) {
          this.obtenertypepaq();
        }
      } catch (e) {
        this.message("Error al realizar cambios ", "is-danger");
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>


