<template>
  <section>
    <section>
      <b-carousel
        indicator
        indicator-background
        indicator-inside
        indicator-mode="hover"
        indicator-position="is-bottom"
        indicator-style="is-lines"
      >
        <b-carousel-item v-for="carousel in carousels" :key="carousel.id">
          <section :class="`hero is-${carousel.color}`" style="height: 44vw">
            <div class="hero-body has-text-centered">
              <div>
                <a @click="imprimir(1, carousel.title)" class="codepen-button"
                  ><span style="font-size: 2vw; color: aliceblue" f>
                    {{ carousel.title }}</span
                  ></a
                >
              </div>

              <div style="width: 25vw" @click="imprimir(2, carousel.image)">
                <a>
                  <img
                    :src="require(`@/assets/${carousel.image}`)"
                    alt="No se encontro imagen"
                  />
                </a>
              </div>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
      <div class="columns" id="navImage">
        <div class="column is-primary">
          <a
            href="https://www.twglobalprotection.com/seguro_viaje/ag_1001/prod_6736/lang_es"
            ><img src="@/assets/circuito.png" alt="No se encontro imagen" />
            <p style="color: aliceblue">
              <strong>Circuitos</strong>
            </p>
          </a>
        </div>
        &nbsp;

        <div class="column is-danger">
          <a
            href="https://www.twglobalprotection.com/seguro_viaje/ag_1001/prod_6736/lang_es"
            ><img src="@/assets/Hotel.png" alt="No se encontro imagen" />
            <p style="color: aliceblue">
              <strong>Hoteles</strong>
            </p></a
          >
        </div>
        &nbsp;
        <div class="column is-info">
          <a
            href="https://www.twglobalprotection.com/seguro_viaje/ag_1001/prod_6736/lang_es"
            ><img src="@/assets/autos.png" alt="No se encontro imagen" />
            <p style="color: aliceblue">
              <strong> Autos</strong>
            </p></a
          >
        </div>
        &nbsp;
        <div class="column is-success">
          <a
            href="https://www.twglobalprotection.com/seguro_viaje/ag_1001/prod_6736/lang_es"
            ><img src="@/assets/crucero.png" alt="No se encontro imagen" />
            <p style="color: aliceblue">
              <strong>Cruceros</strong>
            </p>
          </a>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "HelloWorld",
  data: () => ({
    title: null,
    descripcion: null,
    image: null,
    icon: "lead-pencil",
    status: "is-info is-light",
    status_des: false,
    valor_des: null,
    icon_title: "lead-pencil",
    status_t: "is-info is-light",
    status_title: false,
    valor_title: null,
    avatar: "",
    file: null,
    reserva: null,
    newlocation: null,
    origin: null,

    indicator: true,
    indicatorBackground: true,
    ImageRuta: undefined,
    indicatorMode: "hover",
    indicatorPosition: "is-top",
    indicatorStyle: "is-lines",
    carousels: [
      {
        title:
          "''Comisiones atractivas: ¡Aumenta tus ganancias con nuestras generosas comisiones!''",
        color: "info",
        image: "comisiones.png",
      },
      {
        title:
          "''Circuitos impresionantes: Descubre destinos inolvidables con nuestros emocionantes circuitos''",
        color: "success",
        image: "descircuito.png",
      },
      {
        title:
          "''Paquetes personalizados al instante: Creamos itinerarios a medida para adaptarnos a las preferencias de tus clientes''",
        color: "primary",
        image: "personalizar.png",
      },
      {
        title:
          "''Únete a nuestra red de agencias exitosas y potencia tu negocio de viajes''",
        color: "danger",
        image: "unirse.png",
      },
    ],
  }),
  mounted() {},
  methods: {
    imprimir(option, carrusel) {
      if (option == 2) {
        this.ImageRuta = "@/assets/" + carrusel;
        console.log(this.ImageRuta);
      } else {
        console.log(carrusel);
      }
    },
    mensaje(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  #navImage {
    display: flex;
    position: absolute;
    left: 16vh;
    top: 25vh !important;
  }
  .columns .column[data-v-469af010] {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 10vw;
    height: 8vh;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
  }
}
.b-tooltips {
  .b-tooltip:not(:last-child) {
    margin-right: 0.5em;
  }
  .b-tooltip {
    margin-bottom: 0.5em;
  }
}

#navImage {
  width: 20vw;
  top: 60%;
  right: 45%;
  position: absolute;
  font-size: 10px;
}

.columns .is-danger {
  background-color: #f43f5e;
}

.columns .is-info {
  background-color: #167df0;
}

.columns .is-success {
  background-color: #48c78e;
}
.columns .is-primary {
  background-color: #7957d5;
}
.columns .column {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  width: 10vw;
  height: 10vh;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
}

.columns .column p.tip {
  font-size: 1em;
  font-weight: 700;
}

.columns .column p.second-text {
  font-size: 0.7em;
}

.columns .column:hover {
  transform: scale(1.1, 1.1);
}

.columns:hover > .column:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}

.codepen-button {
  display: block;
  cursor: pointer;
  color: white;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  border-radius: 6px;
  overflow: hidden;
  padding: 0.5vw;
  isolation: isolate;
}

.codepen-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  background: linear-gradient(
    115deg,
    #ff7b43,
    #2c6da7,
    #ff7b76,
    #0d1335,
    #ffffff
  );
  background-size: 25% 100%;
  animation: an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop
    0.75s linear infinite;
  animation-play-state: paused;
  translate: -5% 0%;
  transition: translate 0.75s ease-out;
}

.codepen-button:hover::before {
  animation-play-state: running;
  transition-duration: 0.75s;
  translate: 0% 0%;
}

@keyframes an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop {
  to {
    transform: translateX(-25%);
  }
}

.codepen-button span {
  position: relative;
  display: block;
  font-size: 2.1rem;
  background: #000000;
  border-radius: 3px;
  height: 100%;
}
.has-text-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
