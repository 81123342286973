<template>
  <div class="box">
    <h1 class="title">Seguimiento Facturas</h1>
    <section v-if="checkedRows.length > 0">
      <b-button
        type="is-success is-light is-outlined"
        @click="RealizarAccion(1)"
      >
        <b-icon type="is-info" icon="microsoft-excel"></b-icon>
        <strong>Descargar</strong>
      </b-button>

      <b-button
        type="is-warning is-light is-outlined"
        @click="RealizarAccion(2)"
      >
        <b-icon type="is-success" icon="cash-multiple"></b-icon>
        <strong>Pagar</strong>
      </b-button>
    </section>
    <!-- :is-row-checkable="(row) => row.status !== 'pagado'" -->
    <b-tabs>
      <b-table
        :data="Facturas"
        :checked-rows.sync="checkedRows"
        checkable
        checkbox-position="right"
        ref="table"
        paginated
        per-page="5"
        detailed
        detail-key="id"
        detail-icon="arrow-right-circle"
        pagination-rounded
        pagination-size="is-small"
        @details-open="(row) => traerDatosF(row)"
        :opened-detailed="defaultOpen"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        bordered
        striped
      >
        <template>
          <b-table-column
            field="usuario"
            label="Usuario"
            sortable
            searchable
            v-slot="props"
          >
            {{ props.row.usuario }}
          </b-table-column>

          <b-table-column
            field="seguimiento"
            label="Seguimiento"
            sortable
            searchable
            v-slot="props"
          >
            {{ props.row.seguimiento }}
          </b-table-column>

          <b-table-column
            field="fecha"
            label="Fecha"
            sortable
            searchable
            v-slot="props"
          >
            {{ props.row.fecha }}
          </b-table-column>
          <b-table-column
            field="agencia"
            label="Agencia"
            sortable
            searchable
            v-slot="props"
          >
            {{ props.row.agencia }}
          </b-table-column>
          <b-table-column
            field="status"
            label="Estatus"
            sortable
            searchable
            v-slot="props"
          >
            <span
              :class="[
                'tag',
                { 'is-warning': props.row.status == 'Recibido' },
                { 'is-success': props.row.status == 'Pagado' },
                { 'is-danger': props.row.status == 'Rechazado' },
              ]"
              >{{ props.row.status }}</span
            >
          </b-table-column>

          <b-table-column field="pdf" label="PDF" sortable v-slot="props">
            <a
              class="button is-light"
              :href="
                location +
                props.row.ruta +
                '/PDF/' +
                props.row.seguimiento +
                '.pdf'
              "
              target="_blank"
            >
              <b-icon icon="file-pdf-box" type="is-danger"></b-icon>
            </a>
          </b-table-column>

          <b-table-column field="xml" label="XML" sortable v-slot="props">
            <a
              class="button is-light"
              :href="
                location +
                props.row.ruta +
                '/XML/' +
                props.row.seguimiento +
                '.xml'
              "
              target="_blank"
            >
              <b-icon icon="cloud-tags" type="is-info"></b-icon>
            </a>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <div class="media-content">
            <div class="content">
              <strong
                >{{ props.row.agencia }} -
                <small>{{ props.row.usuario }}</small></strong
              >

              <div class="columns">
                <div class="column">
                  <b-collapse
                    class="card"
                    animation="slide"
                    v-for="(dataFactura, index) of dataFacturas"
                    :key="index"
                    @open="isOpen == index"
                    :open="isOpen"
                    :aria-id="'contentIdForA11y5-' + index"
                  >
                    <template #trigger="props">
                      <div
                        class="card-header"
                        role="button"
                        :aria-controls="'contentIdForA11y5-' + index"
                        :aria-expanded="props.open"
                      >
                        <p class="card-header-title">
                          {{ dataFactura.Descripcion }}
                        </p>
                        <a class="card-header-icon">
                          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                          </b-icon>
                        </a>
                      </div>
                    </template>

                    <div class="card-content">
                      <div class="columns">
                        <div class="column">
                          <div class="rows">
                            <br />
                            <div class="row">
                              <b-taglist attached>
                                <b-tag type="is-dark">Clave Producto</b-tag>
                                <b-tag type="is-info is-light">{{
                                  dataFactura.claveProducto
                                }}</b-tag>
                              </b-taglist>
                            </div>
                            <br />
                            <div class="row">
                              <b-taglist attached>
                                <b-tag type="is-dark">Clave Unidad</b-tag>
                                <b-tag type="is-info is-light">{{
                                  dataFactura.claveUnidad
                                }}</b-tag>
                              </b-taglist>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="rows">
                            <br />
                            <div class="row">
                              <b-taglist attached>
                                <b-tag type="is-dark"
                                  >Impuesto Trasladado</b-tag
                                >
                                <b-tag type="is-info is-light"
                                  >${{ dataFactura.impuestoTrasladado }}</b-tag
                                >
                              </b-taglist>
                            </div>
                            <br v-if="dataFactura.iva > 0" />
                            <div class="row" v-if="dataFactura.iva > 0">
                              <b-taglist attached>
                                <b-tag type="is-dark">IVA</b-tag>
                                <b-tag type="is-info is-light"
                                  >${{ dataFactura.iva }}</b-tag
                                >
                              </b-taglist>
                            </div>
                            <br v-if="dataFactura.isr > 0" />
                            <div class="row" v-if="dataFactura.isr > 0">
                              <b-taglist attached>
                                <b-tag type="is-dark">ISR</b-tag>
                                <b-tag type="is-info is-light"
                                  >${{ dataFactura.isr }}</b-tag
                                >
                              </b-taglist>
                            </div>
                            <br />
                            <div class="row">
                              <b-taglist attached>
                                <b-tag type="is-dark">Importe</b-tag>
                                <b-tag type="is-info is-light"
                                  >${{ dataFactura.importe }}</b-tag
                                >
                              </b-taglist>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>

                <div class="column">
                  <div class="box" v-if="dataFacturas.length > 0">
                    <div class="columns">
                      <div class="column">
                        <p class="title is-6">UUID</p>
                        <p class="subtitle is-6">
                          <strong> {{ dataFacturas[0].uuid }}</strong>
                        </p>
                        <br />
                        <div class="columns">
                          <div class="column">
                            <p class="title is-6">RFC Emisor</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].rfcEmisor }}</strong>
                            </p>
                          </div>
                          <div class="column">
                            <p class="title is-6">Moneda</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].moneda }}</strong>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div class="columns">
                          <div class="column">
                            <p class="title is-6">T_Comprobante</p>
                            <p class="subtitle is-6">
                              <strong>
                                {{ dataFacturas[0].tipoComprobante }}
                              </strong>
                            </p>
                          </div>
                          <div class="column">
                            <p class="title is-6">CFDI</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].cfdi }}</strong>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div class="columns">
                          <div class="column">
                            <p class="title is-6">Metodo Pago</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].metodoPago }}</strong>
                            </p>
                          </div>
                          <div class="column">
                            <p class="title is-6">Forma Pago</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].formaPago }}</strong>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="column">
                        <br />
                        <br />
                        <br />
                        <br />
                        <div class="columns">
                          <div class="column">
                            <p class="title is-6">IVA Retenido</p>
                            <p class="subtitle is-6">
                              <strong>
                                {{ dataFacturas[0].ivaRetenido }}</strong
                              >
                            </p>
                          </div>
                          <div class="column">
                            <p class="title is-6">ISR Retenido</p>
                            <p class="subtitle is-6">
                              <strong>
                                {{ dataFacturas[0].isrRetenido }}</strong
                              >
                            </p>
                          </div>
                        </div>
                        <br />
                        <div class="columns">
                          <div class="column">
                            <p class="title is-6">Total Retenido</p>
                            <p class="subtitle is-6">
                              <strong>
                                {{ dataFacturas[0].totalRetenido }}</strong
                              >
                            </p>
                          </div>
                          <div class="column">
                            <p class="title is-6">Total Trasladado</p>
                            <p class="subtitle is-6">
                              <strong>
                                {{ dataFacturas[0].totalTrasladado }}</strong
                              >
                            </p>
                          </div>
                        </div>
                        <br />
                        <div class="columns">
                          <div class="column">
                            <p class="title is-6">SubTotal</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].subTotal }}</strong>
                            </p>
                          </div>
                          <div class="column">
                            <p class="title is-6">Total</p>
                            <p class="subtitle is-6">
                              <strong> {{ dataFacturas[0].total }}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #bottom-left>
          <b>Total checked</b>: {{ checkedRows.length }}
        </template>
      </b-table>
    </b-tabs>
  </div>
</template>

<script>
import FacturasService from "@/services/FacturasService";
import Constantes from "@/Constantes";
export default {
  data() {
    return {
      location: "https://contravel.com.mx",
      checkedRows: [],
      Facturas: [],
      dataFacturas: [],
      isOpen: false,
      useTransition: false,
      indice: null,
      defaultOpen: [],
    };
  },
  computed: {},
  mounted() {
    this.obtenerFacturas();
  },
  methods: {
    async RealizarAccion(option) {
      var ArrayFacturas = [];
      for (let index = 0; index < this.checkedRows.length; index++) {
        const element = this.checkedRows[index].seguimiento;
        const userIris = this.checkedRows[index].usuario;
        const agencia = this.checkedRows[index].agencia;
        if (option == 1) {
          this.datosFactura = await FacturasService.obtenerPaqueteDeDatos(
            element
          );
          for (let index = 0; index < this.datosFactura.length; index++) {
            const elemento = this.datosFactura[index];
            ArrayFacturas.push(elemento);
          }
        }
        if (option == 2) {
          const respuesta = await FacturasService.cambiarEstado(
            element,
            userIris
          );
          if (respuesta) {
            this.message(
              "Se le notificara a la agencia: " + agencia + " por correo",
              "is-success"
            );
          } else {
            this.message("Algo salio mal :C", "is-danger");
          }
        }
      }

      if (option == 1) {
        const resp = await FacturasService.CrearReporte(ArrayFacturas);
        if (resp) {
          var selected = new Date();
          var anio = selected.getFullYear();
          var mes = selected.getMonth() + 1;
          var dia = selected.getDate();
          var date = "Reporte " + anio + "/" + mes + "/" + dia;
          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + date;
          window.open(url);
          this.message("Archivo creado correctamente", "is-success");
          this.checkedRows = [];
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      }

      this.obtenerFacturas();
    },
    async traerDatosF(dato) {
      this.defaultOpen = [dato.id];
      this.indice = dato.seguimiento;
      this.$buefy.toast.open(`Agencia ${dato.agencia}`);
      this.dataFacturas = await FacturasService.obtenerDatosFactura(
        this.indice
      );
    },

    async obtenerFacturas() {
      this.cargando = true;
      this.Facturas = await FacturasService.obtenerFacturas();
      this.cargando = false;
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

<style >
.table {
  background-color: #ffffff81;
}
.media-content {
  background-color: #0000001c;
}
</style>