<template>
  <div>
    <div id="BInicio">
      <a aria-label="menu" @click="show = !show">
        <b-tooltip label="Opciones" type="is-dark" position="is-left">
          <b-icon
            icon="view-grid-plus"
            size="is-large"
            type="is-success"
          ></b-icon>
        </b-tooltip>
      </a>
    </div>
    <SideMenu v-model="show" />
  </div>
</template>
<script>
import SideMenu from "@/components/SideMenu";
export default {
  components: { SideMenu },

  data() {
    return {
      show: false,
      logeado: false,
    };
  },
  mounted() {},
  methods: {
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

<style>
#BInicio a {
  bottom: 1vw;
  right: 1vw;
  position: fixed;
  z-index: 9999;
}
</style>