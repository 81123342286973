import HttpService from "@/services/HttpService";
const ComunicadosService = {


    async obtenerComunicados() {
        return await HttpService.post("/obtenerComunicados.php");
    },
    async obtenertags() {
        return await HttpService.post("/obtenerTags.php");
    },
    async obtenertag(Dato) {
        const cargaUtil = { Dato };
        return await HttpService.post("/obtenertag.php", cargaUtil);
    },

    async cambiarEstado(nuevoVal, id, opcion) {
        return await HttpService.post("/cambiarComunicado.php", {
            nuevoVal, id, opcion
        });
    },

    async uploadImage(file, id) {
        const formdata = new FormData();
        formdata.append("img", file);
        formdata.append("id", id);
        return await HttpService.formdata("/uploadImageComu.php", formdata);
    },
    async NuevoComuniFile(file, n_tags, titulo, fechaP, fechaE) {
        const formdata = new FormData();
        formdata.append("img", file);
        formdata.append("n_tags", n_tags);
        formdata.append("titulo", titulo);
        formdata.append("fechaP", fechaP);
        formdata.append("fechaE", fechaE);
        return await HttpService.formdata("/saveImageComu.php", formdata);
    },


    async NuevoComunicado(n_tags, titulo, fechaP, fechaE, datoUrl) {
        return await HttpService.post("/NuevoComunicado.php", {
            n_tags: n_tags, titulo: titulo, fechaP: fechaP, fechaE: fechaE, datoUrl: datoUrl
        });
    },




};
export default ComunicadosService;