
<template>
  <section>
    <b-collapse
      class="panel"
      ref="Agregar"
      animation="slide"
      :key="0"
      :open="isOpen == 0"
      @open="isOpen = 0"
      @close="isOpen = 1"
    >
      <template #trigger>
        <div
          class="panel-heading"
          role="button"
          aria-controls="contentIdForA11y2"
        >
          <strong>+ Agregar</strong>
        </div>
      </template>

      <div class="panel-block">
        <div class="content">
          <br />
          <div class="columns">
            <div class="column">
              <b-field label="Titulo" label-position="on-border">
                <b-input
                  expanded
                  v-model="titulo"
                  required
                  ref="fieldTitulo"
                  placeholder="Escribe el titulo"
                  native-value="titulo"
                />
              </b-field>
            </div>

            <div class="column">
              <b-field label="Área de operación" label-position="on-border">
                <b-input
                  expanded
                  v-model="NomRegion"
                  required
                  ref="fieldNomRegion"
                  placeholder="Escribe el Área de operación"
                  native-value="NomRegion"
                />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Descripción" label-position="on-border">
                <b-input
                  type="textarea"
                  v-model="descrip"
                  placeholder="Escribe la descripción"
                  native-value="descrip"
                  expanded
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="URL Video" label-position="on-border">
                <b-input
                  placeholder="URL"
                  type="url"
                  v-model="url"
                  required
                  ref="fieldURL"
                  native-value="url"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <iframe
                title="YouTube video player"
                width="460"
                height="215"
                :src="url"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field
                class="file is-warning"
                :class="{ 'has-name': !!ImgLimpia }"
                label-position="on-border"
                label="Imagen Rutas"
              >
                <b-upload
                  accept="image/jpeg,image/jpg"
                  required
                  ref="fieldImgLimpia"
                  @input="cambiarImagen($event)"
                  v-model="ImgLimpia"
                  class="file-label"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="ImgLimpia">
                    {{ ImgLimpia.name }}
                  </span>
                </b-upload>
              </b-field>

              <div class="column" v-if="visorImgLimp">
                <b-field>
                  <figure class="figure">
                    <img
                      alt="No se encontro Imagen"
                      :src="visorImgLimp"
                      class="image height"
                      style="width: 28vw"
                    />
                  </figure>
                  <b-button type="is-danger" outlined @click="eliminarImg()"
                    ><b-icon icon="delete-forever"> </b-icon
                  ></b-button>
                </b-field>
              </div>
            </div>

            <div class="column">
              <b-field
                class="file is-info"
                :class="{ 'has-name': !!ImgPromo }"
                label-position="on-border"
                label="Imagen Portada"
              >
                <b-upload
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  required
                  ref="fieldImgPromocional"
                  @input="changeImage($event)"
                  v-model="ImgPromo"
                  class="file-label"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="ImgPromo">
                    {{ ImgPromo.name }}
                  </span>
                </b-upload>
              </b-field>
              <div class="column" v-if="visorImgPromo">
                <b-field>
                  <figure class="figure">
                    <img
                      alt="No se encontro Imagen"
                      :src="visorImgPromo"
                      class="image height"
                      style="width: 28vw"
                    />
                  </figure>
                  <b-button type="is-danger" outlined @click="eliminarview()"
                    ><b-icon icon="delete-forever"> </b-icon
                  ></b-button>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="column" style="text-align: right">
          <b-button
            rounded
            @click="AgregarTren()"
            icon-left="content-save"
            type="is-success"
            outlined
          />
        </div>
      </div>
    </b-collapse>

    <b-collapse
      aria-id="contentIdForA11y2"
      class="panel"
      animation="slide"
      ref="view"
      :key="1"
      :open="isOpen == 1"
      @open="isOpen = 1"
      @close="isOpen = 0"
    >
      <template #trigger>
        <div
          class="panel-heading"
          role="button"
          aria-controls="contentIdForA11y2"
        >
          <strong>Ver Tabla</strong>
        </div>
      </template>
      <div class="panel-block">
        <b-table
          :data="isEmpty ? [] : Trenes"
          paginated
          :per-page="10"
          bordered
          narrowed
          hoverable
          mobile-cards
          detailed
          detail-key="id"
          :show-detail-icon="showDetailIcon"
        >
          <template>
            <b-table-column
              field="id"
              label="ID"
              searchable
              sortable
              v-slot="props"
            >
              <template v-if="showDetailIcon">
                {{ props.row.id }}
              </template>
              <template v-else>
                <a @click="props.toggleDetails(props.row)">
                  {{ props.row.id }}
                </a>
              </template>
            </b-table-column>

            <b-table-column
              field="nombre"
              label="Nombre"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.nombre }}
            </b-table-column>
            <b-table-column
              field="descripcion"
              label="Descripción"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.descripcion }}
            </b-table-column>

            <b-table-column
              field="video"
              label="URL Video"
              searchable
              sortable
              v-slot="props"
            >
              <a
                class="button is-light"
                :href="props.row.video"
                target="_blank"
              >
                <b-icon icon="file-eye" type="is-info"></b-icon>
              </a>
            </b-table-column>

            <b-table-column field="portada" label="Portada" v-slot="props">
              <a
                class="button is-light"
                :href="'https://contravel.com.mx' + props.row.img"
                target="_blank"
              >
                <b-icon icon="file-eye" type="is-success"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              field="promocional"
              label="Promocional"
              v-slot="props"
            >
              <a
                class="button is-light"
                :href="'https://contravel.com.mx' + props.row.img_prom"
                target="_blank"
              >
                <b-icon icon="file-eye" type="is-warning"></b-icon>
              </a>
            </b-table-column>

            <b-table-column
              field="Eliminar"
              label="Eliminar"
              sortable
              v-slot="props"
            >
              <a
                class="button is-light"
                @click="cambiarEstado('Eliminar', props.row.id, 1)"
                target="_blank"
              >
                <b-icon icon="delete-empty" type="is-danger"></b-icon>
              </a>
            </b-table-column>
          </template>
          <template #detail="props">
            <div class="columns">
              <div class="column">
                <b-field label="Titulo" label-position="on-border">
                  <b-input
                    expanded
                    v-model="props.row.nombre"
                    required
                    ref="fieldTitulo"
                    placeholder="Escribe el titulo"
                    native-value="nombre"
                    @input="cambiarEstado(props.row.nombre, props.row.id, 2)"
                    :value="props.row.nombre"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Descripción" label-position="on-border">
                  <b-input
                    type="textarea"
                    expanded
                    v-model="props.row.descripcion"
                    required
                    ref="fieldDescripcion"
                    placeholder="Escribe la descripción"
                    native-value="descripcion"
                    @input="
                      cambiarEstado(props.row.descripcion, props.row.id, 3)
                    "
                    :value="props.row.descripcion"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Área de operación" label-position="on-border">
                  <b-input
                    expanded
                    v-model="props.row.region"
                    required
                    ref="fieldRegion"
                    placeholder="Escribe el Área de operación"
                    native-value="region"
                    @input="cambiarEstado(props.row.region, props.row.id, 4)"
                    :value="props.row.region"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="URL Video" label-position="on-border">
                  <b-input
                    expanded
                    v-model="props.row.video"
                    required
                    ref="fieldVideo"
                    placeholder="Ingresa la URL"
                    native-value="video"
                    @input="cambiarEstado(props.row.video, props.row.id, 5)"
                    :value="props.row.video"
                  >
                  </b-input
                ></b-field>
              </div>
              <div class="column">
                <iframe
                  title="YouTube video player"
                  width="560"
                  height="315"
                  :src="props.row.video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field
                  class="file is-warning"
                  :class="{ 'has-name': !!ImgLimpia }"
                  label-position="on-border"
                  label="Imagen Rutas"
                >
                  <b-upload
                    accept="image/jpeg,image/jpg"
                    required
                    ref="fieldImgLimpia"
                    @input="cambiarImagen($event, props.row.id)"
                    v-model="ImgLimpia"
                    class="file-label"
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label"></span>
                    </span>
                    <span class="file-name" v-if="ImgLimpia">
                      {{ ImgLimpia.name }}
                    </span>
                  </b-upload>
                </b-field>

                <div class="column" v-if="!visorImgLimp">
                  <b-field>
                    <figure class="figure">
                      <img
                        alt="No se encontro Imagen"
                        :src="'https://contravel.com.mx' + props.row.img"
                        class="image height"
                        style="width: 28vw"
                      />
                    </figure>
                  </b-field>
                </div>
                <div class="column" v-if="visorImgLimp">
                  <b-field>
                    <figure class="figure">
                      <img
                        alt="No se encontro Imagen"
                        :src="visorImgLimp"
                        class="image height"
                        style="width: 28vw"
                      />
                    </figure>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field
                  class="file is-info"
                  :class="{ 'has-name': !!ImgPromo }"
                  label-position="on-border"
                  label="Imagen Portada"
                >
                  <b-upload
                    accept="image/gif,image/jpeg,image/jpg,image/png"
                    required
                    ref="fieldImgPromocional"
                    @input="changeImage($event, props.row.id)"
                    v-model="ImgPromo"
                    class="file-label"
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label"></span>
                    </span>
                    <span class="file-name" v-if="ImgPromo">
                      {{ ImgPromo.name }}
                    </span>
                  </b-upload>
                </b-field>

                <div class="column" v-if="!visorImgPromo">
                  <b-field>
                    <figure class="figure">
                      <img
                        alt="No se encontro Imagen"
                        :src="'https://contravel.com.mx' + props.row.img_prom"
                        class="image height"
                        style="width: 28vw"
                      />
                    </figure>
                  </b-field>
                </div>

                <div class="column" v-if="visorImgPromo">
                  <b-field>
                    <figure class="figure">
                      <img
                        alt="No se encontro Imagen"
                        :src="visorImgPromo"
                        class="image height"
                        style="width: 28vw"
                      />
                    </figure>
                  </b-field>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="has-text-centered">Sin paquetes registrados aun</div>
          </template>
        </b-table>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import PaquetesService from "@/services/PaquetesService";
export default {
  data() {
    return {
      Trenes: undefined,
      isEmpty: false,
      showDetailIcon: true,
      ImgPromo: null,
      ImgLimpia: null,
      visorImgPromo: "",
      visorImgLimp: "",
      isOpen: 1,
      titulo: null,
      descrip: null,
      url: null,
      NomRegion: null,
    };
  },
  mounted() {
    this.obtenerTrenes();
  },
  methods: {
    async AgregarTren() {
      const upload_image = await PaquetesService.NuevoTren(
        this.ImgLimpia,
        this.ImgPromo,
        this.titulo,
        this.descrip,
        this.url,
        this.NomRegion
      );

      console.log(upload_image);
      if (upload_image) {
        this.message("Se actualizo la tabla de trenes", "is-success");
        location.reload();
      } else {
        this.message("Error al actualizar", "is-danger");
      }
    },
    async cambiarImagen(e, id) {
      this.ImgLimpia = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.ImgLimpia);
      reader.onload = function (e) {
        that.visorImgLimp = this.result;
      };
      this.ImgLimpia.valueOf().id = id;
      if (id > 0) {
        const upload_image = await PaquetesService.PortadaTrenes(
          this.ImgLimpia,
          id
        );
        if (upload_image) {
          this.eliminarImg();

          this.message("Los cambios fueron guardados con exito", "is-success");
          location.reload();
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    async changeImage(e, id) {
      this.file = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.visorImgPromo = this.result;
      };
      this.file.valueOf().id = id;
      if (id > 0) {
        const upload_image = await PaquetesService.uploadImageTre(
          this.file,
          id
        );
        if (upload_image) {
          this.eliminarview();
          this.message("Los cambios fueron guardados con exito", "is-success");
          location.reload();
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    eliminarImg() {
      this.ImgLimpia = null;
      this.visorImgLimp = null;
    },

    async obtenerTrenes() {
      this.Trenes = await PaquetesService.obtenerTrenes();
      console.log(this.Trenes);
    },
    async cambiarEstado(texto, id, opcion) {
      try {
        const respuesta = await PaquetesService.cambiarEstadoTrenes(
          texto,
          id,
          opcion
        );
        console.log(respuesta);
        if (respuesta) {
          if (opcion == 1) {
            this.message("El comunicado ha sido eliminado", "is-success");
          }
          if (opcion == 7) {
            this.message("El comunicado se enviará por correo", "is-success");
          }
          this.obtenerTrenes();
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

