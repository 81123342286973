import Vue from "vue";
import Router from "vue-router";
import Inicio from "@/components/Inicio";
import Header from "@/components/Header";
import SideMenu from "@/components/SideMenu";
import HelloWorld from "@/components/HelloWorld";
import Logout from "@/components/Logout";
import AllClientes from "@/components/Clientes/AllClientes";
import Agencias from "@/components/Clientes/Agencias";
import AccesoAPD from "@/components/Clientes/AccesoAPD";
import tablaComunicados from "@/components/Comunicados/tablaComunicados";
import Pagos from "@/components/CalendarPagos/Pagos";
import Capacitaciones from "@/components/Capacitaciones/capacitaciones";
import Tabla_Participantes from "@/components/Capacitaciones/Tabla_Participantes";
import Paquetes from "@/components/Paquetes/tabla_paq";
import Disney from "@/components/Paquetes/Disney";
import Trenes from "@/components/Paquetes/Trenes";
import Itinerario from "@/components/Paquetes/Itinerario";
import Comisiones from "@/components/Comisiones/comisiones";
import Manuales from "@/components/Manuales/Manuales";
import Slider from "@/components/Sliders/Slider";
import Reportes from "@/components/Reportes/Reportes";
import Directorio from "@/components/Directorio/Directorio";
import Facturas from "@/components/Facturas/Facturas";
import EventBus from "@/EventBus";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "Inicio",
      component: Inicio,
    },
    {
      path: "/Header",
      name: "Header",
      component: Header,
    },
    {
      path: "/HelloWorld",
      name: "HelloWorld",
      component: HelloWorld,
    },
    {
      path: "/SideMenu",
      name: "SideMenu",
      component: SideMenu,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/AllClientes",
      name: "AllClientes",
      component: AllClientes,
    },
    {
      path: "/Agencias",
      name: "Agencias",
      component: Agencias,
    },
    {
      path: "/AccesoAPD",
      name: "AccesoAPD",
      component: AccesoAPD,
    },
    {
      path: "/tablaComunicados",
      name: "tablaComunicados",
      component: tablaComunicados,
    },
    {
      path: "/Pagos",
      name: "Pagos",
      component: Pagos,
    },
    {
      path: "/Capacitaciones",
      name: "Capacitaciones",
      component: Capacitaciones,
    },
    {
      path: "/Tabla_Participantes",
      name: "Tabla_Participantes",
      component: Tabla_Participantes,
    },
    {
      path: "/Paquetes",
      name: "Paquetes",
      component: Paquetes,
    },

    {
      path: "/Disney",
      name: "Disney",
      component: Disney,
    },
    {
      path: "/Trenes",
      name: "Trenes",
      component: Trenes,
    },
    {
      path: "/Itinerario",
      name: "Itinerario",
      component: Itinerario,
    },
    {
      path: "/Comisiones",
      name: "Comisiones",
      component: Comisiones,
    },

    {
      path: "/Manuales",
      name: "Manuales",
      component: Manuales,
    },
    {
      path: "/Slider",
      name: "Slider",
      component: Slider,
    },
    {
      path: "/Reportes",
      name: "Reportes",
      component: Reportes,
    },
    {
      path: "/Directorio",
      name: "Directorio",
      component: Directorio,
    },
    {
      path: "/Facturas",
      name: "Facturas",
      component: Facturas,
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.name !== "Inicio" && !EventBus.obtenerUsuario().UserName) {
    return next({ name: "Inicio" });
  }
  if (to.name == "Inicio" && EventBus.obtenerUsuario().UserName) {
    return next({ name: "HelloWorld" });
  }
  if (to.name !== "Inicio") {
    EventBus.$emit("mostrarMenu");
  } else {
    EventBus.$emit("ocultarMenu");
  }
  return next();
});
export default router;
