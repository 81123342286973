<template>
  <div class="box">
    <h1 class="title">Comunicados</h1>
    <section>
      <div class="block">
        <b-button
          label="+ Nuevo"
          type="is-info"
          size="is-medium"
          @click="isOpen = !isOpen"
          :aria-expanded="isOpen"
          aria-controls="contentIdForA11y2"
        />
      </div>

      <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide"
        v-model="isOpen"
      >
        <template #trigger>
          <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2"
            :aria-expanded="isOpen"
          >
            <strong>Datos</strong>
          </div>
        </template>

        <div class="rows" style="text-align: center">
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Tags">
                  <b-autocomplete
                    rounded
                    ref="fieldnameTag"
                    placeholder="Introduce el nombre del tag"
                    group-field="type"
                    group-options="n_tags"
                    open-on-focus
                    required
                    :clearable="true"
                    :data="filtrarObjeto"
                    native-value="tag"
                    v-model="n_tags"
                  >
                  </b-autocomplete
                ></b-field>
              </div>

              <div class="column">
                <b-field label="Titulo">
                  <b-input
                    rounded
                    v-model="titulo"
                    ref="fieldnameTitulo"
                    placeholder="Escribe el titulo"
                    native-value="titulo"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de publicación">
                  <b-datepicker
                    rounded
                    ref="fieldfchin"
                    :show-week-number="true"
                    :locale="locale"
                    placeholder="Click to select..."
                    icon="calendar-multiselect"
                    :icon-right="fchin ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDate"
                    trap-focus
                    required
                    :value="fchin"
                    native-value="fchin"
                    v-model="fchin"
                  >
                  </b-datepicker
                ></b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de expiración">
                  <b-datepicker
                    rounded
                    ref="fieldfchin1"
                    :show-week-number="true"
                    :locale="locale"
                    placeholder="Click to select..."
                    icon="calendar-multiselect"
                    :icon-right="fchin1 ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDate1"
                    trap-focus
                    required
                    :value="fchin1"
                    native-value="fchin1"
                    v-model="fchin1"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Url Archivo">
                  <b-input
                    rounded
                    v-model="url"
                    ref="fieldnameUrl"
                    placeholder="Ingresa URL"
                    native-value="url"
                    type="textarea"
                    maxlength="500"
                  >
                  </b-input>
                </b-field>
              </div>

              <div class="column">
                <div class="rows">
                  <div class="row">
                    <br />
                    <h1>Subir archivo</h1>

                    <br />
                  </div>
                  <div class="row">
                    <b-field
                      class="file is-info"
                      :class="{ 'has-name': !!file2 }"
                    >
                      <b-tooltip
                        label="Subir Archivo"
                        position="is-bottom"
                        type="is-info is-light"
                      >
                        <b-upload
                          ref="avatarInput"
                          @input="changeImage($event)"
                          v-model="file2"
                          class="file-label"
                          rounded
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label"></span>
                          </span>
                          <span class="file-name" v-if="file2">
                            {{ file2.name }}
                          </span>

                          <div class="tags"></div>
                        </b-upload>
                      </b-tooltip>
                    </b-field>

                    <div
                      class="column is-two-fifths"
                      v-if="avatar || url != ''"
                    >
                      <img
                        :src="avatar"
                        class="image height"
                        alt="No se encontro imagen"
                      />&nbsp;
                      <b-tooltip
                        label="Eliminar"
                        position="is-bottom"
                        type="is-danger is-light"
                      >
                        <a @click="eliminarview()"
                          ><b-icon icon="delete" type="is-danger">
                            <b-tooltip
                              label="Borrar Imagen"
                              position="is-bottom"
                              type="is-info is-light"
                            >
                            </b-tooltip> </b-icon></a></b-tooltip
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b-tooltip
                        label="Guardar"
                        position="is-bottom"
                        type="is-success is-light"
                      >
                        <a @click="AgregarDatoTag()"
                          ><b-icon icon="content-save" type="is-success">
                            <b-tooltip
                              label="Guardar Comunicado"
                              position="is-bottom"
                              type="is-info is-light"
                            >
                            </b-tooltip></b-icon></a
                      ></b-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </section>
    <div class="columns">
      <div class="column">
        <b-table
          v-if="isOpen == false"
          style="font-size: 18px"
          :data="isEmpty ? [] : comunicados"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          detailed
          detail-key="id"
          :show-detail-icon="showDetailIcon"
        >
          <template>
            <b-table-column
              field="fecha_mod"
              label="Fecha de modificación"
              sortable
              searchable
              v-slot="props"
            >
              <template v-if="showDetailIcon">
                {{ props.row.fecha_mod }}
              </template>
              <template v-else>
                <a @click="props.toggleDetails(props.row)">
                  {{ props.row.fecha_mod }}
                </a>
              </template>
            </b-table-column>
            <b-table-column
              field="tags"
              label="Tags"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.tags }}
            </b-table-column>
            <b-table-column
              field="titulo"
              label="Titulo"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.titulo }}
            </b-table-column>
            <b-table-column
              field="fecha_pub"
              label="Fecha de publicación"
              searchable
              sortable
              v-slot="props"
            >
              {{ props.row.fecha_pub }}
            </b-table-column>
            <b-table-column
              field="fecha_exp"
              label="Fecha de expiración"
              searchable
              sortable
              v-slot="props"
            >
              {{ props.row.fecha_exp }}
            </b-table-column>

            <b-table-column
              field="publicado"
              label="Publicado"
              sortable
              v-slot="props"
            >
              <b-checkbox
                :value="props.row.publicado"
                @input="CambiarValor(props.row.publicado, props.row.id, 4)"
                native-value="publicado"
              >
              </b-checkbox>
            </b-table-column>
            <b-table-column field="url" label="URL Archivo" v-slot="props">
              <a class="button is-light" :href="props.row.url" target="_blank">
                <b-icon icon="file-eye" type="is-info"></b-icon>
              </a>
            </b-table-column>

            <b-table-column
              field="Eliminar"
              label="Eliminar"
              sortable
              v-slot="props"
            >
              <a
                class="button is-light"
                @click="cambiarEstado(props.row.id, 5)"
                target="_blank"
              >
                <b-icon icon="delete-empty" type="is-danger"></b-icon>
              </a>
            </b-table-column>
          </template>
          <template #detail="props">
            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Tag</strong>
                  </div>
                  <div class="row">
                    <b-autocomplete
                      rounded
                      ref="fieldnameTag"
                      placeholder="Introduce el nombre del tag"
                      group-field="type"
                      group-options="n_tags"
                      open-on-focus
                      required
                      :clearable="true"
                      :data="filtrarObjeto"
                      @select="
                        (option2) => MandarDatoTag(option2, props.row.id, 1)
                      "
                      :value="props.row.tags"
                      native-value="tag"
                      v-model="props.row.tags"
                    >
                    </b-autocomplete>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Titulo</strong>
                    <b-input
                      rounded
                      v-model="props.row.titulo"
                      ref="fieldnameTitulo"
                      placeholder="Escribe el titulo"
                      @input="Textoeditable(props.row.titulo, props.row.id, 2)"
                      :value="props.row.titulo"
                      native-value="titulo"
                    >
                    </b-input>
                  </div>
                  <div class="row"></div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Fecha de publicación</strong>
                  </div>
                  <div class="row">
                    <b-datepicker
                      rounded
                      ref="fieldfchin1"
                      :show-week-number="true"
                      :locale="locale"
                      placeholder="Click to select..."
                      icon="calendar-multiselect"
                      :icon-right="fchin1 ? 'close-circle' : ''"
                      icon-right-clickable
                      @icon-right-click="clearDate1"
                      trap-focus
                      required
                      :value="fchin1"
                      native-value="fchin1"
                      @input="
                        (option) => CapturarFecha(option, props.row.id, 6)
                      "
                    >
                    </b-datepicker>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Fecha de expiración</strong>
                  </div>
                  <div class="row">
                    <b-datepicker
                      rounded
                      ref="fieldfchin"
                      :show-week-number="true"
                      :locale="locale"
                      placeholder="Click to select..."
                      icon="calendar-multiselect"
                      :icon-right="fchin ? 'close-circle' : ''"
                      icon-right-clickable
                      @icon-right-click="clearDate"
                      trap-focus
                      required
                      :value="fchin"
                      native-value="fchin"
                      @input="
                        (option) => CapturarFecha(option, props.row.id, 3)
                      "
                    >
                    </b-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Url Archivo</strong>
                  </div>
                  <div class="row">
                    <b-input
                      rounded
                      v-model="props.row.url"
                      ref="fieldnameUrl"
                      placeholder="Ingresa URL"
                      @input="Textoeditable(props.row.url, props.row.id, 7)"
                      :value="props.row.url"
                      native-value="url"
                      type="textarea"
                      maxlength="500"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row"><strong>Subir archivo</strong><br /></div>
                  <div class="row">
                    <b-field
                      class="file is-info"
                      :class="{ 'has-name': !!file2 }"
                    >
                      <b-tooltip
                        label="Subir Archivo"
                        position="is-bottom"
                        type="is-info is-light"
                      >
                        <b-upload
                          ref="avatarInput"
                          @input="changeImage($event)"
                          v-model="file2"
                          class="file-label"
                          rounded
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label"></span>
                          </span>
                          <span class="file-name" v-if="file2">
                            {{ file2.name }}
                          </span>

                          <div class="tags"></div> </b-upload
                      ></b-tooltip>
                    </b-field>
                    <div class="column is-two-fifths" v-if="avatar">
                      <img
                        :src="avatar"
                        class="image height"
                        alt="No se encontro imagen"
                      />&nbsp;

                      <b-tooltip
                        label="Eliminar"
                        position="is-bottom"
                        type="is-danger is-light"
                      >
                        <a @click="eliminarview()"
                          ><b-icon icon="xamarin" type="is-danger">
                          </b-icon></a></b-tooltip
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b-tooltip
                        label="Actualizar"
                        position="is-bottom"
                        type="is-success is-light"
                      >
                        <a @click="updateImg(props.row.id)"
                          ><b-icon icon="check-outline" type="is-success">
                          </b-icon></a
                      ></b-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #empty>
            <div class="has-text-centered">Sin comunicados aun</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ComunicadosService from "@/services/ComunicadosService";
export default {
  name: "tablaComunicados",
  data() {
    return {
      n_tags: null,
      isOpen: false,
      file2: null,
      fchin: new Date(),
      fchin1: new Date(),
      isEmpty: false,
      comunicados: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      url: "",
      nuevoVal: "",
      edit: null,
      titulo: null,
      status_letter: true,
      nameTag: "",
      locale: undefined, // Browser locale
      icon_edit: "lead-pencil",
      status_t: "is-info is-light",
      status_edit: false,
      avatar: "",
      fechaE: undefined,
      fechaP: undefined,
      datoUrl: undefined,

      showDetailIcon: true,

      dataTag: [
        {
          type: "tags",
          n_tags: [],
        },
      ],
    };
  },
  mounted() {
    this.obtenerComunicados();
    this.obtenertags();
  },
  computed: {
    filtrarObjeto() {
      const newData = [];
      this.dataTag.forEach((element) => {
        const n_tags = element.n_tags.filter(
          (item) => item.toLowerCase().indexOf(this.nameTag.toLowerCase()) >= 0
        );

        if (n_tags.length) {
          newData.push({ type: element.type, n_tags });
        }
      });
      return newData;
    },
  },
  methods: {
    async updateImg(id) {
      this.file.valueOf().id = id;
      if (this.$refs["avatarInput"].newValue && this.avatar != null) {
        const upload_image = await ComunicadosService.uploadImage(
          this.file,
          id
        );
        if (upload_image) {
          this.message("Los cambios fueron guardados con exito", "is-success");
          this.obtenerComunicados();
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      } else {
        this.message("Se realizaron los cambios con exito", "is-success");

        this.obtenerComunicados();
      }
    },

    async AgregarDatoTag() {
      let anioP = this.fchin.getFullYear();
      let mesP = this.fchin.getMonth() + 1;
      let diaP = this.fchin.getDate();
      this.fechaP = anioP + "-" + mesP + "-" + diaP;

      let anioE = this.fchin1.getFullYear();
      let mesE = this.fchin1.getMonth() + 1;
      let diaE = this.fchin1.getDate();
      this.fechaE = anioE + "-" + mesE + "-" + diaE;

      if (this.url !== "") {
        this.datoUrl = this.url;

        try {
          const respuesta = await ComunicadosService.NuevoComunicado(
            this.n_tags,
            this.titulo,
            this.fechaE,
            this.fechaP,
            this.datoUrl
          );

          if (respuesta) {
            this.message("Se guardo el comunicado con exito", "is-success");
            location.reload();
          } else {
            this.message("Error al realizar el comunicado", "is-danger");
          }
        } catch (e) {
          // Manejar errores aquí, si es necesario
          console.log(e);
        }
      }

      if (this.$refs["avatarInput"].newValue && this.avatar !== null) {
        const upload_image = await ComunicadosService.NuevoComuniFile(
          this.file,
          this.n_tags,
          this.titulo,
          this.fechaE,
          this.fechaP
        );

        if (upload_image) {
          this.message("Se guardo el comunicado con exito", "is-success");
          location.reload();
        } else {
          this.message("Error al realizar el comunicado", "is-danger");
        }
      }
    },
    changeImage(e) {
      this.file = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.avatar = this.result;
      };
    },
    eliminarview() {
      this.avatar = null;
      this.file = "";
      this.file2 = null;
      this.url = "";
    },
    clearDate() {
      this.fchin = null;
    },
    clearDate1() {
      this.fchin1 = null;
    },
    async CambiarValor(valor, id, opcion) {
      if (valor == "false") {
        this.nuevoVal = "true";
      } else {
        this.nuevoVal = "false";
      }
      this.cambiarEstado(id, opcion);
    },

    Textoeditable(texto, id, opcion) {
      this.nuevoVal = texto;

      this.cambiarEstado(id, opcion);
    },

    CapturarFecha(fecha, id, opcion) {
      let anio = fecha.getFullYear();
      let mes = fecha.getMonth() + 1;
      let dia = fecha.getDate();
      this.nuevoVal = anio + "-" + mes + "-" + dia;
      this.cambiarEstado(id, opcion);
    },
    async cambiarEstado(id, opcion) {
      this.cargando = true;
      try {
        const respuesta = await ComunicadosService.cambiarEstado(
          this.nuevoVal,
          id,
          opcion
        );

        if (respuesta) {
          if (opcion == 5) {
            this.message("El comunicado ha sido eliminado", "is-success");
          }
          if (opcion == 6) {
            this.message("El comunicado se enviará por correo", "is-success");
          }
          this.obtenerComunicados();
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.cargando = false;
      }
    },

    async obtenerComunicados() {
      this.cargando = true;
      this.comunicados = await ComunicadosService.obtenerComunicados();
      this.cargando = false;
    },
    async obtenertags() {
      this.cargando = true;
      this.tags = await ComunicadosService.obtenertags();
      this.cargando = false;

      if (this.tags) {
        for (const tagData of this.tags) {
          this.tag = tagData.tag;
          this.dataTag[0].n_tags.push(this.tag);
        }
      }
    },
    async MandarDatoTag(Dato, id, opcion) {
      if (Dato != null) {
        this.cargando = true;
        const respuesta = await ComunicadosService.obtenertag(Dato);
        this.cargando = false;
        this.nuevoVal = respuesta[0].tag;

        this.cambiarEstado(id, opcion);
      } else {
        this.nuevoVal = null;
      }
    },

    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

