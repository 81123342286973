<template>
  <div class="box">
    <h1 class="title">Participantes</h1>
    <b-table
      :data="Participantes"
      :loading="cargando"
      detailed
      custom-detail-row
    >
      <b-table-column field="asunto" label="ASUNTO" sortable v-slot="props">
        {{ props.row[1].asunto }}
      </b-table-column>
      <b-table-column field="fecha" label="FECHA" sortable v-slot="props">
        {{ props.row[1].fecha }}
      </b-table-column>
      <b-table-column
        field="estatus"
        label="ESTATUS"
        sortable
        v-slot="props"
        centered
      >
        <span
          :class="[
            'tag',
            { 'is-warning is-light': props.row[1].estatus == 'ACTIVO' },
            { 'is-danger is-light': props.row[1].estatus == 'FINALIZADO' },
          ]"
        >
          {{ props.row[1].estatus }}</span
        >
      </b-table-column>
      <b-table-column label="Participaron" sortable v-slot="props" centered>
        <span
          :class="[
            'tag',
            { 'is-success is-light': props.row[2].participaron != 0 },
            { 'is-danger is-light': props.row[2].participaron == 0 },
          ]"
        >
          {{ props.row[2].participaron }}</span
        >
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <td></td>
        <td>
          <strong> Participantes</strong>
        </td>
        <td><strong>CVE. Agencias</strong></td>
        <td><strong>Email</strong></td>
        <td></td>

        <tr v-for="items in props.row[0]" :key="items.id">
          <td></td>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;{{ items.participante }}</td>
          <td>{{ items.cve_agencia }}</td>
          <td>{{ items.email }}</td>
          <td></td>
        </tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import CapacitacionesService from "@/services/CapacitacionesService";
export default {
  data() {
    return {
      Participantes: [],
      cargando: false,
    };
  },

  mounted() {
    this.obtenerParticipantes();
  },

  methods: {
    async obtenerParticipantes() {
      this.cargando = true;
      this.Participantes = await CapacitacionesService.obtenerParticipantes();

      for (const participante of this.Participantes) {
        const element = participante[0];
        participante.push({
          participaron: element.length,
        });
      }

      this.cargando = false;
    },
  },
};
</script>

