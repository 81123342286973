<template>
  <div class="box">
    <h1 class="title">Contactos</h1>

    <b-button outlined type="is-success" @click="CrearReporte(contactos)"
      >Descargar</b-button
    >
    <div class="columns">
      <div class="column">
        <h1>Resultados encontrados: {{ contactos.length }}</h1>
        <b-table
          style="font-size: 18px"
          :data="contactos"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
        >
          <template>
            <b-table-column
              field="id_iris"
              label="ID IRIS"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.id_iris }}
            </b-table-column>
            <b-table-column
              field="username"
              label="USER NAME"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.username }}
            </b-table-column>
            <b-table-column
              field="nombres"
              label="NOMBRES"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.nombres }}
            </b-table-column>
            <b-table-column
              field="apellidos"
              label="APELLIDOS"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.apellidos }}
            </b-table-column>
            <b-table-column
              field="email"
              label="EMAIL"
              searchable
              sortable
              v-slot="props"
            >
              {{ props.row.email }}
            </b-table-column>

            <b-table-column
              field="ver_reportes"
              label="VER REPORTES"
              sortable
              v-slot="props"
              centered
            >
              <b-checkbox
                :value="props.row.ver_reportes"
                @input="
                  CambiarValor(props.row.ver_reportes, props.row.id_iris, 1)
                "
                native-value="ver_reportes"
              >
              </b-checkbox>
            </b-table-column>
            <b-table-column
              field="subir_facturas"
              label="SUBIR FACTURAS"
              sortable
              v-slot="props"
              centered
            >
              <b-checkbox
                :value="props.row.subir_facturas"
                @input="
                  CambiarValor(props.row.subir_facturas, props.row.id_iris, 2)
                "
                native-value="subir_facturas"
              >
              </b-checkbox>
            </b-table-column>
            <b-table-column
              field="solicitar_reembolso"
              label="SOLICITAR REEMBOLSOS"
              sortable
              v-slot="props"
              centered
            >
              <b-checkbox
                :value="props.row.solicitar_reembolso"
                @input="
                  CambiarValor(
                    props.row.solicitar_reembolso,
                    props.row.id_iris,
                    3
                  )
                "
                native-value="solicitar_reembolso"
              >
              </b-checkbox>
            </b-table-column>

            <b-table-column
              field="ver_tabla_comisiones"
              label="VER COMISION"
              sortable
              v-slot="props"
              centered
            >
              <b-checkbox
                :value="props.row.ver_tabla_comisiones"
                @input="
                  CambiarValor(
                    props.row.ver_tabla_comisiones,
                    props.row.id_iris,
                    4
                  )
                "
                native-value="ver_tabla_comisiones"
              >
              </b-checkbox>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ClientesService from "@/services/ClientesService";
import Constantes from "@/Constantes";
export default {
  name: "AllClientes",
  data() {
    return {
      contactos: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      nuevoVal: null,
    };
  },
  mounted() {
    this.obtenerClientes();
  },
  methods: {
    async CambiarValor(valor, id, opcion) {
      if (valor == "false") {
        this.nuevoVal = "true";
      } else {
        this.nuevoVal = "false";
      }

      this.cargando = true;
      try {
        const respuestsa = await ClientesService.cambiarEstado(
          this.nuevoVal,
          id,
          opcion
        );
        if (respuestsa) {
          this.message("Estado cambiado", "is-success");
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.cargando = false;
      }
    },
    async obtenerClientes() {
      this.cargando = true;
      this.contactos = await ClientesService.obtenerClientes();
      this.cargando = false;
    },
    async CrearReporte(contactos) {
      try {
        const respuesta = await ClientesService.CrearReporte(contactos);
        if (respuesta) {
          this.message("Archivo creado correctamente", "is-success");

          let date = new Date();
          let output =
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear();

          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + output;
          window.open(url);
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      } catch (e) {
        this.message("Error al crear archivo: " + e, "is-danger");
        console.log(e);
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

