import HttpService from "@/services/HttpService";
const CapacitacionesService = {

    async obtenerCapacitaciones() {
        return await HttpService.post("/obtenerCapacitaciones.php");
    },
    async obtenerParticipantes() {
        return await HttpService.post("/obtenerParticipantes.php");
    },
    async cambiarEstado(nuevoVal, id, opcion) {
        return await HttpService.post("/cambiarCapacitacion.php", {
            nuevoVal, id, opcion
        });
    },
    async NuevaCapacitacion(fechaP, asunto, duracion, descrip, Participantes, url) {
        return await HttpService.post("/NuevaCapacitacion.php", {
            fechaP: fechaP, asunto: asunto, duracion: duracion, descrip: descrip, Participantes: Participantes, url: url
        });
    },




};
export default CapacitacionesService;