<template>
  <div class="box">
    <h1 class="title">Directorio</h1>
    <section>
      <div class="block">
        <b-button
          label="+ Nuevo"
          type="is-info"
          size="is-medium"
          @click="isOpen = !isOpen"
          :aria-expanded="isOpen"
          aria-controls="contentIdForA11y2"
        />
      </div>

      <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide"
        v-model="isOpen"
      >
        <template #trigger>
          <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2"
            :aria-expanded="isOpen"
          >
            <strong>Datos</strong>
          </div>
        </template>
        <div class="rows p-6">
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Nombre">
                  <b-input
                    rounded
                    v-model="nombre"
                    ref="fieldnombre"
                    placeholder="ingresa nombre"
                    native-value="nombre"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Areas">
                  <b-autocomplete
                    rounded
                    ref="fieldnameArea"
                    placeholder="Introduce el nombre del Area"
                    group-field="type"
                    group-options="n_Areas"
                    open-on-focus
                    required
                    :clearable="true"
                    :data="filtrarObjeto"
                    native-value="Area"
                    v-model="area"
                  >
                  </b-autocomplete
                ></b-field>
              </div>

              <div class="column">
                <b-field label="Puesto">
                  <b-input
                    rounded
                    v-model="puesto"
                    ref="fieldpuesto"
                    placeholder="Escribe el puesto"
                    native-value="puesto"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Email">
                  <b-input
                    type="email"
                    rounded
                    v-model="email"
                    ref="fieldemail"
                    placeholder="Escribe el email"
                    native-value="email"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Skype">
                  <b-input
                    rounded
                    v-model="skype"
                    ref="fieldSkype"
                    placeholder="Ingrese el usuari Skype"
                    native-value="skype"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de Nacimiento">
                  <b-datepicker v-model="cumple" :mobile-native="false">
                    <template v-slot:trigger>
                      <b-button icon-left="calendar-today" type="is-info" />
                    </template>
                  </b-datepicker>
                  <b-input
                    expanded
                    readonly
                    :value="selectedString"
                    rounded
                    Disabled
                  />
                </b-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns">
              <div class="column">
                <b-field label="Whatsapp">
                  <b-input
                    rounded
                    v-model="whatsapp"
                    ref="fieldnamewhatsapp"
                    placeholder="Ingresa whatsapp"
                    native-value="whatsapp"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Teléfono">
                  <b-input
                    rounded
                    v-model="telefono"
                    ref="fieldnametelefono"
                    placeholder="Ingresa Teléfono"
                    native-value="telefono"
                  >
                  </b-input>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Extensión">
                  <b-input
                    rounded
                    v-model="extension"
                    ref="fieldextension"
                    placeholder="extension del contacto"
                    native-value="extension"
                  >
                  </b-input>
                </b-field>

                <b-tooltip
                  v-if="nombre != null && nombre != ''"
                  label="Guardar"
                  position="is-bottom"
                  type="is-success is-light"
                >
                  <a @click="GuardarNewContacto()"
                    ><b-icon icon="content-save" type="is-success">
                      <b-tooltip
                        label="Guardar Comunicado"
                        position="is-bottom"
                        type="is-info is-light"
                      >
                      </b-tooltip></b-icon></a
                ></b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </section>
    <div class="columns">
      <div class="column">
        <b-table
          v-if="isOpen == false"
          style="font-size: 18px"
          :data="isEmpty ? [] : Directorio"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          detailed
          detail-key="ID"
          :show-detail-icon="showDetailIcon"
        >
          <template>
            <b-table-column
              field="nombre"
              label="Nombre"
              sortable
              searchable
              v-slot="props"
            >
              <template v-if="showDetailIcon">
                {{ props.row.nombre }}
              </template>
              <template v-else>
                <a @click="props.toggleDetails(props.row)">
                  {{ props.row.nombre }}
                </a>
              </template>
            </b-table-column>
            <b-table-column
              field="puesto"
              label="Puesto"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.puesto }}
            </b-table-column>
            <b-table-column
              field="area"
              label="Area"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.area }}
            </b-table-column>
            <b-table-column
              field="extension"
              label="Extensión"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.extension }}
            </b-table-column>
            <b-table-column
              field="telefono"
              label="Telefono"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.telefono }}
            </b-table-column>
            <b-table-column
              field="skype"
              label="Skype"
              sortable
              v-slot="props"
              centered
            >
              <a
                v-if="props.row.skype != null && props.row.skype != ''"
                class="button is-light"
                :href="'skype:' + props.row.skype + '?chat'"
                target="_blank"
              >
                <b-icon icon="skype" type="is-info"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              field="email"
              label="Email"
              sortable
              v-slot="props"
              centered
            >
              <a
                v-if="props.row.correo != null && props.row.correo != ''"
                class="button is-light"
                :href="'mailto:' + props.row.correo"
                target="_blank"
              >
                <b-icon icon="email" type="is-dark"></b-icon>
              </a>
            </b-table-column>

            <b-table-column
              field="whatsapp"
              label="Whatsapp"
              sortable
              v-slot="props"
              centered
            >
              <a
                v-if="props.row.whatsapp != null && props.row.whatsapp != ''"
                class="button is-light"
                :href="
                  'https://api.whatsapp.com/send?phone=' + props.row.whatsapp
                "
                target="_blank"
              >
                <b-icon icon="whatsapp" type="is-success"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              field="cumple"
              label="Cumpleaños"
              sortable
              v-slot="props"
              centered
            >
              <a>
                <b-icon icon="cake" type="is-primary"></b-icon>
              </a>
              <br />
              <span class="tag is-primary is-light">
                {{ props.row.cumple }}
              </span>
            </b-table-column>

            <b-table-column
              field="Eliminar"
              label="Eliminar"
              sortable
              v-slot="props"
              centered
            >
              <a
                class="button is-light"
                @click="eliminar(props.row.ID, 1)"
                target="_blank"
              >
                <b-icon icon="delete-empty" type="is-danger"></b-icon>
              </a>
            </b-table-column>
          </template>
          <template #detail="props">
            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Nombre</strong>
                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 2"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      rounded
                      v-model="props.row.nombre"
                      ref="fieldnombre"
                      placeholder="Escribe el nombre"
                      @input="Textoeditable(props.row.ID, 2, props.row.nombre)"
                      :value="props.row.nombre"
                      native-value="nombre"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Puesto</strong>
                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 3"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      rounded
                      v-model="props.row.puesto"
                      ref="fieldpuesto"
                      placeholder="Escribe el puesto"
                      @input="Textoeditable(props.row.ID, 3, props.row.puesto)"
                      :value="props.row.puesto"
                      native-value="puesto"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Area</strong>
                    <b-autocomplete
                      rounded
                      ref="fieldnameArea"
                      placeholder="Introduce el nombre del Area"
                      group-field="type"
                      group-options="n_Areas"
                      open-on-focus
                      required
                      :clearable="true"
                      :data="filtrarObjeto"
                      native-value="Area"
                      @select="
                        (option2) => MandarDatoArea(props.row.ID, 4, option2)
                      "
                      :value="props.row.area"
                      v-model="props.row.area"
                    >
                    </b-autocomplete>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Skype</strong>
                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 5"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      rounded
                      v-model="props.row.skype"
                      ref="fieldskype"
                      placeholder="Escribe el skype"
                      @input="Textoeditable(props.row.ID, 5, props.row.skype)"
                      :value="props.row.skype"
                      native-value="skype"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Email</strong>
                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 6"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      type="email"
                      rounded
                      v-model="props.row.correo"
                      ref="fieldcorreo"
                      placeholder="Escribe el correo"
                      @input="Textoeditable(props.row.ID, 6, props.row.correo)"
                      :value="props.row.correo"
                      native-value="correo"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Telefonó</strong>
                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 7"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      rounded
                      v-model="props.row.telefono"
                      ref="fieldtelefono"
                      placeholder="Escribe la telefono"
                      @input="
                        Textoeditable(props.row.ID, 7, props.row.telefono)
                      "
                      :value="props.row.telefono"
                      native-value="telefono"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Extensión</strong>
                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 8"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      rounded
                      v-model="props.row.extension"
                      ref="fieldextension"
                      placeholder="Escribe la extension"
                      @input="
                        Textoeditable(props.row.ID, 8, props.row.extension)
                      "
                      :value="props.row.extension"
                      native-value="extension"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <strong>Whatsapp</strong>

                    &nbsp;&nbsp;&nbsp;<b-tooltip
                      v-if="statusbtn == 9"
                      label="Actualizar"
                      type="is-info is-light"
                    >
                      <a @click="cambiarEstado()"
                        ><b-icon icon="update" type="is-info">
                          <b-tooltip label="Actualizar" type="is-info is-light">
                          </b-tooltip></b-icon></a
                    ></b-tooltip>
                    <b-input
                      rounded
                      v-model="props.row.whatsapp"
                      ref="fieldwhatsapp"
                      placeholder="ingresa el whatsapp"
                      @input="
                        Textoeditable(props.row.ID, 9, props.row.whatsapp)
                      "
                      :value="props.row.whatsapp"
                      native-value="whatsapp"
                    >
                    </b-input>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #empty>
            <div class="has-text-centered">Sin Contactos aun</div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-datepicker
          inline
          v-model="date"
          :events="events"
          indicators="dots"
          @input="(option) => CapturarFecha(option)"
        >
        </b-datepicker>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="card">
              <h3 class="card__title">Title</h3>
              <p class="card__content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <div class="card__date">April 15, 2022</div>
              <div class="card__arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  height="15"
                  width="15"
                >
                  <path
                    fill="#fff"
                    d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DirectorioService from "@/services/DirectorioService";
export default {
  name: "tablaDirectorio",
  data() {
    return {
      cumple: null,
      date: new Date(),
      events: [],
      isFullPage: true,
      isOpen: false,
      isEmpty: false,
      Directorio: [],
      extension: null,
      loading: false,
      telefono: null,
      edit: null,
      nombre: null,
      status_edit: false,
      showDetailIcon: true,
      puesto: null,
      area: null,
      skype: null,
      email: null,
      whatsapp: null,
      n_Areas: null,
      nuevoVal: "",
      nameArea: "",
      statusbtn: null,
      btnSave: false,
      dataArea: [
        {
          type: "Areas",
          n_Areas: [],
        },
      ],
    };
  },
  mounted() {
    this.obtenerDirectorio();
    this.obtenerAreas();
  },
  computed: {
    filtrarObjeto() {
      const newData = [];
      this.dataArea.forEach((element) => {
        const n_Areas = element.n_Areas.filter(
          (item) => item.toLowerCase().indexOf(this.nameArea.toLowerCase()) >= 0
        );

        if (n_Areas.length) {
          newData.push({ type: element.type, n_Areas });
        }
      });
      return newData;
    },
    selectedString() {
      return this.cumple ? this.cumple.toDateString() : "";
    },
  },

  methods: {
    CapturarFecha(option) {
      console.log(option);
    },
    async GuardarNewContacto() {
      this.loading = true;
      const respuesta = await DirectorioService.NuevoContacto(
        this.nombre,
        this.area,
        this.puesto,
        this.email,
        this.skype,
        this.telefono,
        this.whatsapp,
        this.extension,
        this.cumple
      );
      if (respuesta) {
        location.reload();
      }
    },

    async obtenerDirectorio() {
      this.loading = true;
      this.Directorio = await DirectorioService.obtenerDirectorio();
      let anio = new Date();
      if (this.Directorio) {
        for (const item of this.Directorio) {
          let contador = 0;
          do {
            let cumple = item.cumple;
            let partes = cumple.split("-");
            partes[0] = anio.getFullYear() + contador;
            cumple = partes.join("-");
            const prueba1 = {
              date: new Date(cumple + "T00:00:00"),
              type: "is-primary",
            };
            this.events.push(prueba1);
            contador = contador + 1;
            console.log(prueba1);
          } while (contador <= 2);
        }
      }

      this.loading = false;
    },

    async obtenerAreas() {
      this.loading = true;
      this.Areas = await DirectorioService.obtenerAreas();
      this.loading = false;

      if (this.Areas) {
        for (const areaObj of this.Areas) {
          this.Area = areaObj.area;
          this.dataArea[0].n_Areas.push(this.Area);
        }
      }
    },
    async MandarDatoArea(id, opcion, Dato) {
      if (Dato != null) {
        this.loading = true;
        const respuesta = await DirectorioService.obtenerArea(Dato);
        this.loading = false;
        this.nuevoVal = respuesta[0].area;
        this.nuevoid = id;
        this.nuevoOption = opcion;
        this.cambiarEstado();
      } else {
        this.nuevoVal = null;
      }
    },
    Textoeditable(id, opcion, texto) {
      this.nuevoVal = texto;
      this.statusbtn = opcion;
      this.nuevoid = id;
      this.nuevoOption = opcion;
    },
    eliminar(id, opcion) {
      this.nuevoid = id;
      this.nuevoOption = opcion;
      this.cambiarEstado();
    },
    async cambiarEstado() {
      this.loading = true;
      try {
        const respuesta = await DirectorioService.cambiarEstado(
          this.nuevoid,
          this.nuevoOption,
          this.nuevoVal
        );
        if (respuesta) {
          if (this.nuevoOption == 1) {
            this.message("El contacto ha sido eliminado", "is-success");
          }
          if (this.nuevoOption > 1) {
            this.message("El contacto ha sido actualizado", "is-success");
            this.statusbtn = null;
          }
          this.obtenerDirectorio();
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.loading = false;
      }
    },
    openLoading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10 * 1000);
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

<style scoped>
.file {
  justify-content: center;
}
/* this card is inspired form this - https://georgefrancis.dev/ */

.card {
  --border-radius: 0.75rem;
  --primary-color: #7257fa;
  --secondary-color: #3c3852;
  width: 210px;
  padding: 1rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  background: #f1f1f3;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
  position: relative;
}

.card > * + * {
  margin-top: 1.1em;
}

.card .card__content {
  color: var(--secondary-color);
  font-size: 0.86rem;
}

.card .card__title {
  padding: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.card .card__date {
  color: #6e6b80;
  font-size: 0.8rem;
}

.card .card__arrow {
  position: absolute;
  background: var(--primary-color);
  padding: 0.4rem;
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  bottom: 0;
  right: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card svg {
  transition: 0.2s;
}

/* hover */
.card:hover .card__title {
  color: var(--primary-color);
  text-decoration: underline;
}

.card:hover .card__arrow {
  background: #111;
}

.card:hover .card__arrow svg {
  transform: translateX(3px);
}
</style>