import HttpService from "@/services/HttpService";
const DirectorioService = {
  async obtenerDirectorio() {
    return await HttpService.post("/obtenerDirectorio.php");
  },

  async Eliminar(id, option) {
    return await HttpService.post("/eliminarArcR.php", {
      id,
      option,
    });
  },
  async obtenerAgencia(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenerAgencia.php", cargaUtil);
  },
  async obtenerAreas() {
    return await HttpService.post("/obtenerAreas.php");
  },
  async obtenerArea(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenerArea.php", cargaUtil);
  },

  async NuevoContacto(
    nombre,
    area,
    puesto,
    email,
    skype,
    telefono,
    whatsapp,
    extension,
    cumple
  ) {
    return await HttpService.post("/NuevoContacto.php", {
      nombre: nombre,
      area: area,
      puesto: puesto,
      email: email,
      skype: skype,
      telefono: telefono,
      whatsapp: whatsapp,
      extension: extension,
      cumple: cumple,
    });
  },
  async cambiarEstado(id, opcion, nuevoVal) {
    return await HttpService.post("/cambiarContacto.php", {
      id,
      opcion,
      nuevoVal,
    });
  },
};
export default DirectorioService;
