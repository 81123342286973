<template>
  <div class="box">
    <h1 class="title">Comisiones</h1>

    <div class="block">
      <b-button
        label="+ Nuevo"
        type="is-info"
        size="is-medium"
        @click="isOpen = !isOpen"
        :aria-expanded="isOpen"
        aria-controls="contentIdForA11y2"
      />
    </div>

    <b-collapse
      aria-id="contentIdForA11y2"
      class="panel"
      animation="slide"
      v-model="isOpen"
    >
      <template #trigger>
        <div
          class="panel-heading"
          role="button"
          aria-controls="contentIdForA11y2"
          :aria-expanded="isOpen"
        >
          <strong>Datos</strong>
        </div>
      </template>

      <div class="columns" style="text-align: center">
        <div class="column">
          <br />
          <b-field label="Nombre">
            <b-input
              rounded
              v-model="nombre"
              ref="fieldnamenombre"
              placeholder="Escribe el nombre"
              native-value="nombre"
            >
            </b-input>
          </b-field>
        </div>
        <div class="column" style="text-align: center">
          <div class="rows">
            <div class="row">
              <br />
              <h3 class="title is-4">Subir archivo</h3>

              <br />

              <b-field class="file is-info" :class="{ 'has-name': !!file2 }">
                <b-upload
                  ref="avatarInput"
                  @input="changeImage($event)"
                  v-model="file2"
                  class="file-label"
                  rounded
                >
                  <b-tooltip
                    label="Subir Imagen"
                    position="is-bottom"
                    type="is-info is-light"
                  >
                  </b-tooltip>
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="file2">
                    {{ file2.name }}
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="row" v-if="avatar || ruta != ''">
              <b-tooltip
                label="Borrar Imagen"
                position="is-bottom"
                type="is-info is-light"
              >
                <a @click="eliminarview()"
                  ><b-icon icon="delete" type="is-danger">
                  </b-icon></a></b-tooltip
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b-tooltip
                label="Guardar Archivo"
                position="is-bottom"
                type="is-info is-light"
              >
                <a @click="AgregarComi()"
                  ><b-icon icon="content-save" type="is-success"> </b-icon></a
              ></b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <div class="columns">
      <div class="column">
        <b-table
          v-if="isOpen == false"
          style="font-size: 18px"
          :data="isEmpty ? [] : Comisiones"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
        >
          <template>
            <b-table-column
              field="Fecha_Registro"
              label="Fecha de modificación"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.Fecha_Registro }}
            </b-table-column>
            <b-table-column
              field="nombre"
              label="Nombre"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.Nombre }}
            </b-table-column>
            <b-table-column
              field="ruta"
              label="URL Archivo"
              v-slot="props"
              centered
            >
              <a
                class="button is-light"
                :href="location + '' + props.row.Ruta + '' + props.row.Nombre"
                target="_blank"
              >
                <b-icon icon="file-eye" type="is-info"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              field="Eliminar"
              label="Eliminar"
              sortable
              v-slot="props"
              centered
            >
              <a
                class="button is-light"
                @click="Eliminar(props.row.id)"
                target="_blank"
              >
                <b-icon icon="delete-empty" type="is-danger"></b-icon>
              </a> </b-table-column></template
        ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ComisionesService from "@/services/ComisionesService";
export default {
  name: "tablaComisiones",
  data() {
    return {
      isOpen: false,
      file2: null,
      isEmpty: false,
      Comisiones: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      nuevoVal: "",
      edit: null,
      nombre: null,
      status_letter: true,
      locale: undefined, // Browser locale
      icon_edit: "lead-pencil",
      status_t: "is-info is-light",
      status_edit: false,
      avatar: "",
      ruta: "",
      showDetailIcon: true,
      location: "https://contravel.com.mx",
    };
  },
  mounted() {
    this.obtenerComisiones();
  },

  methods: {
    async AgregarComi() {
      if (this.$refs["avatarInput"].newValue && this.avatar != null) {
        const upload_image = await ComisionesService.NuevoFileComi(
          this.file,
          this.nombre
        );
        if (upload_image) {
          this.message("Se guardo el comunicado con exito", "is-success");
          location.reload();
        } else {
          this.message("Error al realizar el comunicado ", "is-danger");
        }
      } else {
        this.message("Debes subir archivo de comisiones", "is-danger");
      }
    },
    changeImage(e) {
      this.file = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.avatar = this.result;
      };
    },
    eliminarview() {
      this.avatar = null;
      this.file = "";
      this.file2 = null;
      this.ruta = "";
    },
    async Eliminar(id) {
      this.cargando = true;
      try {
        const respuesta = await ComisionesService.Eliminar(id);

        if (respuesta) {
          this.message("El archivo ha sido eliminado", "is-success");

          this.obtenerComisiones();
        } else {
          this.message("Error al tratar de eliminar archivo", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.cargando = false;
      }
    },

    async obtenerComisiones() {
      this.cargando = true;
      this.Comisiones = await ComisionesService.obtenerComisiones();
      this.cargando = false;
    },

    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

<style scoped>
.file {
  justify-content: center;
}
</style>