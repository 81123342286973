
<template>
  <div class="box" align="center">
    <div class="rows">
      <div class="box" v-if="estadoUpload == false">
        <div class="columns">
          <div class="column">
            <b-field label="Cargar Imagenes (Se recomienda 1535 x 400)">
              <b-upload
                accept="image/gif,image/jpeg,image/jpg,image/png"
                v-model="dropFiles"
                multiple
                drag-drop
                expanded
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Suelte sus imagenes aquí o haga clic para cargar</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <div class="tags">
              <span
                v-for="(files, index) in dropFiles"
                :key="index"
                class="tag is-primary"
              >
                {{ files.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile(index)"
                ></button>
              </span>
            </div>
            <b-button
              v-if="dropFiles.length > 0"
              @click="save()"
              class="save"
              size="is-large"
              icon-left="content-save"
              rounded
            />
          </div>
        </div>
      </div>
      <div class="box" v-if="estadoUpload == true">
        <article class="media">
          <figure class="media-left"></figure>
          <div class="column">
            <div class="content">
              <p>
                <b-skeleton height="80px"></b-skeleton>
              </p>
            </div>
          </div>
        </article>
      </div>
      <div class="box" v-if="estado == true">
        <article class="media">
          <figure class="media-left"></figure>
          <div class="column" v-for="Slider in Sliders" :key="Slider.id">
            <div class="content">
              <p>
                <b-skeleton height="80px"></b-skeleton>
              </p>
            </div>
          </div>
        </article>
      </div>
      <div class="box" v-if="estado == false">
        <center>
          <Draggable
            v-if="Sliders.length > 0"
            ghost-class="moving-card"
            tag="ul"
            class="w-full max-w-md is-flex is-flex-direction-row"
            :list="Sliders"
            :animation="200"
            v-model="myArray"
            group="people"
            :component-data="getComponentData(Sliders)"
            :move="CambioLugar(true)"
          >
            <div
              v-for="Slider in Sliders"
              :key="Slider.id"
              class="p-4 mb-3 flex justify-between items-center bg-white shadow rounded-lg cursor-move"
            >
              <div class="column is-flex is-flex-direction-column" id="Figuras">
                <figure class="figure">
                  <p>
                    <img
                      class="image height"
                      :src="location + Slider.img_dir + Slider.nombre"
                      :alt="Slider.name"
                      style="width: 15vw"
                    />
                  </p>
                </figure>

                <b-button
                  @click="eliminar(Slider)"
                  type="is-danger"
                  icon-left="delete"
                  size="is-small"
                />
              </div>
            </div>
          </Draggable>
        </center>
      </div>
    </div>
  </div>
</template>

<script >
import SlidersService from "@/services/SlidersService";
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  data() {
    return {
      location: null,
      Sliders: [],
      lugarRemp: null,
      rango: null,
      OrigidImg: null,
      RemImg: null,
      avatar: "",
      dropFiles: [],
      files: {},
      file: null,
      sortableOptions: null,
      myArray: null,
      cambiar: false,
      newLugar: null,
      estado: false,
      estadoUpload: false,
    };
  },
  mounted() {
    this.obtenerSliders();
  },
  methods: {
    CambioLugar(valor) {
      this.cambiar = valor;

      this.Mover();
    },
    handleChange(value) {
      this.option = 1;
      this.oldLugar = value.oldIndex + 1;
      this.newLugar = value.newIndex + 1;
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    getComponentData() {
      return {
        on: {
          change: this.handleChange,
          input: this.inputChanged,
        },
        attrs: {
          wrap: true,
        },
        props: {
          value: this.activeNames,
        },
      };
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },

    async save() {
      try {
        this.estadoUpload = true;
        const respuesta = await SlidersService.subirArchivos(
          this.dropFiles,
          this.rango
        );
        this.estadoUpload = false;

        if (respuesta) {
          this.message("Se subieron archivos correctamente", "is-success");

          location.reload();
        } else {
          this.message("Error al subir archivos", "is-danger");
        }
      } catch (e) {
        this.message("Error al subir archivos" + e, "is-danger");
      }
    },
    eliminar(dataimg) {
      this.option = 2;
      this.oldLugar = dataimg.id;
      this.Mover();
    },
    async Mover() {
      if (this.option > 0) {
        this.estado = true;
        const respuesta = await SlidersService.cambiarLugar(
          this.option,
          this.oldLugar,
          this.newLugar
        );

        if (respuesta) {
          this.option = null;
          this.estado = false;
          this.obtenerSliders();
        } else {
          this.message("Error al modificar estado imagen", "is-danger");
        }
      }
    },

    async obtenerSliders() {
      this.Sliders = await SlidersService.obtenerSliders();

      this.rango = this.Sliders.length;

      this.location = "https://contravel.com.mx";

      console.log(this.location);
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>
<style>
#Figuras:hover {
  background: rgb(121, 50, 9);
  background: -moz-linear-gradient(
    90deg,
    rgba(121, 50, 9, 0.6) 11%,
    rgba(9, 9, 121, 0.6) 57%,
    rgba(0, 212, 255, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(121, 50, 9, 0.6) 11%,
    rgba(9, 9, 121, 0.6) 57%,
    rgba(0, 212, 255, 0.6) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(121, 50, 9, 0.6) 11%,
    rgba(9, 9, 121, 0.6) 57%,
    rgba(0, 212, 255, 0.6) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#793209",endColorstr="#00d4ff",GradientType=1);
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
#Figuras:active {
  cursor: wait;
  /* cursor: -moz-grabbing;
  cursor: -webkit-grabbing; */
}
#Figuras::after {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
