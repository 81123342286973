import HttpService from "@/services/HttpService";
const ManualesService = {


    async obtenerTipoManuales() {
        return await HttpService.post("/obtenerTipoManuales.php");
    },
    async obtenerManuales() {
        return await HttpService.post("/obtenerManuales.php");
    },

    async obtenertipo(Dato) {
        const cargaUtil = { Dato };
        return await HttpService.post("/obtenertipo.php", cargaUtil);
    },

    async cambiarEstado(id, opcion, nuevoVal) {
        return await HttpService.post("/cambiarManual.php", {
            id, opcion, nuevoVal,
        });
    },

    async NuevoFileManu(file, nombre, n_tags) {
        const formdata = new FormData();
        formdata.append("img", file);
        formdata.append("nombre", nombre);
        formdata.append("n_tags", n_tags);

        return await HttpService.formdata("/saveManu.php", formdata);
    },

    async Eliminar(id) {
        return await HttpService.post("/eliminarManual.php", {
            id
        });
    },
};
export default ManualesService;