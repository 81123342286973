<template>
  <div class="box">
    <h1 class="title">Agencias</h1>

    <b-button outlined type="is-success" @click="CrearReporte(contactos)"
      >Descargar</b-button
    >

    <div class="box">
      <h1>Resultados encontrados: {{ contactos.length }}</h1>
      <b-table
        :data="contactos"
        :paginated="true"
        :per-page="10"
        :bordered="true"
        :striped="true"
        :narrowed="true"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
      >
        <template>
          <b-table-column
            field="id_iris"
            label="ID IRIS"
            sortable
            searchable
            v-slot="props"
          >
            {{ props.row.id_iris }}
          </b-table-column>
          <b-table-column
            field="Nombre_RazonSo"
            label="Nombre/R.Social"
            sortable
            searchable
            v-slot="props"
          >
            {{ props.row.Nombre_RazonSo }}
          </b-table-column>

          <b-table-column
            field="email"
            label="EMAIL"
            searchable
            sortable
            v-slot="props"
          >
            {{ props.row.email }}
          </b-table-column>
          <b-table-column
            field="rfc"
            label="RFC"
            searchable
            sortable
            v-slot="props"
          >
            {{ props.row.rfc }}
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import ClientesService from "@/services/ClientesService";
import Constantes from "@/Constantes";
export default {
  name: "Agencias",
  data() {
    return {
      contactos: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      nuevoVal: null,
    };
  },
  mounted() {
    this.obtenerAgencias();
  },
  methods: {
    async obtenerAgencias() {
      this.cargando = true;
      this.contactos = await ClientesService.obtenerAgencias();
      this.cargando = false;
    },
    async CrearReporte(contactos) {
      try {
        const respuesta = await ClientesService.ReporteAgencias(contactos);

        if (respuesta) {
          this.message("Archivo creado correctamente", "is-success");

          let date = new Date();
          let output =
            "Clientes " +
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear();
          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + output;
          window.open(url);
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      } catch (e) {
        this.message("Error al crear archivo: " + e, "is-danger");
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

