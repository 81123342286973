import HttpService from "@/services/HttpService";

const UsuariosService = {

    async login(usuario, palabraSecreta) {
        const cargaUtil = { usuario, palabraSecreta };
        return await HttpService.post("/login.php", cargaUtil);
    },
    async logout() {
        return await HttpService.post("/logout.php");
    },

};
export default UsuariosService;
