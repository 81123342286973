<template>
  <div class="box">
    <h1 class="title">Calendario</h1>
    <div class="columns">
      <div class="column" style="text-align: center">
        <!-- bars || dots -->

        <b-datepicker
          inline
          v-model="date"
          :events="events"
          indicators="dots"
          :unselectable-days-of-week="[0, 6]"
          @input="(option) => CapturarFecha(option)"
        >
        </b-datepicker>

        <b-tooltip label="Agregar" type="is-dark"
          ><b-button
            v-if="add == true"
            @click="agregar()"
            icon-left="plus-box"
            type="is-warning"
            size="is-large"
          />
        </b-tooltip>
      </div>
      <div class="column" v-if="items.length == 0 && add == true" style="text-align: center;">
        <b-tag icon="account-check-outline" type="is-warning"
          >No hay asignaciones para esta fecha</b-tag
        >
      </div>
      <div class="column" v-if="items.length > 0">
        <div class="rows">
          <div class="row" v-for="item in items" :key="item.idfecha">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <p class="title is-4">
                    Periodo {{ item.idfecha }} &nbsp;&nbsp;&nbsp;
                    <b-button
                      @click="editar(item)"
                      size="is-small"
                      type="is-success"
                      icon-left="pencil"
                      outlined
                    />
                    &nbsp;
                    <b-button
                      @click="eliminar(item.idfecha)"
                      size="is-small"
                      type="is-danger"
                      icon-left="delete"
                      outlined
                    />
                  </p>

                  <p class="subtitle is-5">{{ item.fetchper }}</p>
                  <p class="subtitle is-6">{{ item.fechainfo }}</p>
                  <p>
                    <b-tag :type="item.color" icon="account-check-outline">{{
                      item.asunto
                    }}</b-tag>
                  </p>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="rows">
          <div class="row">
            <div class="box">
              <div
                class="row"
                v-for="ModsDay in this.ModsDays"
                :value="ModsDay"
                :key="ModsDay.id"
              >
                &nbsp;&nbsp;&nbsp;
                <b-button
                  icon-left="card-account-details"
                  :type="ModsDay.type"
                />&nbsp;&nbsp;&nbsp;<label>{{ ModsDay.asunto }} </label
                ><br /><br />
              </div>
            </div>
          </div>
          <br />
          <div class="row" v-if="capdate == true">
            <div class="box">
              <h3 class="title is-6">{{ fechainfo }}</h3>
              <div class="columns">
                <div class="column">
                  <h1 class="title is-5">Periodo:</h1>
                  <h2 class="title is-6">{{ peridoNom.fecha_periodo }}</h2>
                  <b-select
                    v-model="peridoNom"
                    ref="fieldPeriodo"
                    size="is-medium"
                    required
                    rounded
                  >
                    <option disabled value="">Selecciona un periodo</option>
                    <option
                      v-for="periodo_option in this.Periodos"
                      :value="periodo_option"
                      :key="periodo_option.numero_periodo"
                    >
                      &nbsp;&nbsp;&nbsp;{{ periodo_option.numero_periodo }}
                    </option>
                  </b-select>
                </div>
                <div class="column">
                  <h1 class="title is-5">
                    Asignación &nbsp;&nbsp;&nbsp;
                    <b-tooltip label="Guardar" type="is-dark">
                      <b-button
                        v-if="Asig != '' && peridoNom != ''"
                        @click="save(Asig, peridoNom)"
                        :type="Asig.type"
                        icon-left="content-save"
                        size="is-medium"
                    /></b-tooltip>
                  </h1>
                  <b-select
                    v-model="Asig"
                    ref="fieldAsig"
                    size="is-medium"
                    required
                    rounded
                  >
                    <option disabled value="">Selecciona una Asignación</option>
                    <option
                      v-for="Asigna_option in this.ModsDays"
                      :value="Asigna_option"
                      :key="Asigna_option.id"
                    >
                      {{ Asigna_option.asunto }}
                    </option>
                  </b-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FechasPagoService from "@/services/FechasPagoService";

export default {
  data() {
    return {
      cargando: false,
      fechas: null,
      date: new Date(),
      events: [],
      bars: true,
      fechainfo: null,
      meses: null,
      Periodo: null,
      asunto: null,
      fetchper: null,
      periodo: null,
      idfecha: null,
      fechacompair: null,
      color: null,
      items: [],
      peridoNom: "",
      Asig: "",
      Periodos: "",
      ModsDays: [],
      capdate: false,
      resp: null,
      add: false,
    };
  },
  mounted() {
    this.obtenerDias();
    this.obtenerMeses();
    this.calcularPeriodo();
    this.obtenerPeriodos();
    this.obtenerModsDay();
  },
  methods: {
    async eliminar(id) {
      this.cargando = true;

      const respuesta = await FechasPagoService.eliminar(id);
      if (respuesta) {
        this.message("La tarea ha sido eliminada", "is-success");
        this.cargando = false;
        location.reload();
      } else {
        this.message("Error cambiando estado", "is-danger");
        this.cargando = false;
      }
    },
    editar(datos) {
      this.idfechaMod = datos.idfecha;
      this.editarDato = true;
      this.capdate = true;

      // asunto: "Reporte de Ventas"
      // color: "is-success"
      // fetchper: "Del 1 al 8 de Abril, 2022"
      // idfecha: 7
      // periodo: 13
    },

    agregar() {
      this.capdate = true;
    },

    async save(Asig, peridoNom) {
      try {
        if (this.editarDato) {
          this.resp = await FechasPagoService.UpdateFech(
            this.fechacompair,
            Asig.asunto,
            peridoNom.numero_periodo,
            this.idfechaMod
          );
        } else {
          this.resp = await FechasPagoService.AgregarFecha(
            this.fechacompair,
            Asig.asunto,
            peridoNom.numero_periodo
          );
        }
        if (this.resp) {
          this.message("Se realizo el cambio correctamente", "is-success");
          location.reload();
        } else {
          this.message("Error al realizar el cambio ", "is-danger");
        }
      } catch (e) {
        console.log(e);
      }
    },
    async obtenerDias() {
      this.cargando = true;
      this.fechas = await FechasPagoService.obtenerFechas();
      this.cargando = false;

      if (this.fechas) {
        for (const fecha of this.fechas) {
          const prueba1 = {
            date: new Date(fecha.date + "T00:00:00"),
            type: fecha.type,
          };
          this.events.push(prueba1);
        }
      }
    },

    async obtenerModsDay() {
      this.cargando = true;
      this.ModsDays = await FechasPagoService.obtenerModsDay();
      this.cargando = false;
    },
    async obtenerMeses() {
      this.cargando = true;
      this.meses = await FechasPagoService.obtenerMeses();
      this.cargando = false;
    },
    async obtenerPeriodos() {
      this.cargando = true;
      this.Periodos = await FechasPagoService.obtenerPeriodos();
      this.cargando = false;
    },
    async calcularPeriodo() {
      this.cargando = true;
      this.Periodo = await FechasPagoService.calcularPeriodo();
      this.cargando = false;
    },
    CapturarFecha(opcion) {
      this.add = true;
      let dia = ("0" + opcion.getDate()).slice(-2);
      let anio = opcion.getFullYear();
      let mesNo = opcion.getMonth();
      let mesfor = ("0" + (opcion.getMonth() + 1)).slice(-2);
      let mes = this.meses[mesNo].mes;
      this.fechainfo = dia + "-" + mes + "-" + anio; //TDD:MM:YYYY
      this.fechacompair = anio + "-" + mesfor + "-" + dia; //T00:00:00

      this.traerdatosFecha();
    },

    traerdatosFecha() {
      this.items = [];
      for (const fecha of this.fechas) {
        if (fecha.date === this.fechacompair) {
          const atri = {
            asunto: fecha.asunto,
            fetchper: fecha.fecha_periodo,
            periodo: fecha.periodo,
            idfecha: fecha.id,
            color: fecha.type,
          };
          this.items.push(atri);
        }
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>
<style scoped>
.datepicker {
  font-size: 0.7rem;
}
.pagination {
  font-size: 0.7rem;
}
.control {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  position: relative;
  text-align: center;
}
</style>