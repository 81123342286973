<template>
  <div class="box">
    <b-loading is-full-page v-model="isLoading">
      <div class="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </b-loading>
    <h1 class="title">Paquetes</h1>
    <div class="steps">
      <b-steps
        :animated="true"
        :rounded="true"
        :label-position="labelPosition"
        position="is-right"
      >
        <template #navigation="{ previous, next }">
          <div class="rows">
            <div class="row">
              <b-button
                type="is-danger"
                icon-left="chevron-left"
                size="is-small"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
                v-if="Eleccion != 'Elección'"
              />
              <b-button
                type="is-success"
                size="is-small"
                icon-right="chevron-right"
                :disabled="next.disabled"
                @click.prevent="next.action"
                v-if="Eleccion != 'Elección'"
              />
            </div>
            <div class="row">
              <b-step-item
                step="1"
                label="Selecciona la  Región"
                :clickable="false"
                type="is-info"
              >
                <div class="map">
                  <img
                    id="Image-Maps-Com-image-maps-2022-10-18-144733"
                    src="@/assets/Mapa.png"
                    border="0"
                    usemap="#image-maps-2022-10-18-144733"
                    width="600"
                    height="306"
                    orgWidth="600"
                    orgHeight="306"
                    alt="No se encontro Imagen"
                  />
                  <map
                    name="image-maps-2022-10-18-144733"
                    id="ImageMapsCom-image-maps-2022-10-18-144733"
                  >
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Africa')"
                      title="África"
                      shape="poly"
                      coords="320,122,317,124,300,124,299,103,296,100,291,102,289,105,282,102,274,100,274,92,267,91,258,91,250,96,245,93,243,96,238,100,236,107,229,112,226,115,222,123,220,124,224,131,223,134,221,137,219,141,224,148,233,159,240,161,250,161,260,158,267,162,271,172,276,181,277,191,277,198,275,205,277,214,282,226,287,239,289,246,299,244,308,241,314,232,317,227,320,223,319,215,320,212,323,211,330,206,330,200,333,198,341,203,336,207,335,214,333,220,340,225,343,220,343,216,346,212,347,209,348,204,346,198,342,199,338,198,330,196,328,185,330,179,335,173,340,168,343,164,347,156,349,150,350,145,341,148,337,148,333,139,327,139"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Asia')"
                      title="Asia"
                      shape="poly"
                      coords="522,177,520,188,517,191,515,189,513,182,510,186,504,189,490,193,478,191,458,188,449,181,441,168,433,158,439,155,439,150,440,146,440,140,437,137,434,135,430,138,426,126,432,111,429,108,422,110,415,112,398,104,396,99,394,95,389,92,382,92,380,88,377,90,372,91,367,95,363,92,357,90,351,91,350,86,346,83,348,80,344,76,345,73,343,70,336,73,339,81,328,78,322,78,319,73,320,69,321,66,318,62,309,57,304,51,300,47,304,38,303,34,300,29,299,26,312,28,314,33,306,31,311,34,320,28,330,28,345,26,354,28,351,22,351,19,357,19,367,18,378,16,384,14,386,12,405,14,401,20,423,19,430,18,436,25,439,21,441,20,449,21,462,22,473,22,482,25,491,27,491,26,499,25,511,28,525,30,530,37,528,35,521,35,517,34,519,41,518,42,515,44,509,43,507,44,512,52,513,61,509,59,504,55,500,51,499,49,500,45,501,42,500,39,497,42,494,41,492,45,483,45,475,46,474,54,481,55,490,59,496,67,500,76,507,82,504,86,506,94,501,98,494,105,490,107,487,101,492,94,499,87,495,81,493,72,490,70,489,79,486,81,485,82,483,84,487,92,484,98,480,97,477,94,474,92,472,94,469,96,477,107,474,113,482,121,478,124,480,132,484,141,491,148,495,155,492,157,490,160,485,160,480,160,478,165,490,167,499,165,509,172"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Australia')"
                      title="Australia"
                      shape="poly"
                      coords="524,192,529,203,535,222,536,234,522,249,519,253,516,262,508,265,503,260,503,254,502,246,499,244,493,239,487,242,474,246,469,244,465,248,461,244,464,238,464,226,468,217,485,206,488,201,498,202,501,198,503,194,511,197,517,205"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Canada')"
                      title="Canadá"
                      shape="poly"
                      coords="112,82,118,80,122,77,127,75,134,74,137,70,144,76,142,80,148,75,150,74,148,71,146,69,148,67,149,65,156,64,155,70,165,72,167,68,166,65,164,64,167,60,165,57,164,53,161,49,160,46,158,44,152,46,152,43,157,42,158,38,164,36,168,33,170,32,168,29,166,27,165,24,161,21,160,19,157,17,157,15,165,13,175,9,188,6,180,5,175,4,171,4,162,6,159,6,152,7,147,9,143,12,140,11,137,9,134,10,132,14,130,14,126,12,125,11,121,11,118,15,116,15,114,13,107,14,104,15,103,18,101,17,95,19,91,21,90,27,88,27,85,24,80,25,77,26,73,27,70,27,66,26,46,42,50,48,47,55,49,64,60,67,75,66,95,65,103,71,108,67,115,72,113,79,114,76,114,72"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Caribe')"
                      title="Centro América Y Caribe"
                      shape="poly"
                      coords="102,170,111,172,120,178,120,174,120,168,124,165,131,169,132,164,134,161,141,166,149,166,155,164,155,161,151,158,147,157,142,154,139,151,139,145,141,138,135,134,132,131,121,128,106,122,102,120,99,121,96,123,102,127,113,128,120,134,130,134,137,142,134,148,126,148,122,144,118,144,116,145,112,147,109,150,107,153,105,150,103,150,99,151,96,146,96,142,98,139,98,137,94,138,87,139,88,136,90,131,87,132,85,133,84,134,81,138,90,144,94,152,101,157,106,156,104,165"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('EUA')"
                      title="Estados Unidos"
                      shape="poly"
                      coords="65,27,56,25,49,24,44,24,36,26,30,27,27,29,20,33,16,35,13,40,8,41,6,44,12,48,4,51,4,58,10,53,23,45,25,51,31,47,35,43,43,46,46,56,47,63,44,65,46,71,40,79,37,87,37,98,45,101,52,106,57,104,61,102,63,109,66,108,74,117,78,110,83,108,90,110,90,107,95,106,101,108,102,116,108,116,108,111,107,107,108,103,117,97,120,93,122,90,126,85,130,84,133,82,134,80,138,78,141,76,140,71,138,69,135,74,131,75,126,75,123,77,120,77,117,81,114,82,114,76,111,71,104,71,101,69,98,67,53,68,46,62,48,55,52,53,52,50,50,45,44,42"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Europa')"
                      title="Europa"
                      shape="poly"
                      coords="236,32,232,33,228,33,225,31,224,33,223,35,231,40,247,53,240,57,238,61,246,62,249,59,247,68,253,74,252,77,241,79,239,79,237,88,244,94,250,92,254,89,256,86,260,83,261,79,267,78,269,86,273,83,273,79,282,86,280,89,275,90,284,94,287,85,278,78,276,76,279,73,287,80,292,91,297,96,297,91,296,88,296,84,304,85,305,83,303,79,305,73,310,72,312,77,316,76,319,74,321,70,322,65,317,65,312,60,307,61,307,57,304,54,302,51,301,44,302,41,303,39,300,34,299,28,301,25,296,23,289,24,286,25,284,25,272,35,264,40,266,48,273,47,273,52,272,54,272,50,266,53,264,57,262,60,260,61,258,59,255,55,253,53,254,50,252,48,251,47,249,47,248,49,246,49,235,39"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Groenlandia')"
                      title="Groenlandia"
                      shape="poly"
                      coords="191,44,198,37,211,32,229,29,227,23,228,20,233,15,240,12,244,9,246,6,238,3,224,4,213,6,196,5,189,5,186,8,183,10,176,11,170,9,179,16,185,23,183,28,183,35,185,41"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('MedioOri')"
                      title="Medio Oriente"
                      shape="poly"
                      coords="299,102,300,124,316,125,320,122,318,114,314,109,323,116,334,131,334,144,350,139,353,134,362,130,365,125,360,119,358,117,358,115,368,117,376,118,384,124,390,129,399,154,406,159,409,157,409,153,405,150,405,146,404,138,416,126,423,123,428,121,430,113,435,111,429,108,424,111,419,111,417,111,414,112,411,109,405,105,398,102,396,97,393,93,384,92,382,90,378,92,373,91,367,96,362,92,357,90,352,91,351,92,342,92,343,89,342,87,342,85,342,81,339,84,336,81,327,78,326,82,319,82,318,81,314,81,310,83,305,84,302,84,305,91,314,92,319,100,317,103,309,105"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Mexico')"
                      title="México"
                      shape="poly"
                      coords="89,132,90,129,91,126,92,124,88,125,85,125,84,128,82,131,80,132,76,131,73,128,73,122,73,119,74,116,72,114,70,109,68,107,66,109,63,106,61,103,59,102,55,103,51,104,47,101,44,100,42,100,42,108,45,115,49,122,50,120,48,113,47,106,50,112,54,119,57,125,56,128,61,133,68,136,74,138,79,139,82,137"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Oceania')"
                      title="Oceanía"
                      shape="poly"
                      coords="522,176,522,191,527,188,535,192,565,246,563,252,554,259,537,268,544,273,550,268,558,264,566,259,573,254,539,190,535,186,541,185,551,186,566,203,568,212,559,214,565,221,590,212,595,207,594,190,591,177,586,165,584,155,579,150,568,150,574,159,581,168,586,177,591,190,591,200,588,204,582,204,578,209,570,200,564,193,559,186,551,182,548,181,545,178,541,174,539,178,536,182,531,183"
                    />
                    <area
                      alt="No se encontro Imagen"
                      @click.prevent="next.action"
                      @click="obtenerDestinos('Sudamerica')"
                      title="Sudamérica"
                      shape="poly"
                      coords="153,289,161,284,162,279,157,278,152,281,146,284,143,280,145,270,148,261,153,252,161,243,166,229,165,223,174,220,181,212,182,203,185,195,190,188,184,180,175,175,168,173,160,169,157,166,156,161,152,164,147,167,143,167,139,166,139,160,134,160,128,163,128,171,121,169,121,180,115,176,108,172,103,167,99,172,98,180,100,189,108,200,117,207,123,215,121,224,122,236,122,245,124,259,132,272,136,285"
                    />
                  </map>
                </div>
                <div class="buttons">
                  <a
                    style="width: 21vw"
                    v-for="(itemRegion, index) in itemsRegion"
                    :key="itemRegion.title"
                    :value="(index = elementos)"
                    @click.prevent="next.action"
                    @click="obtenerDestinos(itemRegion.title)"
                    >{{ itemRegion.name }}
                    <figure class="figure">
                      <img
                        alt="No se encontro Imagen"
                        :src="location + itemRegion.image"
                        id="icon"
                        class="navbar-item"
                        style="height: 9vh"
                      />
                    </figure>
                  </a>
                </div>
              </b-step-item>
              <b-step-item
                step="2"
                label="Acción a realizar"
                :clickable="false"
                type="is-danger"
              >
                <h1 class="title is-4 has-text-centered">
                  ¿Que vas a realizar?
                </h1>

                <b-button
                  type="is-info is-light is-outlined"
                  @click.prevent="next.action"
                  @click="Accion('Agregar')"
                >
                  <b-icon icon="plus" />&nbsp;&nbsp; Agregar
                </b-button>
                <br /><br />

                <b-button
                  type="is-info is-light is-outlined"
                  @click.prevent="next.action"
                  @click="Accion('Editar')"
                >
                  <b-icon icon="pencil" />&nbsp;&nbsp; Editar
                </b-button>
              </b-step-item>
              <b-step-item
                step="3"
                :label="Eleccion"
                :clickable="false"
                type="is-warning"
              >
                <div v-if="Eleccion == 'Agregar'">
                  <br />
                  <div class="columns">
                    <div class="column">
                      <b-field label="Titulo" label-position="on-border">
                        <b-input
                          expanded
                          v-model="titulo"
                          required
                          ref="fieldTitulo"
                          placeholder="Escribe el titulo"
                          native-value="titulo"
                        />
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Servicio" label-position="on-border">
                        <b-autocomplete
                          expanded
                          v-model="nameTipo"
                          required
                          ref="fieldServicio"
                          placeholder="Tipo de Servicio"
                          native-value="typs"
                          group-field="typs"
                          group-options="n_tipo"
                          open-on-focus
                          :clearable="true"
                          :data="filtrarObjeto"
                          @select="(option) => (selected = option)"
                          @select-header="AddNewServicio"
                          selectable-header
                        >
                          <template #header>
                            <a><span> Agregar Nuevo... </span></a>
                          </template>
                          <template #empty
                            >No results for {{ nameTipo }}</template
                          >
                        </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Destino" label-position="on-border">
                        <b-autocomplete
                          expanded
                          v-model="Destino"
                          required
                          ref="fieldDestino"
                          placeholder="Escribe el Destino"
                          native-value="Destino"
                          group-field="Destinos"
                          group-options="n_destino"
                          open-on-focus
                          :clearable="true"
                          :data="filtrarObjeto2"
                          @select-header="AddNewDestino"
                          @select="(option2) => (selected2 = option2)"
                          selectable-header
                        >
                          <template #header>
                            <a><span> Agregar Nuevo... </span></a>
                          </template>
                          <template #empty
                            >No results for {{ Destino }}</template
                          >
                        </b-autocomplete>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field
                        label="Salidas"
                        label-position="on-border"
                        v-if="salidasSelect == null || salidasSelect == ''"
                      >
                        <b-select
                          expanded
                          placeholder="Selecciona una opción"
                          v-model="salidasSelect"
                          required
                        >
                          <option value="Rango">RANGO DE FECHAS</option>
                          <option value="Multifecha">
                            UNA O VARIAS FECHAS
                          </option>
                        </b-select>
                      </b-field>

                      <div v-if="salidasSelect == 'Rango'">
                        <b-field label="Salidas" label-position="on-border">
                          <b-datepicker
                            expanded
                            placeholder="Click para seleccionar..."
                            v-model="dataFecha"
                            :min-date="MinFecha"
                            @input="formatearExpiracion"
                            range
                            icon-right-clickable
                            icon="calendar-today"
                            trap-focus
                            required
                            ref="fieldDataFecha"
                          >
                          </b-datepicker>
                          <b-button
                            v-show="dataFecha.length > 0"
                            @click="LimpiarDataFecha()"
                            icon-left="delete"
                            type="is-danger"
                          />
                        </b-field>
                      </div>
                      <div v-if="salidasSelect == 'Multifecha'">
                        <b-field label="Salidas" label-position="on-border">
                          <b-datepicker
                            expanded
                            placeholder="Click para seleccionar..."
                            v-model="dataFecha"
                            @input="formatearExpiracion"
                            :min-date="MinFecha"
                            multiple
                            icon-right-clickable
                            icon="calendar-today"
                            trap-focus
                            required
                            ref="fieldDataFecha"
                          >
                          </b-datepicker>
                          <b-button
                            v-show="dataFecha.length > 0"
                            @click="LimpiarDataFecha()"
                            icon-left="delete"
                            type="is-danger"
                          />
                        </b-field>
                      </div>
                    </div>
                    <div class="column">
                      <b-field
                        label="Fecha de expiración"
                        label-position="on-border"
                      >
                        <b-datepicker
                          required
                          ref="fieldFechaEx"
                          expanded
                          :show-week-number="true"
                          :locale="locale"
                          placeholder="Click to select..."
                          icon="calendar-multiselect"
                          :icon-right="fchexp ? 'close-circle' : ''"
                          icon-right-clickable
                          @icon-right-click="clearDate1"
                          trap-focus
                          :value="fchexp"
                          native-value="fchexp"
                          v-model="fchexp"
                          :min-date="MinFecha"
                        >
                        </b-datepicker>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field
                        label="Duración (Días)"
                        label-position="on-border"
                      >
                        <b-numberinput
                          :controls="false"
                          required
                          expanded
                          v-model="duracion"
                          ref="fieldDuracion"
                          native-value="duracion"
                          placeholder="Escribe la duración"
                          :min="1"
                        ></b-numberinput>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <b-checkbox v-model="isOfert" type="is-warning">
                          Oferta
                        </b-checkbox>
                      </b-field>
                    </div>
                    <div class="column">
                      <div class="columns" style="display: block">
                        <b-field label="Incluye" style="display: inline-grid">
                          <div class="rows">
                            <div class="row">
                              <b-field>
                                <div class="checkbox-wrapper-16">
                                  <label class="checkbox-wrapper">
                                    <input
                                      class="checkbox-input"
                                      type="checkbox"
                                      v-model="hotel"
                                    />
                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <b-icon icon="domain"> </b-icon>
                                      </span>
                                      <span class="checkbox-label"
                                        >Hospedaje</span
                                      >
                                    </span>
                                  </label>
                                </div>
                                <div class="checkbox-wrapper-16">
                                  <label class="checkbox-wrapper">
                                    <input
                                      class="checkbox-input"
                                      type="checkbox"
                                      v-model="avion"
                                    />
                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <b-icon icon="airplane"></b-icon>
                                      </span>
                                      <span class="checkbox-label"> Vuelo</span>
                                    </span>
                                  </label>
                                </div>
                                <div class="checkbox-wrapper-16">
                                  <label class="checkbox-wrapper">
                                    <input
                                      class="checkbox-input"
                                      type="checkbox"
                                      v-model="traslado"
                                    />
                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <b-icon icon="bus"></b-icon>
                                      </span>
                                      <span class="checkbox-label"
                                        >Traslado</span
                                      >
                                    </span>
                                  </label>
                                </div></b-field
                              >
                            </div>
                            <div class="row">
                              <b-field>
                                <div class="checkbox-wrapper-16">
                                  <label class="checkbox-wrapper">
                                    <input
                                      class="checkbox-input"
                                      type="checkbox"
                                      v-model="crucero"
                                    />
                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <b-icon icon="ferry"></b-icon>
                                      </span>
                                      <span class="checkbox-label"
                                        >Crucero</span
                                      >
                                    </span>
                                  </label>
                                </div>
                                <div class="checkbox-wrapper-16">
                                  <label class="checkbox-wrapper">
                                    <input
                                      class="checkbox-input"
                                      type="checkbox"
                                      v-model="train"
                                    />

                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <b-icon icon="train"></b-icon>
                                      </span>
                                      <span class="checkbox-label">Tren</span>
                                    </span>
                                  </label>
                                </div>
                                <div class="checkbox-wrapper-16">
                                  <label class="checkbox-wrapper">
                                    <input
                                      class="checkbox-input"
                                      type="checkbox"
                                      v-model="alimentos"
                                    />

                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <b-icon icon="food-variant"></b-icon>
                                      </span>
                                      <span class="checkbox-label"
                                        >Alimentos</span
                                      >
                                    </span>
                                  </label>
                                </div></b-field
                              >
                            </div>
                          </div>
                        </b-field>
                      </div>
                    </div>

                    <div class="column" style="display: block">
                      <div class="rows">
                        <div class="row">
                          <b-field
                            label="Precio $"
                            label-position="on-border"
                            style="width: 100%"
                          >
                            <b-numberinput
                              v-model="precio"
                              required
                              ref="fieldPrecio"
                              placeholder="Escribe el precio"
                              native-value="precio"
                              :controls="false"
                              expanded
                            >
                            </b-numberinput>
                          </b-field>
                        </div>
                        <br />
                        <div class="row">
                          <b-field
                            label="Currency"
                            label-position="on-border"
                            style="width: 100%"
                          >
                            <b-select
                              v-model="currency"
                              placeholder="Tipo de Cambio"
                              expanded
                            >
                              <option value="MXN">MXN</option>
                              <option value="USD">USD</option>
                              <option value="CAD">CAD</option>
                              <option value="EUR">EUR</option>
                            </b-select>
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column" v-if="nameTipo == 'Circuito'">
                      <b-field
                        class="file is-light"
                        :class="{ 'has-name': !!itinerario }"
                        label-position="on-border"
                        label="Itinerario"
                      >
                        <b-upload
                          v-model="itinerario"
                          @input="changeDoc($event)"
                          required
                          ref="fieldItinerario"
                          class="file-label"
                          accept=".doc , .docx"
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label">{{
                              itinerario.name ||
                              "Click para subir (Unicamente word)"
                            }}</span>
                          </span>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="column" v-if="nameTipo == 'Circuito'">
                      <b-field
                        class="file is-light"
                        :class="{ 'has-name': !!pdfFile }"
                        label-position="on-border"
                        label="PDF"
                      >
                        <b-upload
                          v-model="pdfFile"
                          @input="changePDF($event)"
                          required
                          ref="fieldPDF"
                          class="file-label"
                          accept=".pdf"
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label">{{
                              pdfFile.name ||
                              "Click para subir (Unicamente pdf)"
                            }}</span>
                          </span>
                        </b-upload>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field
                        class="file is-warning"
                        :class="{ 'has-name': !!ImgLimpia }"
                        label-position="on-border"
                        label="Imagen Limpia"
                      >
                        <b-upload
                          accept="image/jpeg,image/jpg"
                          required
                          ref="fieldImgLimpia"
                          @input="cambiarImagen($event)"
                          v-model="ImgLimpia"
                          class="file-label"
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label"></span>
                          </span>
                          <span class="file-name" v-if="ImgLimpia">
                            {{ ImgLimpia.name }}
                          </span>
                        </b-upload>
                      </b-field>

                      <div class="column" v-if="visorImgLimp">
                        <b-field>
                          <figure class="figure">
                            <img
                              alt="No se encontro Imagen"
                              :src="visorImgLimp"
                              class="image height"
                              style="width: 28vw"
                            />
                          </figure>
                          <b-button
                            type="is-danger"
                            outlined
                            @click="eliminarImg()"
                            ><b-icon icon="delete-forever"> </b-icon
                          ></b-button>
                        </b-field>
                      </div>
                    </div>
                    <div class="column">
                      <b-field
                        class="file is-info"
                        :class="{ 'has-name': !!ImgPromo }"
                        label-position="on-border"
                        label="Imagen Promocional"
                      >
                        <b-upload
                          accept="image/gif,image/jpeg,image/jpg,image/png"
                          required
                          ref="fieldImgPromocional"
                          @input="changeImage($event)"
                          v-model="ImgPromo"
                          class="file-label"
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label"></span>
                          </span>
                          <span class="file-name" v-if="ImgPromo">
                            {{ ImgPromo.name }}
                          </span>
                        </b-upload>
                      </b-field>
                      <div class="column" v-if="visorImgPromo">
                        <b-field>
                          <figure class="figure">
                            <img
                              alt="No se encontro Imagen"
                              :src="visorImgPromo"
                              class="image height"
                              style="width: 28vw"
                            />
                          </figure>
                          <b-button
                            type="is-danger"
                            outlined
                            @click="eliminarview()"
                            ><b-icon icon="delete-forever"> </b-icon
                          ></b-button>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="Eleccion == 'Editar'">
                  <h1 class="title is-4 has-text-centered">Editar</h1>

                  <div class="columns">
                    <div class="column">
                      <b-table
                        :data="isEmpty ? [] : dataPaquetes"
                        :paginated="true"
                        :per-page="10"
                        :bordered="true"
                        :striped="true"
                        :narrowed="true"
                        :hoverable="true"
                        :mobile-cards="true"
                        detailed
                        detail-key="id"
                        :show-detail-icon="showDetailIcon"
                      >
                        <template>
                          <b-table-column
                            field="fecha_expi"
                            label="Fecha de expiración"
                            searchable
                            sortable
                            v-slot="props"
                          >
                            <template v-if="showDetailIcon">
                              {{ props.row.fecha_expi }}
                            </template>
                            <template v-else>
                              <a @click="props.toggleDetails(props.row)">
                                {{ props.row.fecha_expi }}
                              </a>
                            </template>
                          </b-table-column>

                          <b-table-column
                            field="tipo"
                            label="Tipo"
                            sortable
                            searchable
                            v-slot="props"
                          >
                            {{ props.row.tipo }}
                          </b-table-column>
                          <b-table-column
                            field="titulo"
                            label="Titulo"
                            sortable
                            searchable
                            v-slot="props"
                          >
                            {{ props.row.titulo }}
                          </b-table-column>
                          <b-table-column
                            field="destino"
                            label="Destino"
                            searchable
                            sortable
                            v-slot="props"
                          >
                            {{ props.row.destino }}
                          </b-table-column>

                          <b-table-column
                            field="status"
                            label="Publicado"
                            sortable
                            v-slot="props"
                            style="text-align: center"
                          >
                            <b-checkbox
                              :value="props.row.status"
                              @input="
                                CambiarValor(props.row.status, props.row.id, 5)
                              "
                              native-value="status"
                            >
                            </b-checkbox>
                          </b-table-column>
                          <b-table-column
                            field="oferta"
                            label="Oferta"
                            sortable
                            v-slot="props"
                            style="text-align: center"
                          >
                            <b-checkbox
                              :value="props.row.oferta"
                              @input="
                                CambiarValor(props.row.oferta, props.row.id, 19)
                              "
                              native-value="oferta"
                            >
                            </b-checkbox>
                          </b-table-column>
                          <b-table-column
                            field="multimedia"
                            label="Multimedia"
                            v-slot="props"
                          >
                            <a
                              class="button is-light"
                              :href="location + props.row.multimedia"
                              target="_blank"
                            >
                              <b-icon icon="folder-eye" type="is-info"></b-icon>
                            </a>
                          </b-table-column>
                          <b-table-column
                            field="portada"
                            label="Portada"
                            v-slot="props"
                          >
                            <a
                              class="button is-light"
                              :href="location + props.row.imagen"
                              target="_blank"
                            >
                              <b-icon
                                icon="file-eye"
                                type="is-success"
                              ></b-icon>
                            </a>
                          </b-table-column>
                          <b-table-column
                            field="promocional"
                            label="Promocional"
                            v-slot="props"
                          >
                            <a
                              class="button is-light"
                              :href="location + props.row.detalles"
                              target="_blank"
                            >
                              <b-icon
                                icon="file-eye"
                                type="is-warning"
                              ></b-icon>
                            </a>
                          </b-table-column>

                          <b-table-column
                            field="Eliminar"
                            label="Eliminar"
                            sortable
                            v-slot="props"
                          >
                            <a
                              class="button is-light"
                              @click="cambiarEstado(props.row.id, 6)"
                              target="_blank"
                            >
                              <b-icon
                                icon="delete-empty"
                                type="is-danger"
                              ></b-icon>
                            </a>
                          </b-table-column>
                        </template>
                        <template #detail="props">
                          <div class="columns">
                            <div class="column">
                              <b-field
                                label="Titulo"
                                label-position="on-border"
                              >
                                <b-input
                                  expanded
                                  v-model="props.row.titulo"
                                  required
                                  ref="fieldTitulo"
                                  placeholder="Escribe el titulo"
                                  native-value="titulo"
                                  @input="
                                    Textoeditable(
                                      props.row.titulo,
                                      props.row.id,
                                      2
                                    )
                                  "
                                  :value="props.row.titulo"
                                >
                                </b-input>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field
                                label="Servicio"
                                label-position="on-border"
                              >
                                <b-autocomplete
                                  expanded
                                  v-model="props.row.tipo"
                                  required
                                  ref="fieldServicio"
                                  placeholder="Tipo de Servicio"
                                  native-value="typs"
                                  group-field="typs"
                                  group-options="n_tipo"
                                  open-on-focus
                                  :clearable="true"
                                  :data="filtrarObjeto"
                                  :value="props.row.tipo"
                                  @input="
                                    Textoeditable(
                                      props.row.tipo,
                                      props.row.id,
                                      1
                                    )
                                  "
                                  @select-header="AddNewServicio"
                                  selectable-header
                                >
                                  <template #header>
                                    <a><span> Agregar Nuevo... </span></a>
                                  </template>
                                  <template #empty
                                    >No results for {{ nameTipo }}</template
                                  >
                                </b-autocomplete>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field
                                label="Destino"
                                label-position="on-border"
                              >
                                <b-autocomplete
                                  expanded
                                  v-model="props.row.destino"
                                  required
                                  ref="fieldDestino"
                                  placeholder="Escribe el Destino"
                                  native-value="Destino"
                                  group-field="Destinos"
                                  group-options="n_destino"
                                  open-on-focus
                                  :clearable="true"
                                  :data="filtrarObjeto2"
                                  @select-header="AddNewDestino"
                                  selectable-header
                                  @input="
                                    Textodestino(
                                      props.row.destino,
                                      props.row.id,
                                      3
                                    )
                                  "
                                  :value="props.row.destino"
                                >
                                  <template #header>
                                    <a><span> Agregar Nuevo... </span></a>
                                  </template>
                                  <template #empty
                                    >No results for {{ Destino }}</template
                                  >
                                </b-autocomplete>
                              </b-field>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column">
                              <b-field
                                label="Salidas"
                                label-position="on-border"
                              >
                                <b-taginput
                                  ref="fieldnamesalidas"
                                  placeholder="Escribe la o las salidas"
                                  native-value="salidas"
                                  v-model="props.row.salidas"
                                  :value="props.row.salidas"
                                  ellipsis
                                  icon="label"
                                  aria-close-label="Borrar fecha"
                                  @input="
                                    Textoeditable(
                                      props.row.salidas,
                                      props.row.id,
                                      9
                                    )
                                  "
                                >
                                </b-taginput>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field
                                label="Fecha de expiración"
                                label-position="on-border"
                              >
                                <b-datepicker
                                  ref="fielddestino"
                                  :show-week-number="true"
                                  :locale="locale"
                                  placeholder="Click to select..."
                                  icon="calendar-multiselect"
                                  :icon-right="destino ? 'close-circle' : ''"
                                  icon-right-clickable
                                  @icon-right-click="clearDate"
                                  trap-focus
                                  required
                                  :value="destino"
                                  native-value="destino"
                                  @input="
                                    (option) =>
                                      CapturarFecha(option, props.row.id, 4)
                                  "
                                >
                                </b-datepicker>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field
                                label="Duración"
                                label-position="on-border"
                              >
                                <b-input
                                  required
                                  expanded
                                  v-model="props.row.duracion"
                                  ref="fieldDuracion"
                                  placeholder="Escribe la duración"
                                  native-value="duracion"
                                  @input="
                                    Textoeditable(
                                      props.row.duracion,
                                      props.row.id,
                                      8
                                    )
                                  "
                                  :value="props.row.duracion"
                                >
                                </b-input>
                              </b-field>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column">
                              <div class="columns">
                                <div class="column">
                                  <b-field label="Incluye">
                                    <div class="rows">
                                      <div class="row">
                                        <b-field>
                                          <b-checkbox
                                            type="is-success"
                                            @input="
                                              CambiarValor(
                                                props.row.hotel,
                                                props.row.id,
                                                7
                                              )
                                            "
                                            :value="props.row.hotel"
                                            native-value="hotel"
                                          >
                                            Hospedaje
                                          </b-checkbox>
                                          <b-checkbox
                                            type="is-warning"
                                            @input="
                                              CambiarValor(
                                                props.row.avion,
                                                props.row.id,
                                                14
                                              )
                                            "
                                            :value="props.row.avion"
                                            native-value="avion"
                                          >
                                            Vuelo
                                          </b-checkbox>
                                          <b-checkbox
                                            type="is-danger"
                                            @input="
                                              CambiarValor(
                                                props.row.traslado,
                                                props.row.id,
                                                15
                                              )
                                            "
                                            :value="props.row.traslado"
                                            native-value="traslado"
                                          >
                                            Traslado
                                          </b-checkbox>
                                        </b-field>
                                      </div>
                                      <div class="row">
                                        <b-field>
                                          <b-checkbox
                                            type="is-info"
                                            @input="
                                              CambiarValor(
                                                props.row.train,
                                                props.row.id,
                                                16
                                              )
                                            "
                                            :value="props.row.train"
                                            native-value="train"
                                          >
                                            Tren
                                          </b-checkbox>
                                          <b-checkbox
                                            type="is-primary"
                                            @input="
                                              CambiarValor(
                                                props.row.alimentos,
                                                props.row.id,
                                                17
                                              )
                                            "
                                            :value="props.row.alimentos"
                                            native-value="alimentos"
                                          >
                                            Alimentos
                                          </b-checkbox>
                                          <b-checkbox
                                            type="is-dark"
                                            @input="
                                              CambiarValor(
                                                props.row.crucero,
                                                props.row.id,
                                                18
                                              )
                                            "
                                            :value="props.row.crucero"
                                            native-value="crucero"
                                          >
                                            Crucero
                                          </b-checkbox>
                                        </b-field>
                                      </div>
                                    </div>
                                  </b-field>
                                </div>
                              </div>
                            </div>
                            <div class="column">
                              <b-field
                                label="Precio"
                                label-position="on-border"
                              >
                                <b-input
                                  v-model="props.row.precio"
                                  required
                                  ref="fieldPrecio"
                                  placeholder="Escribe el precio"
                                  native-value="precio"
                                  expanded
                                  @input="
                                    Textoeditable(
                                      props.row.precio,
                                      props.row.id,
                                      10
                                    )
                                  "
                                  :value="props.row.precio"
                                >
                                </b-input>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field
                                class="file is-light"
                                :class="{ 'has-name': !!itinerario }"
                                label-position="on-border"
                                label="Itinerario"
                              >
                                <b-upload
                                  ref="avatarInput"
                                  @input="changeDoc($event, props.row.id)"
                                  v-model="itinerario"
                                  class="file-label"
                                  required
                                  accept=".doc , .docx"
                                >
                                  <span class="file-cta">
                                    <b-icon
                                      class="file-icon"
                                      icon="upload"
                                    ></b-icon>
                                    <span class="file-label">{{
                                      itinerario.name ||
                                      "Click para subir (Unicamente word)"
                                    }}</span>
                                  </span>
                                </b-upload>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field
                                class="file is-light"
                                :class="{ 'has-name': !!pdfFile }"
                                label-position="on-border"
                                label="PDF"
                              >
                                <b-upload
                                  ref="avatarInput"
                                  @input="changePDF($event, props.row.id)"
                                  v-model="pdfFile"
                                  class="file-label"
                                  required
                                  accept=".pdf"
                                >
                                  <span class="file-cta">
                                    <b-icon
                                      class="file-icon"
                                      icon="upload"
                                    ></b-icon>
                                    <span class="file-label">{{
                                      pdfFile.name ||
                                      "Click para subir (Unicamente pdf)"
                                    }}</span>
                                  </span>
                                </b-upload>
                              </b-field>
                            </div>
                          </div>

                          <div class="columns">
                            <div class="column">
                              <b-field
                                class="file is-warning"
                                :class="{ 'has-name': !!ImgLimpia }"
                                label-position="on-border"
                                label="Imagen Limpia"
                              >
                                <b-upload
                                  accept="image/jpeg,image/jpg"
                                  required
                                  ref="fieldImgLimpia"
                                  @input="cambiarImagen($event, props.row.id)"
                                  v-model="ImgLimpia"
                                  class="file-label"
                                >
                                  <span class="file-cta">
                                    <b-icon
                                      class="file-icon"
                                      icon="upload"
                                    ></b-icon>
                                    <span class="file-label"></span>
                                  </span>
                                  <span class="file-name" v-if="ImgLimpia">
                                    {{ ImgLimpia.name }}
                                  </span>
                                </b-upload>
                              </b-field>

                              <div class="column" v-if="!visorImgLimp">
                                <b-field>
                                  <figure class="figure">
                                    <img
                                      alt="No se encontro Imagen"
                                      :src="location + props.row.imagen"
                                      class="image height"
                                      style="width: 28vw"
                                    />
                                  </figure>
                                </b-field>
                              </div>
                              <div class="column" v-if="visorImgLimp">
                                <b-field>
                                  <figure class="figure">
                                    <img
                                      alt="No se encontro Imagen"
                                      :src="visorImgLimp"
                                      class="image height"
                                      style="width: 28vw"
                                    />
                                  </figure>
                                </b-field>
                              </div>
                            </div>
                            <div class="column">
                              <b-field
                                class="file is-info"
                                :class="{ 'has-name': !!ImgPromo }"
                                label-position="on-border"
                                label="Imagen Promocional"
                              >
                                <b-upload
                                  accept="image/gif,image/jpeg,image/jpg,image/png"
                                  required
                                  ref="fieldImgPromocional"
                                  @input="changeImage($event, props.row.id)"
                                  v-model="ImgPromo"
                                  class="file-label"
                                >
                                  <span class="file-cta">
                                    <b-icon
                                      class="file-icon"
                                      icon="upload"
                                    ></b-icon>
                                    <span class="file-label"></span>
                                  </span>
                                  <span class="file-name" v-if="ImgPromo">
                                    {{ ImgPromo.name }}
                                  </span>
                                </b-upload>
                              </b-field>

                              <div class="column" v-if="!visorImgPromo">
                                <b-field>
                                  <figure class="figure">
                                    <img
                                      alt="No se encontro Imagen"
                                      :src="location + props.row.detalles"
                                      class="image height"
                                      style="width: 28vw"
                                    />
                                  </figure>
                                </b-field>
                              </div>

                              <div class="column" v-if="visorImgPromo">
                                <b-field>
                                  <figure class="figure">
                                    <img
                                      alt="No se encontro Imagen"
                                      :src="visorImgPromo"
                                      class="image height"
                                      style="width: 28vw"
                                    />
                                  </figure>
                                </b-field>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div class="has-text-centered">
                            Sin paquetes registrados aun
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>

                <div class="column" style="text-align: right">
                  <b-tooltip label="Guardar" type="is-success">
                    <b-button
                      rounded
                      v-if="Eleccion == 'Agregar'"
                      @click="AgregarPaquete()"
                      icon-left="content-save"
                      type="is-success"
                      outlined
                    />
                  </b-tooltip>
                </div>
              </b-step-item>
              <b-step-item
                :step="showSocial ? '5' : '4'"
                label="Finish"
                :clickable="false"
                type="is-success"
                disabled
              >
                <h1 class="title is-4 has-text-centered">Finish</h1>
                Lorem ipsum dolor sit amet.
              </b-step-item>
            </div>
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import PaquetesService from "@/services/PaquetesService";
import moment from "moment";

export default {
  name: "tablaPaquetes",
  data() {
    return {
      hotel: false,
      avion: false,
      traslado: false,
      crucero: false,
      train: false,
      alimentos: false,
      duracion: null,
      salidas: null,
      precio: null,
      n_tipo: null,
      ImgPromo: null,
      ImgLimpia: null,
      Destino: "",
      destino: null,
      currency: "MXN",
      MinFecha: new Date(),
      fchexp: new Date(),
      isEmpty: false,
      Paquetes: [],
      itinerario: {},
      pdfFile: {},
      nuevoVal: "",
      edit: null,
      titulo: null,
      locale: undefined, // Browser locale
      visorImgPromo: "",
      visorImgLimp: "",
      visorDoc: "",
      visorPDF: "",
      showDetailIcon: true,
      interval: 100,
      animated: "slide",
      showSocial: false,
      NomRegion: null,
      Eleccion: "Elección",
      nameTipo: "",
      selected: null,
      selectable: false,
      selected2: null,
      arrow: true,
      location: "https://contravel.com.mx",
      isLoading: false,
      elementos: 1,
      test: 0,
      labelPosition: "bottom",
      dataFecha: [],
      dataPaquetes: [],
      salidasSelect: null,
      durFormater: null,
      isOfert: false,
      itemsRegion: [
        {
          title: "Africa",
          name: "África",
          image: "/softw/contravel/paquetes/img/Regiones/Africa.png",
        },
        {
          title: "Asia",
          name: "Asia",
          image: "/softw/contravel/paquetes/img/Regiones/Asia.png",
        },
        {
          title: "Australia",
          name: "Australia",
          image: "/softw/contravel/paquetes/img/Regiones/Australia.png",
        },
        {
          title: "Canada",
          name: "Canadá",
          image: "/softw/contravel/paquetes/img/Regiones/Canada.png",
        },
        {
          title: "Caribe",
          name: "Caribe y América Central",
          image: "/softw/contravel/paquetes/img/Regiones/Caribe.png",
        },
        {
          title: "EUA",
          name: "Estados Unidos",
          image: "/softw/contravel/paquetes/img/Regiones/EUA.png",
        },
        {
          title: "Europa",
          name: "Europa",
          image: "/softw/contravel/paquetes/img/Regiones/Europa.png",
        },
        {
          title: "Groenlandia",
          name: "Groenlandia",
          image: "/softw/contravel/paquetes/img/Regiones/Groelandia.png",
        },
        {
          title: "MedioOri",
          name: "Medio Oriente",
          image: "/softw/contravel/paquetes/img/Regiones/MedioOri.png",
        },
        {
          title: "Mexico",
          name: "México",
          image: "/softw/contravel/paquetes/img/Regiones/Mexico.png",
        },
        {
          title: "Oceania",
          name: "Oceanía",
          image: "/softw/contravel/paquetes/img/Regiones/Oceania.png",
        },
        {
          title: "Sudamerica",
          name: "Sudamérica",
          image: "/softw/contravel/paquetes/img/Regiones/Sudamerica.png",
        },
      ],
      dataTipo: [
        {
          typs: "Servicios",
          n_tipo: [],
        },
      ],
      dataServicios: [
        {
          Destinos: "País/Ciudad/Localidad",
          n_destino: [],
        },
      ],
      fechaArray: [],
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.obtenerPaquetes();
  },
  computed: {
    filtrarObjeto() {
      const newData = [];
      this.dataTipo.forEach((element) => {
        const n_tipo = element.n_tipo.filter(
          (item) => item.toLowerCase().indexOf(this.nameTipo.toLowerCase()) >= 0
        );

        if (n_tipo.length) {
          newData.push({ typs: element.typs, n_tipo });
        }
      });
      return newData;
    },

    filtrarObjeto2() {
      const newData = [];
      this.dataServicios.forEach((element) => {
        const n_destino = element.n_destino.filter(
          (item) => item.toLowerCase().indexOf(this.Destino.toLowerCase()) >= 0
        );

        if (n_destino.length) {
          newData.push({ Destinos: element.Destinos, n_destino });
        }
      });
      return newData;
    },
  },
  methods: {
    formatearExpiracion() {
      if (this.salidasSelect != "Diario" || this.salidasSelect != "Otro") {
        for (const Fechaposition of this.dataFecha) {
          this.fchexp = Fechaposition;
          const fechaExp = moment(this.fchexp).subtract(2, "day").toDate();

          this.fchexp = fechaExp;
        }
      } else {
        this.fchexp = new Date();
      }
      this.MinFecha = this.fchexp;
    },
    LimpiarDataFecha() {
      this.dataFecha = [];
      this.fechaArray = [];
      this.salidasSelect = null;
      this.MinFecha = new Date();
    },
    resize() {
      if (window.innerWidth < 550 && window.innerWidth > 356) {
        this.elementos = 3;
      } else if (window.innerWidth < 356) {
        this.elementos = 2;
      } else {
        this.elementos = 4;
      }
    },
    AddNewServicio() {
      this.$buefy.dialog.prompt({
        message: `Servicio`,
        inputAttrs: {
          placeholder: "Excribe el tipo de servicio",
          maxlength: 200,
          value: this.nameTipo,
        },
        confirmText: "Agregar",
        onConfirm: (value) => {
          this.AddServicio(value);
          this.nameTipo = value;
        },
      });
    },
    AddNewDestino() {
      this.$buefy.dialog.prompt({
        message: `Pais`,
        inputAttrs: {
          placeholder: "Excribe el nombre del destino",
          maxlength: 200,
          value: this.Destino,
        },
        confirmText: "Agregar",
        onConfirm: (valor) => {
          this.AddDestino(valor);
          this.Destino = valor;
        },
      });
    },
    async AddDestino(valor) {
      const respuesta = await PaquetesService.AddDestino(valor, this.NomRegion);

      if (respuesta) {
        this.message(
          "Se agrego " + valor + " a " + "this.NomRegion",
          "is-success"
        );
      }
    },
    async AddServicio(NewTipo) {
      try {
        const respuesta = await PaquetesService.AddTipoPaq(NewTipo);

        if (respuesta) {
          this.obtenertypepaq();
        }
      } catch (e) {
        this.message("Error al realizar cambios ", "is-danger");
      }
    },
    async Accion(dato) {
      this.Eleccion = dato;
      this.Paquetes = [];
      if (dato == "Editar") {
        this.dataPaquetes = await PaquetesService.obtenerPaquetesRegion(
          this.NomRegion
        );
      }
      this.Paquetes = this.dataPaquetes;
      for (let paquete of this.Paquetes) {
        paquete.salidas = paquete.salidas.split(",");
      }
    },
    info(value) {
      this.test = value;
    },
    async AgregarPaquete() {
      const fields = [
        "fieldTitulo",
        "fieldServicio",
        "fieldDestino",
        "fieldDataFecha",
        "fieldFechaEx",
        "fieldDuracion",
        "fieldPrecio",
        "fieldImgLimpia",
        "fieldImgPromocional",
      ];

      // Check validity of input fields
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }

      // Format expiration date
      const fechaE = this.formatDate(this.fchexp);

      // Format and populate fechaArray
      this.fechaArray = this.getFormattedFechaArray();

      try {
        this.isLoading = true;

        // Call PaquetesService.NuevoPaq with appropriate parameters

        if (this.duracion == 1) {
          this.durFormater = this.duracion + " Día";
        } else {
          this.durFormater = this.duracion + " Días";
        }

        const newPrecio = this.PrecioFormater();

        const respuesta = await PaquetesService.NuevoPaq(
          this.nameTipo,
          this.titulo,
          this.Destino,
          fechaE,
          this.durFormater,
          this.fechaArray,
          this.salidasSelect,
          "$" + newPrecio + " " + this.currency,
          this.NomRegion,
          this.ImgLimpia,
          this.ImgPromo,
          this.itinerario,
          this.pdfFile,
          this.hotel,
          this.avion,
          this.traslado,
          this.crucero,
          this.train,
          this.alimentos,
          this.isOfert
        );

        this.isLoading = false;
        if (respuesta) {
          location.reload();
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    PrecioFormater() {
      const cantidad = this.precio
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return cantidad;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return year + "-" + month + "-" + day;
    },

    getFormattedFechaArray() {
      if (
        this.salidasSelect === "Rango" ||
        this.salidasSelect === "Multifecha"
      ) {
        const formattedArray = [];
        for (const fecha of this.dataFecha) {
          const year = fecha.getFullYear();
          const month = fecha.getMonth() + 1;
          const day = fecha.getDate();
          const monthNames = [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ];
          const monthName = monthNames[month - 1];
          const formattedFecha = day + "-" + monthName + "-" + year;
          formattedArray.push(formattedFecha);
        }
        return formattedArray;
      } else {
        return this.dataFecha;
      }
    },

    async changeDoc(e, id) {
      this.doc = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.doc);
      reader.onload = function (e) {
        that.visorDoc = this.result;
      };
      this.doc.valueOf().id = id;

      if (id > 0) {
        const upload_image = await PaquetesService.uploadItinerario(
          this.doc,
          id
        );
        if (upload_image) {
          this.Accion("Editar");
          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    async changePDF(e, id) {
      this.pdf = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.pdf);
      reader.onload = function (e) {
        that.visorPDF = this.result;
      };
      this.pdf.valueOf().id = id;

      if (id > 0) {
        const upload_image = await PaquetesService.uploadPDF(this.pdf, id);
        if (upload_image) {
          this.Accion("Editar");
          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    async changeImage(e, id) {
      this.file = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.visorImgPromo = this.result;
      };
      this.file.valueOf().id = id;
      if (id > 0) {
        const upload_image = await PaquetesService.uploadImage(this.file, id);
        if (upload_image) {
          this.eliminarview();
          this.Accion("Editar");
          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    async cambiarImagen(e, id) {
      this.ImgLimpia = e;
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.ImgLimpia);
      reader.onload = function (e) {
        that.visorImgLimp = this.result;
      };
      this.ImgLimpia.valueOf().id = id;
      if (id > 0) {
        const upload_image = await PaquetesService.ActualizarImage(
          this.ImgLimpia,
          id
        );
        if (upload_image) {
          this.eliminarImg();
          this.Accion("Editar");
          this.message("Los cambios fueron guardados con exito", "is-success");
        } else {
          this.message("Error al realizar cambios ", "is-danger");
        }
      }
    },
    eliminarview() {
      this.ImgPromo = null;
      this.visorImgPromo = null;
    },
    eliminarImg() {
      this.ImgLimpia = null;
      this.visorImgLimp = null;
    },
    clearDate() {
      this.destino = null;
    },
    clearDate1() {
      this.fchexp = null;
    },
    async CambiarValor(valor, id, opcion) {
      if (valor == "false") {
        this.nuevoVal = "true";
      } else {
        this.nuevoVal = "false";
      }
      this.cambiarEstado(id, opcion);
    },
    Textoeditable(texto, id, opcion) {
      if (opcion == 9) {
        let arrayDate = [];
        for (let datof of texto) {
          arrayDate.push(datof);
        }
        this.nuevoVal = arrayDate.toString();
      } else {
        this.nuevoVal = texto;
      }
      this.cambiarEstado(id, opcion);
    },
    Textodestino(texto, id, opcion) {
      this.nuevoVal = texto;
      this.cambiarEstado(id, opcion);
    },
    CapturarFecha(fecha, id, opcion) {
      let anio = fecha.getFullYear();
      let mes = fecha.getMonth() + 1;
      let dia = fecha.getDate();
      this.nuevoVal = anio + "-" + mes + "-" + dia;
      this.cambiarEstado(id, opcion);
    },
    async cambiarEstado(id, opcion) {
      try {
        if (opcion == 6) {
          this.isLoading = true;
        }

        const respuesta = await PaquetesService.cambiarEstado(
          this.nuevoVal,
          id,
          opcion
        );

        if (respuesta) {
          if (opcion == 6) {
            this.message("El comunicado ha sido eliminado", "is-success");
            this.isLoading = false;
          }
          if (opcion == 7) {
            this.message("El paquete fue actualizado con éxito", "is-success");
          }
          this.Accion("Editar");
        } else {
          this.message("Error cambiando estado", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      }
    },
    async obtenerPaquetes() {
      this.Paquetes = await PaquetesService.obtenerPaquetes();

      for (let paquete of this.Paquetes) {
        paquete.salidas = paquete.salidas.split(",");
      }
      this.obtenertypepaq();
    },
    async obtenertypepaq() {
      try {
        this.tipopaq = await PaquetesService.obtenertypepaq();
        if (this.tipopaq) {
          for (let item of this.tipopaq) {
            this.tipo = item.tipo;
            this.dataTipo[0].n_tipo.push(this.tipo);
          }
        }
      } catch (e) {
        console.log("Tienes un error" + e);
      }
    },
    async obtenerDestinos(Dato) {
      this.NomRegion = Dato;
      this.Nomdestino = await PaquetesService.obtenerDestinos(Dato);
      if (this.Nomdestino) {
        for (let destino of this.Nomdestino) {
          this.pais = destino.pais;
          this.dataServicios[0].n_destino.push(this.pais);
        }
      }
    },
    async MandarDatotipo(Dato, id, opcion) {
      if (Dato != null) {
        const respuesta = await PaquetesService.obtenertype(Dato);
        this.nuevoVal = respuesta[0].tipo;
        this.cambiarEstado(id, opcion);
      } else {
        this.nuevoVal = null;
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

<style scoped>
.control.is-loading::after,
.select.is-loading::after,
.loader,
.button.is-loading::after {
  animation: spinAround 3000ms infinite linear !important;
}
.loader {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader div {
  height: 30px;
  width: 30px;
  position: absolute;
  animation: move 4s infinite;
}

.loader div:nth-child(1) {
  background-color: #0c4173;
  box-shadow: #0c4173;
  transform: translate(-30px, -30px);
  animation-delay: -1s;
}

.loader div:nth-child(2) {
  background-color: #116ba7;
  box-shadow: #116ba7;
  transform: translate(30px, -30px);
  animation-delay: -2s;
}

.loader div:nth-child(3) {
  background-color: #f89617;
  box-shadow: #f89617;
  transform: translate(30px, 30px);
  animation-delay: -3s;
}

.loader div:nth-child(4) {
  background-color: #f28705;
  box-shadow: #f28705;
  transform: translate(-30px, 30px);
  animation-delay: -4s;
}

@keyframes move {
  0% {
    transform: translate(-30px, -30px);
  }

  25% {
    transform: translate(30px, -30px);
  }

  50% {
    transform: translate(30px, 30px);
  }

  75% {
    transform: translate(-30px, 30px);
  }

  100% {
    transform: translate(-30px, -30px);
  }
}

.steps {
  text-align: center;
}
@media (max-width: 600px) {
  .map {
    display: none;
  }
}
@media (min-width: 600px) {
  .buttons {
    display: none;
  }
}

.checkbox-wrapper-16 *,
.checkbox-wrapper-16 *:after,
.checkbox-wrapper-16 *:before {
  box-sizing: border-box;
}

.checkbox-wrapper-16 .checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: #2260ff;
}

.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-wrapper-16 .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.7rem;
  min-height: 4.7rem;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.checkbox-wrapper-16 .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;

  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.checkbox-wrapper-16 .checkbox-tile:hover {
  border-color: #2260ff;
}

.checkbox-wrapper-16 .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-wrapper-16 .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}

.checkbox-wrapper-16 .checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
</style>