import HttpService from "@/services/HttpService";
const ReportesService = {


    async obtenerReportes() {
        return await HttpService.post("/obtenerReportes.php");
    },

    async NuevoFileR(file) {
        const formdata = new FormData();
        formdata.append("img", file);
        return await HttpService.formdata("/saveR.php", formdata);
    },

    async Eliminar(id, option) {
        return await HttpService.post("/eliminarArcR.php", {
            id, option
        });
    },
    async obtenerAgencia(Dato) {
        const cargaUtil = { Dato };
        return await HttpService.post("/obtenerAgencia.php", cargaUtil);
    },
};
export default ReportesService;