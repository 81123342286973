<template>
  <div class="box">
    <h1 class="title">Reportes</h1>

    <div class="block">
      <b-button
        label="+ Nuevo"
        type="is-info"
        size="is-medium"
        @click="isOpen = !isOpen"
        :aria-expanded="isOpen"
        aria-controls="contentIdForA11y2"
      />
    </div>

    <b-collapse
      aria-id="contentIdForA11y2"
      class="panel"
      animation="slide"
      v-model="isOpen"
    >
      <template #trigger>
        <div
          class="panel-heading"
          role="button"
          aria-controls="contentIdForA11y2"
          :aria-expanded="isOpen"
        >
          <strong>Datos</strong>
        </div>
      </template>

      <div class="columns" style="text-align: center">
        <div class="column" style="text-align: center">
          <div class="rows">
            <div class="row">
              <br />
              <h3 class="title is-4">Subir archivo</h3>
              <br />
              <b-field class="file is-info" :class="{ 'has-name': !!file }">
                <b-upload
                  accept=".pdf"
                  ref="avatarInput"
                  @input="changeImage($event)"
                  v-model="file"
                  class="file-label"
                  rounded
                >
                  <b-tooltip
                    label="Subir Imagen"
                    position="is-bottom"
                    type="is-info is-light"
                  >
                  </b-tooltip>
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="file">
                    {{ file.name }}
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="row" v-if="avatar || ruta != ''">
              <b-tooltip
                label="Borrar Imagen"
                position="is-bottom"
                type="is-info is-light"
              >
                <a @click="eliminarview()"
                  ><b-icon icon="delete" type="is-danger">
                  </b-icon></a></b-tooltip
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b-tooltip
                label="Guardar Archivo"
                position="is-bottom"
                type="is-info is-light"
              >
                <a @click="AgregarComi()"
                  ><b-icon icon="content-save" type="is-success"> </b-icon></a
              ></b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <div class="columns">
      <div class="column">
        <b-table
          v-if="isOpen == false"
          style="font-size: 18px"
          :data="isEmpty ? [] : Reportes"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
        >
          <template>
            <b-table-column
              field="semana"
              label="Semana"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.semana }}
            </b-table-column>
            <b-table-column
              field="anio"
              label="Año"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.anio }}
            </b-table-column>

            <b-table-column
              field="identificador"
              label="DK agencia"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.identificador }}
            </b-table-column>

            <b-table-column
              field="comisiones"
              label="Comisiones"
              v-slot="props"
            >
              <center>
                <a
                  v-if="props.row.comi == 'true'"
                  class="button is-light"
                  :href="location + 'comisiones/C' + props.row.archivo + '.pdf'"
                  target="_blank"
                >
                  <b-icon icon="file-eye" type="is-info"></b-icon> </a
                >&nbsp;&nbsp;
                <a
                  class="button is-light"
                  v-if="props.row.comi == 'true'"
                  @click="Eliminar(props.row.archivo, 1)"
                  target="_blank"
                >
                  <b-icon icon="delete-empty" type="is-danger"></b-icon>
                </a>
              </center>
            </b-table-column>
            <b-table-column field="ventas" label="Ventas" v-slot="props">
              <center>
                <a
                  v-if="props.row.ventas == 'true'"
                  class="button is-light"
                  :href="location + 'ventas/V' + props.row.archivo + '.pdf'"
                  target="_blank"
                >
                  <b-icon icon="file-eye" type="is-info"></b-icon> </a
                >&nbsp;&nbsp;
                <a
                  v-if="props.row.ventas == 'true'"
                  class="button is-light"
                  @click="Eliminar(props.row.archivo, 2)"
                  target="_blank"
                >
                  <b-icon icon="delete-empty" type="is-danger"></b-icon>
                </a>
              </center>
            </b-table-column> </template
        ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ReportesService from "@/services/ReportesService";
export default {
  name: "tablaReportes",
  data() {
    return {
      isOpen: false,
      isEmpty: false,
      Reportes: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      nuevoVal: "",
      edit: null,
      nombre: null,
      status_letter: true,
      locale: undefined, // Browser locale
      icon_edit: "lead-pencil",
      status_t: "is-info is-light",
      status_edit: false,
      avatar: "",
      ruta: "",
      showDetailIcon: true,
      location: null,
      file: null,
    };
  },
  mounted() {
    this.obtenerReportes();
  },

  methods: {
    async AgregarComi() {
      var agencia = this.file.name.substring(1, this.file.name.length - 8);
      const respuesta = await ReportesService.obtenerAgencia(agencia);

      if (respuesta != 0) {
        if (this.$refs["avatarInput"].newValue && this.avatar != null) {
          const upload_image = await ReportesService.NuevoFileR(this.file);

          if (upload_image) {
            this.message("Se guardo el reporte con exito", "is-success");
            this.isOpen = false;
            this.file = null;
            this.obtenerReportes();
          } else {
            this.message("Error al cargar el reporte ", "is-danger");
          }
        } else {
          this.message("Debes subir archivo de Reportes", "is-danger");
        }
      } else {
        this.message(
          "El archivo no cumple con el formato o la agencia no se encuentra Activa",
          "is-danger"
        );
      }
    },
    changeImage(e) {
      this.file = e;
      var that = this;
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.avatar = this.result;
      };
    },
    eliminarview() {
      this.avatar = null;
      this.file = null;
      this.ruta = "";
    },
    async Eliminar(id, option) {
      this.cargando = true;

      try {
        const respuesta = await ReportesService.Eliminar(id, option);

        if (respuesta) {
          this.message("El archivo ha sido eliminado", "is-success");

          this.obtenerReportes();
        } else {
          this.message("Error al tratar de eliminar archivo", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.cargando = false;
      }
    },

    async obtenerReportes() {
      this.cargando = true;
      this.Reportes = await ReportesService.obtenerReportes();
      this.cargando = false;
      var pathname = "/";
      this.location =
        "https://contravel.com.mx" +
        pathname +
        "softw" +
        pathname +
        "contravel" +
        pathname +
        "reportes" +
        pathname;
    },

    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

<style scoped>
.file {
  justify-content: center;
}
</style>