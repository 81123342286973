import HttpService from "@/services/HttpService";
const FacturasService = {
    async obtenerFacturas() {
        return await HttpService.post("/obtenerFacturas.php");
    },
    async obtenerDatosFactura(Dato) {
        const cargaUtil = { Dato };
        return await HttpService.post("/obtenerDatosFactura.php", cargaUtil);
    },
    async obtenerPaqueteDeDatos(Dato) {
        const cargaUtil = { Dato };
        return await HttpService.post("/obtenerPaqueteDeDatos.php", cargaUtil);
    },
    async cambiarEstado(seguimiento, userIris) {
        return await HttpService.post("/UpdateStatus.php", {
            seguimiento, userIris
        });
    },
    async CrearReporte(DataFactu) {
        const cargaUtil = { DataFactu };
        return await HttpService.post("/ReporteFacturas.php", cargaUtil);

    },
    async NuevaCapacitacion(fechaP, asunto, duracion, descrip, Participantes, url) {
        return await HttpService.post("/NuevaCapacitacion.php", {
            fechaP: fechaP, asunto: asunto, duracion: duracion, descrip: descrip, Participantes: Participantes, url: url
        });
    },
};
export default FacturasService;