import HttpService from "@/services/HttpService";
const ComisionesService = {


    async obtenerComisiones() {
        return await HttpService.post("/obtenerComisiones.php");
    },

    async NuevoFileComi(file, nombre) {
        const formdata = new FormData();
        formdata.append("img", file);
        formdata.append("nombre", nombre);
        return await HttpService.formdata("/saveComi.php", formdata);
    },

    async Eliminar(id) {
        return await HttpService.post("/eliminarArc.php", {
            id
        });
    },
};
export default ComisionesService;