import HttpService from "@/services/HttpService";
const SlidersService = {

    async obtenerSliders() {
        return await HttpService.post("/obtenerSliders.php");
    },
    async cambiarLugar(option, oldLugar, newLugar) {
        return await HttpService.post("/cambiarLugar.php", {
            option, oldLugar, newLugar
        });
    },
    async obtenerDatosRem(Dato) {
        const cargaUtil = { Dato };
        return await HttpService.post("/obtenerDatosRem.php", cargaUtil);
    },
    async subirArchivos(archivos, contador) {
        const formData = new FormData();
        for (const archivo of archivos) {
            formData.append("archivos[]", archivo);
            formData.append("contador", contador);
        }
        return await HttpService.formdata("/subir_archivos.php", formData);
    },
};
export default SlidersService;