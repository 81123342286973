<template>
  <div class="box">
    <h1 class="title">Manuales</h1>

    <div class="block">
      <b-button
        label="+ Nuevo"
        type="is-info"
        size="is-medium"
        @click="isOpen = !isOpen"
        :aria-expanded="isOpen"
        aria-controls="contentIdForA11y2"
      />
    </div>

    <b-collapse
      aria-id="contentIdForA11y2"
      class="panel"
      animation="slide"
      v-model="isOpen"
    >
      <template #trigger>
        <div
          class="panel-heading"
          role="button"
          aria-controls="contentIdForA11y2"
          :aria-expanded="isOpen"
        >
          <strong>Datos</strong>
        </div>
      </template>

      <div class="columns" style="text-align: center">
        <div class="column">
          <b-field label="Titulo">
            <b-input
              rounded
              v-model="nombre"
              ref="fieldnamenombre"
              placeholder="Escribe el nombre para el manual"
              native-value="nombre"
            >
            </b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Tipo">
            <b-autocomplete
              rounded
              ref="fieldnameTag"
              placeholder="Introduce el tipo de manual"
              group-field="type"
              group-options="n_tags"
              open-on-focus
              required
              :clearable="true"
              :data="filtrarObjeto"
              native-value="tag"
              v-model="n_tags"
            >
            </b-autocomplete
          ></b-field>
        </div>
        <div class="column" style="text-align: center">
          <div class="rows">
            <div class="row">
              <br />
              <h3 class="title is-4">Subir archivo</h3>

              <br />

              <b-field class="file is-info" :class="{ 'has-name': !!file2 }">
                <b-upload
                  ref="avatarInput"
                  @input="changeImage($event)"
                  v-model="file2"
                  class="file-label"
                  rounded
                >
                  <b-tooltip
                    label="Subir Imagen"
                    position="is-bottom"
                    type="is-info is-light"
                  >
                  </b-tooltip>
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label"></span>
                  </span>
                  <span class="file-name" v-if="file2">
                    {{ file2.name }}
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="row" v-if="avatar || ruta != ''">
              <b-tooltip
                label="Borrar Imagen"
                position="is-bottom"
                type="is-info is-light"
              >
                <a @click="eliminarview()"
                  ><b-icon icon="delete" type="is-danger">
                  </b-icon></a></b-tooltip
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b-tooltip
                label="Guardar Archivo"
                position="is-bottom"
                type="is-info is-light"
              >
                <a @click="AgregarComi()"
                  ><b-icon icon="content-save" type="is-success"> </b-icon></a
              ></b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <div class="columns">
      <div class="column">
        <b-table
          v-if="isOpen == false"
          style="font-size: 18px"
          :data="isEmpty ? [] : Manuales"
          :paginated="true"
          :per-page="10"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
        >
          <template>
            <b-table-column
              field="Fecha_Registro"
              label="Fecha de Registro"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.fch_registro }}
            </b-table-column>
            <b-table-column
              field="titulo"
              label="Titulo"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.titulo }}
            </b-table-column>
            <b-table-column field="ruta" label="URL Archivo" v-slot="props">
              <center>
                <a
                  class="button is-light"
                  :href="props.row.ruta + props.row.titulo"
                  target="_blank"
                >
                  <b-icon icon="file-eye" type="is-info"></b-icon>
                </a>
              </center>
            </b-table-column>
            <b-table-column
              field="Eliminar"
              label="Eliminar"
              sortable
              v-slot="props"
            >
              <center>
                <a
                  class="button is-light"
                  @click="Eliminar(props.row.id)"
                  target="_blank"
                >
                  <b-icon icon="delete-empty" type="is-danger"></b-icon>
                </a>
              </center> </b-table-column></template
        ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ManualesService from "@/services/ManualesService";
export default {
  name: "tablaComisiones",
  data() {
    return {
      isOpen: false,
      file2: null,
      isEmpty: false,
      Manuales: [],
      downloadLink: null,
      cargando: false,
      i: null,
      j: null,
      nuevoVal: "",
      edit: null,
      nombre: null,
      status_letter: true,
      locale: undefined, // Browser locale
      icon_edit: "lead-pencil",
      status_t: "is-info is-light",
      status_edit: false,
      avatar: "",
      ruta: "",
      showDetailIcon: true,
      n_tags: null,
      nameTag: "",
      dataTag: [
        {
          type: "tags",
          n_tags: [],
        },
      ],
    };
  },
  mounted() {
    this.obtenerManuales();
    this.obtenertags();
  },
  computed: {
    filtrarObjeto() {
      const newData = [];
      this.dataTag.forEach((element) => {
        const n_tags = element.n_tags.filter(
          (item) => item.toLowerCase().indexOf(this.nameTag.toLowerCase()) >= 0
        );

        if (n_tags.length) {
          newData.push({ type: element.type, n_tags });
        }
      });
      return newData;
    },
  },
  methods: {
    async AgregarComi() {
      if (this.$refs["avatarInput"].newValue && this.avatar != null) {
        const upload_image = await ManualesService.NuevoFileManu(
          this.file,
          this.nombre,
          this.n_tags
        );
        if (upload_image) {
          this.message("Se guardo el comunicado con exito", "is-success");
          location.reload();
        } else {
          this.message("Error al realizar el comunicado ", "is-danger");
        }
      } else {
        this.message("Debes subir archivo de comisiones", "is-danger");
      }
    },
    async obtenertags() {
      this.cargando = true;
      this.tags = await ManualesService.obtenerTipoManuales();
      this.cargando = false;

      if (this.tags) {
        for (let index2 = 0; index2 < this.tags.length; index2++) {
          this.dataTag[0].n_tags.push(this.tags[index2].nombre);
        }
      }
    },
    async MandarDatoTag(Dato, id, opcion) {
      if (Dato != null) {
        this.cargando = true;
        const respuesta = await ManualesService.obtenertipo(Dato);
        this.cargando = false;
        this.nuevoVal = respuesta[0].nombre;

        this.cambiarEstado(id, opcion);
      } else {
        this.nuevoVal = null;
      }
    },
    changeImage(e) {
      this.file = e;
      var that = this;
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.avatar = this.result;
      };
    },
    eliminarview() {
      this.avatar = null;
      this.file = "";
      this.file2 = null;
      this.ruta = "";
    },
    async Eliminar(id) {
      this.cargando = true;
      try {
        const respuesta = await ManualesService.Eliminar(id);
        if (respuesta) {
          this.message("El archivo ha sido eliminado", "is-success");

          this.obtenerManuales();
        } else {
          this.message("Error al tratar de eliminar archivo", "is-danger");
        }
      } catch (e) {
        this.message("Error cambiando estado: " + e, "is-danger");
      } finally {
        this.cargando = false;
      }
    },
    async obtenerManuales() {
      this.cargando = true;
      this.Manuales = await ManualesService.obtenerManuales();
      this.cargando = false;
    },

    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

<style scoped>
.file {
  justify-content: center;
}
</style>