<template>
  <div>
    <nav
      v-if="logueado"
      style="min-height: 80px"
      class="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <img
          @click="navegarHacia('HelloWorld')"
          src="@/assets/logo.png"
          id="icon"
          style="height: 10vh"
          class="navbar-item"
          alt="User Icon"
        />

        <div class="navbar-burger" id="BSalir">
          <b-tooltip label="Salir" type="is-dark" position="is-left">
            <a @click="navegarHacia('Logout')">
              <b-icon
                style="height: 0.1vh"
                size="is-medium"
                icon="exit-run"
              ></b-icon>
            </a>
          </b-tooltip>
        </div>
      </div>
      <div :class="{ 'is-active': menuEstaActivo }" class="navbar-menu">
        <div class="navbar-start"></div>
        <div class="navbar-end">
          <a
            v-show="logueado"
            class="navbar-item"
            @click="navegarHacia('Logout')"
          >
            <b-icon class="mr-1" icon="exit-run"></b-icon>&nbsp;Salir (<small>{{
              user
            }}</small
            >)
          </a>
        </div>
      </div>
    </nav>
    <div>
      <div v-show="logueado"><Header /></div>
      <router-view />
    </div>
  </div>
</template>
 
<script>
import Header from "./components/Header.vue";
import EventBus from "@/EventBus";

export default {
  components: { Header },
  name: "app",

  data: () => ({
    esAdministrador: false,
    logueado: false,
    deberiaMostrarMenu: false,
    UserName: "",
    menuEstaActivo: false,
  }),
  mounted() {
    EventBus.$on("establecerUsuario", (usuario) => {
      this.refrescarUsuario(usuario);
    });
    EventBus.$on("cerrarSesion", () => {
      this.refrescarUsuario({});
    });
    EventBus.$on("navegarHacia", this.navegarHacia);
    EventBus.$on("mostrarMenu", this.mostrarMenu);
    EventBus.$on("ocultarMenu", this.ocultarMenu);
    this.obtenerUsuarioDeLocalStorage();
  },
  methods: {
    obtenerUsuarioDeLocalStorage() {
      const usuario = EventBus.obtenerUsuario();
      this.refrescarUsuario(usuario);
    },
    refrescarUsuario(usuario) {
      if (usuario.UserName) {
        this.esAdministrador = Boolean(usuario.administrador);
        this.user = usuario.UserName;
        this.logueado = true;
      } else {
        this.esAdministrador = false;
        this.user = "";
        this.logueado = false;
      }
    },
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
      this.menuEstaActivo = false;
    },
    mostrarMenu() {
      this.deberiaMostrarMenu = true;
    },
    ocultarMenu() {
      this.deberiaMostrarMenu = false;
    },

    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>
<style>
#BSalir a {
  top: 1vh;
  color: rgb(204, 48, 9);
}
.navbar.is-dark {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(8, 37, 176, 0.9839286056219363) 0%,
    rgba(168, 72, 72, 1) 45%,
    rgba(0, 138, 255, 1) 90%
  );
  color: #fff;
}

.b-sidebar .sidebar-content.is-light {
  background-color: #00000084 !important;
}
.menu-list a {
  border-radius: 20px !important;
  color: #ffffff !important;
}
.menu-list a:hover {
  color: #110404 !important;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  height: 2em !important;
}
</style>