<template>
  <section>
    <div class="box">
      <div class="columns">
        <div class="column">
          <div class="rows">
            <div class="row">
              <b-field label="Titulo Formulario" label-position="on-border">
                <b-input
                  v-model="titulo"
                  @input="enviarFormulario"
                  placeholder="Escribe el Titulo"
              /></b-field>
              <br />
            </div>
            <div class="row">
              <div class="columns">
                <div class="column">
                  <b-field label="Duración (Días)" label-position="on-border">
                    <b-numberinput
                      :controls="false"
                      v-model="duracion"
                      placeholder="Escribe la duración"
                      @input="formatearDuracion"
                      :min="1"
                  /></b-field>
                </div>
                <div class="column">
                  <b-field label="Precio $" label-position="on-border">
                    <b-numberinput
                      v-model="precio"
                      placeholder="Escribe el precio"
                      :controls="false"
                      @input="PrecioFormater"
                    >
                    </b-numberinput>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Currency" label-position="on-border">
                    <b-select
                      v-model="currency"
                      placeholder="Tipo de Cambio"
                      @input="enviarFormulario"
                    >
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                      <option value="CAD">CAD</option>
                      <option value="EUR">EUR</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <br />
            </div>
            <div class="row">
              <div class="columns">
                <div class="column">
                  <br />
                  <b-field
                    label="Salidas"
                    label-position="on-border"
                    v-if="salidasSelect == null || salidasSelect == ''"
                  >
                    <b-select
                      expanded
                      placeholder="Selecciona una opción"
                      v-model="salidasSelect"
                      required
                    >
                      <option value="Rango">RANGO DE FECHAS</option>
                      <option value="Multifecha">UNA O VARIAS FECHAS</option>
                    </b-select>
                  </b-field>

                  <div v-if="salidasSelect == 'Rango'">
                    <b-field label="Salidas" label-position="on-border">
                      <b-datepicker
                        placeholder="Click para seleccionar..."
                        v-model="dataFecha"
                        :min-date="MinFecha"
                        @input="getFormattedFechaArray"
                        range
                        icon-right-clickable
                        icon="calendar-today"
                        trap-focus
                        required
                        ref="fieldDataFecha"
                      >
                      </b-datepicker>
                      <b-button
                        v-show="dataFecha.length > 0"
                        @click="LimpiarDataFecha()"
                        icon-left="delete"
                        type="is-danger"
                      />
                    </b-field>
                  </div>
                  <div v-if="salidasSelect == 'Multifecha'">
                    <b-field label="Salidas" label-position="on-border">
                      <b-datepicker
                        placeholder="Click para seleccionar..."
                        v-model="dataFecha"
                        @input="getFormattedFechaArray"
                        :min-date="MinFecha"
                        multiple
                        icon-right-clickable
                        icon="calendar-today"
                        trap-focus
                        required
                        ref="fieldDataFecha"
                      >
                      </b-datepicker>
                      <b-button
                        v-show="dataFecha.length > 0"
                        @click="LimpiarDataFecha()"
                        icon-left="delete"
                        type="is-danger"
                      />
                    </b-field>
                  </div>
                </div>
                <div class="column">
                  <b-field label="Visitando" label-position="on-border">
                    <b-input
                      v-model="visitando"
                      @input="enviarFormulario"
                      placeholder="Escribe el Titulo"
                      type="textarea"
                  /></b-field>
                </div>
              </div>
            </div>
            <div class="row">
              <br />

              <div class="columns">
                <div class="column">
                  <br />
                  <b-field label="Titulo día 1" label-position="on-border">
                    <b-input v-model="titulodia1" placeholder="Titulo día 1" />
                  </b-field>
                  <div
                    style="text-align: center"
                    v-if="titulodia1 && descripdia1"
                  >
                    <b-tooltip
                      type="is-success is-light"
                      label="Agregar día"
                      position="is-right"
                    >
                      <b-button
                        icon-left="plus"
                        type="is-success"
                        @click="crearHoja1"
                        outlined
                      />
                    </b-tooltip>
                  </div>
                </div>
                <div class="column">
                  <b-field label="Descripcion" label-position="on-border">
                    <b-input
                      v-model="descripdia1"
                      placeholder="Escribe la descripcion"
                      type="textarea"
                  /></b-field>
                </div>
              </div>
            </div>
            <div class="row"></div>
            <div class="row"></div>
            <div class="row"></div>
          </div>
          <!-- Contenido de Itinerario -->
          <button @click="cambiarPagina(-1)" :disabled="paginaActual === 0">
            Anterior
          </button>
          <button
            @click="cambiarPagina(1)"
            :disabled="paginaActual === hojas.length - 1"
          >
            Siguiente
          </button>
        </div>
        <div class="column" style="background-color: darkgrey">
          <div style="width: 612px; height: 792px">
            <Preview :hojas="hojas" :paginaActual="paginaActual" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Tu contenido -->

  <!-- Contenido que se mostrará en la previsualización -->
</template>
<script>
import Preview from "@/components/Paquetes/Preview.vue";
import EventBus from "@/EventBus";

export default {
  components: {
    Preview,
  },
  data() {
    const hoja1 = [];
    const hojas = [];
    return {
      titulo: null,
      duracion: null,
      precio: null,
      currency: null,
      salidasSelect: null,
      visitando: null,
      titulodia1: null,
      descripdia1: null,
      formattedArray: [],
      dataFecha: [],
      MinFecha: new Date(),
      hoja1,
      numberCount: 0,
      hojas, // Inicializa las hojas aquí
      paginaActual: 0,
      // Otras variables del formulario
    };
  },
  methods: {
    cambiarPagina(direccion) {
      this.paginaActual += direccion;
    },
    getFormattedFechaArray() {
      const formattedData = this.formatData();

      if (this.salidasSelect == "Rango") {
        this.formattedArray = this.formatRango(formattedData);
      } else {
        this.formattedArray = this.formatYearsAndMonths(formattedData);
      }

      this.enviarFormulario();
    },

    formatData() {
      const formattedData = {};

      for (const fecha of this.dataFecha) {
        const year = fecha.getFullYear();
        const month = fecha.getMonth() + 1;
        const day = fecha.getDate();
        const monthNames = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        const monthName = monthNames[month - 1];

        formattedData[year] = formattedData[year] || {};
        formattedData[year][monthName] = formattedData[year][monthName] || [];
        formattedData[year][monthName].push(day);
      }

      return formattedData;
    },

    formatRango(formattedData) {
      const years = Object.keys(formattedData);
      const iniciAnio = years[0];
      const finAnio = years[1];
      const sacarMeses = formattedData[years[0]];
      const nameMeses = Object.keys(sacarMeses);
      const mesInicio = nameMeses[0];
      const mesFin = nameMeses[1];
      const nombreMes = sacarMeses[mesInicio];
      const nombreMesFin = sacarMeses[mesFin];

      if (finAnio == undefined) {
        if (mesFin == undefined) {
          return `Del ${nombreMes[0]} al ${nombreMes[1]} de ${mesInicio} del ${iniciAnio}`;
        } else {
          return `Del ${nombreMes[0]} de ${mesInicio} al ${nombreMesFin[0]} de ${mesFin} del ${iniciAnio}`;
        }
      } else {
        const sacarMesesAF = formattedData[years[1]];
        const nameMesAF = Object.keys(sacarMesesAF);
        const mesFinAF = nameMesAF[0];
        const diaMesAF = sacarMesesAF[mesFinAF][0];
        return `Del ${nombreMes[0]} de ${mesInicio} del ${iniciAnio} al ${diaMesAF} de ${mesFinAF} del ${finAnio}`;
      }
    },

    formatYearsAndMonths(formattedData) {
      let formattedArray = "";

      for (const year in formattedData) {
        formattedArray += `${year}:<br />`;
        for (const month in formattedData[year]) {
          formattedArray += `${month}: ${formattedData[year][month].join(
            ", "
          )}.<br />`;
        }
      }

      return formattedArray;
    },
    LimpiarDataFecha() {
      this.dataFecha = [];
      this.fechaArray = [];
      this.salidasSelect = null;
      this.MinFecha = new Date();
    },

    crearHoja1() {
      this.numberCount = this.numberCount + 1;

      this.hoja1.push({
        titulo: "<b>Día " + this.numberCount + ": " + this.titulodia1 + "</b>",
        actividades: this.descripdia1,
      });
      this.hojas.push(this.hoja1);
      this.titulodia1 = null;
      this.descripdia1 = null;
      this.enviarFormulario();
    },

    enviarFormulario() {
      EventBus.$emit("datos-formulario", {
        hoja1: this.hoja1,
        duracion: this.duracionformater,
        precio: this.precioFormater,
        currency: this.currency,
        salidas: this.formattedArray,
        visitando: this.visitando,

        // Otros datos del formulario
      });
    },

    formatearDuracion() {
      if (this.duracion == null) {
        this.duracionformater = null;
        return; // Salir de la función si this.duracion es null
      }
      if (this.duracion == 1) {
        this.duracionformater = "SALIDAS DE UN DÍA";
      } else if (this.duracion == 2) {
        this.noches = "1 NOCHE";
        this.duracionformater = this.duracion + " DÍAS" + " / " + this.noches;
      } else {
        this.noches = this.duracion - 1 + " NOCHES";
        this.duracionformater = this.duracion + " DÍAS" + " / " + this.noches;
      }

      this.enviarFormulario();
    },

    PrecioFormater() {
      this.precioFormater = this.precio
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.enviarFormulario();
    },
  },
};
</script>
