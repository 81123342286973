import HttpService from "@/services/HttpService";
const FechasPagoService = {


    async obtenerFechas() {
        return await HttpService.post("/obtenerFechas.php");
    },


    async obtenerMeses() {
        return await HttpService.post("/obtenerMeses.php");

    },
    async calcularPeriodo() {
        return await HttpService.post("/calcularPeriodo.php");
    },

    async obtenerPeriodos() {
        return await HttpService.post("/obtenerPeriodos.php");
    },
    async obtenerModsDay() {
        return await HttpService.post("/obtenerModsDay.php");
    },

    async AgregarFecha(fechacompair, asunto, numero_periodo) {
        return await HttpService.post("/NuevaFecha.php", {
            fechacompair: fechacompair, asunto: asunto, numero_periodo: numero_periodo
        });
    },
    async eliminar(id) {
        return await HttpService.post("/eliminarFecha.php", {
            id:id
        });
    },
    async UpdateFech(fechacompair,asunto,numero_periodo, idfechaMod) {
        return await HttpService.post("/UpdateFech.php", {
            fechacompair,asunto,numero_periodo, idfechaMod
        });
    },


};
export default FechasPagoService;