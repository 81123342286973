import HttpService from "@/services/HttpService";
const ClientesService = {

    async CrearReporte(contactos) {
        const cargaUtil = { contactos };
        return await HttpService.post("/CrearReporte.php", cargaUtil);
    },
    async ReporteAgencias(contactos) {
        const cargaUtil = { contactos };
        return await HttpService.post("/ReporteAgencias.php", cargaUtil);
    },
    async obtenerClientes() {
        return await HttpService.post("/obtenerClientes.php");
    },
    async obtenerAgencias() {
        return await HttpService.post("/obtenerAgencias.php");
    },
    async cambiarEstado(nuevoVal, id, opcion) {
        return await HttpService.post("/cambiar_estado.php", {
            nuevoVal, id, opcion
        });
    },

    async cambiarAccesoAPD(nuevoVal, id) {
        return await HttpService.post("/cambiarAccesoAPD.php", {
            nuevoVal, id
        });
    }




};
export default ClientesService;